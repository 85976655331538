import { element } from 'protractor';
import { Banner } from './../../../../service/model/menu/menu.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-pc-menu-banner',
  templateUrl: './pc-menu-banner.component.html',
  styleUrls: ['./pc-menu-banner.component.css']
})
export class PcMenuBannerComponent implements OnInit {

  bannerOptions: any;
  pager = '';

  @Input() bannerAry: Banner[] = [];
  constructor() { }

  ngOnInit(): void {

    this.bannerOptions = { // 設定 Owl Carousel Options
      items: 1,
      dots: true,
      nav: true,
      loop: true,
      autoHeight: true,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      navText: [
        '<a href="javascript:;" uk-slideshow-item="previous"><img src="./assets/content/images/ic-banner-2-previous.svg" width="48px"></a>',
        '<a href="javascript:;" uk-slideshow-item="next"><img src="./assets/content/images/ic-banner-2-next.svg" width="48px"></a>'
      ],
      onDragged : this.pageChange,
      onChanged : this.pageChange
    };

    this.pager = '01/' + '0' + this.bannerAry.length.toString();
  }

  pageChange(event): void {

    this.pager = `0` + (event.item.index + 1).toString() + `/0` + event.item.count + ``;

    const parentNode = event.target.closest('.sky-navbar-img');

    const pagerTitle = parentNode.getElementsByClassName('float-right');

    const dotNode = parentNode.getElementsByClassName('owl-dot');

    this.pager = `01` + `/0` + event.item.count + ``;

    for (let index = 0; index < dotNode.length; index++) {

      const element = dotNode[index];

      if (element.classList.contains('active')) {

        this.pager = `0` + (index + 1).toString() + `/0` + event.item.count + ``;
      }
    }

    pagerTitle[0].innerHTML = this.pager;
  }

}
