import { CryptoService } from './crypto.service';
import { Injectable } from '@angular/core';

import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  COOKIE_CONSENT = 'COOKIE_CONSENT';

  isConsented = true;

  constructor(private cryptosvc: CryptoService) { }

  // 存入 Cookie
  setCookie(cookieName: string, value: any, expireDays: number, path: string = ''): void {

    const d = new Date();

    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);

    const expires = `expires=${d.toUTCString()}`;

    const cpath = path ? `; path=${path}` : '';

    const encryptValue = this.cryptosvc.encryptData(value);

    document.cookie = `${cookieName}=${encryptValue}; ${expires}${cpath}`;
  }

  // 刪除 Cookie by Name
  removeCookie(cookieName): void {

    this.setCookie(cookieName, '', -1);
  }

  // 取 Cookie By Name
  getCookieByName<T>(cookieName: string): T {

    const cookies = document.cookie.split(';');

    const cookieObj = {};

    let cookie;

    for (let i = 0, l = cookies.length; i < l; ++i) {

      cookie = jQuery.trim(cookies[i]);
      cookie = cookie.split('=');

      const name = cookie[0];
      const value = cookie[1];

      if (cookieName === name) {

        const decryptValue = this.cryptosvc.decryptData(value);



        try {

          return decryptValue as T;
        } catch {

          return null;
        }
      }
    }

    return null;
  }

  setConsent(isConsent: boolean, e: any): void {

    this.setCookie(this.COOKIE_CONSENT, isConsent, 999);

    this.isConsented = true;

    e.preventDefault();
  }

  checkConsent(): void {
    const result = this.getCookieByName<boolean>(this.COOKIE_CONSENT);

    if (result !== true || result === null) {

      this.isConsented = false;
    }
  }
}
