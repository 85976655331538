<div class="mobile-warp" [style.height]='this.layoutsvc.windowHeight + "px"'>

  <div class="sky-head-fixed">
    <div class="head_secondary_bg-m">
      <div class="head-container-fixed_m">
        <div class="head-logo float-left" *ngIf="this.menusvc.menuStep === menuStepEnum.step1">
          <a [routerLink]="['/ ' + this.languagesvc.nowLangNo + ' ']">
            <img src="../../../../assets/content/images/img-logo-cal-header-w@3x.png" alt="" width="208px">
          </a>
        </div>
        <div class="head-secondary-icon float-left"
             *ngIf='this.menusvc.menuStep === menuStepEnum.step2 || this.menusvc.menuStep === menuStepEnum.step3'>
          <button type="button" style="background-color: transparent;" (click)="backPage(this.menusvc.menuStep)">
            <img src="../../../../assets/content/images/ic-left-w.svg" alt="" width="32px">
            <span class="text-transform"
                  *ngIf='this.menusvc.menuStep === menuStepEnum.step2'>{{"MobileMenu" | translate}}</span>
            <span class="text-transform"
                  *ngIf='this.menusvc.menuStep === menuStepEnum.step3'>{{this.menuData.CategoryName}}</span>
          </button>
        </div>
        <div class="head-icon float-right">
          <a href="javascript:;" (click)="closeMenuEvent($event)">
            <img src="./assets/content/images/ic-cancel-w.svg"
                 alt="" width="32px">
          </a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>

  <div class="head_secondary_container-m head-container_m"
       [@slideInOut]="this.menusvc.menuStep === menuStepEnum.step1 ? 'open' : 'closed'">
    <nav class="primary_category" *ngIf="this.menusvc.menuStep === menuStepEnum.step1">
      <ul>
        <li class="members_mobile_down" *ngIf="this.membersvc.isLoginSubject.value">
          <a href="#" class="members_mobile_down sky-border-item d-block position-relative" type="text"
             data-toggle="collapse" data-target=".members_coding" aria-expanded="true" aria-controls="members_coding">
            <div class="members_mobile_drop">
              <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.DynastyMember">
                <img class="mr-2"
                     src="./assets/content/images/ic_membershipcard_{{this.baseDatasvc.memberData.userData.dfpCardType}}@2x.png" alt=""
                     width="76px">
                <div class="text d-inline-block">
                  <p class="mb-2">
                    {{this.baseDatasvc.memberData.userData.eName.split("/")[0] + "/**"}}
                    <!-- {{this.baseDatasvc.memberData.loginData.paxFirstName}},
                    {{this.baseDatasvc.memberData.loginData.paxLastName}} -->
                  </p>
                  <small>{{"MemberDynastyMemberCardNo" | translate}}<span> | </span>{{this.baseDatasvc.memberData.userData.dfpCardNumber}}</small>
                </div>
              </ng-container>
              <ng-container *ngIf="this.baseDatasvc.memberData.loginType !== this.loginTypeEnum.DynastyMember">
                <div class="text d-inline-block">
                  <p class="mb-2">
                    {{this.baseDatasvc.memberData.loginData.paxFirstName}},
                    {{this.baseDatasvc.memberData.loginData.paxLastName}}
                  </p>
                  <small *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.PnrLogin">
                    {{"MemberDynastyReservationdNo" | translate}}<span> | </span>{{this.baseDatasvc.memberData.loginData.PaxLocator}}
                  </small>
                  <small *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.TicketNumber">
                    {{"MemberDynastyTicketNo" | translate}}<span> | </span>{{this.baseDatasvc.memberData.loginData.TicketNumber}}
                  </small>
                  <small *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.EmployID">
                    {{"MemberDynastyEmployeeNo" | translate}}<span> | </span>{{this.baseDatasvc.memberData.loginData ? this.baseDatasvc.memberData.loginData.dfpCardNumber : ""}}
                  </small>
                </div>
              </ng-container>
            </div>
          </a>
          <ul class="members_mobile_bg_gray collapse members_coding" aria-labelledby="headingOne">
            <li>
              <a routerLink="/{{this.languagesvc.nowLangNo}}/member/{{this.memberCenterTypeEnum[this.memberCenterTypeEnum.Order]}}"
                 class="head-container_m d-block">
                <small class="members_mobile_blank sky-border-item ic-list">
                  {{"MemberToolListMyOrder" | translate}}
                </small>
              </a>
            </li>
            <li>
              <a routerLink="/{{this.languagesvc.nowLangNo}}/member/{{this.memberCenterTypeEnum[this.memberCenterTypeEnum.Notification]}}"
                 class="head-container_m d-block">
                <small class="members_mobile_blank sky-border-item ic-notice">
                  {{"MemberToolPushNotification" | translate}}
                </small>
              </a>
            </li>
            <li>
              <a href="javascript:;" class="head-container_m d-block" (click)="this.toWishList()">
                <small class="members_mobile_blank sky-border-item ic-wishlish">{{"MemberToolWishlist" | translate}}</small>
              </a>
            </li>
            <li>
              <a href="javascript:;" class="head-container_m d-block" (click)="this.logOut()">
                <small class="members_mobile_blank sky-border-item ic-signout">
                  <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.DynastyMember">{{"MemberToolSignOutDynastyMember" | translate}}</ng-container>
                  <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.TicketNumber">{{"MemberToolSignOutTicketNumber" | translate}}</ng-container>
                  <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.PnrLogin">{{"MemberToolSignOutPnrLogin" | translate}}</ng-container>
                  <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.loginTypeEnum.EmployID">{{"MemberToolSignOut" | translate}}</ng-container>
                </small>
              </a>
            </li>
          </ul>
        </li>
        <li class="members_mobile_only sky-border-item" *ngIf="!this.membersvc.isLoginSubject.value">
          <a href="javascript:;" (click)="changePage(this.menuStepEnum.step2, null, this.menuTypeEnum.Member)">
            <small class="text-transform">{{"HeaderRightButtonLogin" | translate}}</small>
          </a>
        </li>
        <li *ngFor="let menuData of this.menuDataList" class="members_mobile_next sky-border-item">
          <a href="javascript:;" (click)="changePage(this.menuStepEnum.step2, menuData.Id, this.menuTypeEnum.APIMenu)">
            <small class="text-transform">{{menuData.CategoryName}}</small>
          </a>
        </li>
        <li class="members_mobile_blank sky-border-item pt-0 pb-0">
          <a [routerLink]="['news']"><small>{{"HeaderRightNews" | translate}}</small></a>
        </li>
        <li class="members_mobile_blank sky-border-item pt-0 pb-0">
          <a [routerLink]="['purchaseNotes']"><small>{{"HeaderRightShoppingNotice" | translate}}</small></a>
        </li>
        <li class="members_mobile_blank sky-border-item pt-0 pb-0">
          <a [routerLink]="['contactUs']"><small>{{"HeaderRightContactUs" | translate}}</small></a>
        </li>
        <ng-container *ngIf="!this.membersvc.isLoginSubject.value || this.baseDatasvc.memberData.loginType !== this.loginTypeEnum.EmployID">
          <li class="members_mobile_blank sky-border-item pt-0 pb-0">
            <a href="javascript:;" (click)="this.empLogin()"><small>{{"HeaderRightEmpLogin" | translate}}</small></a>
          </li>
        </ng-container>
        <li class="members_mobile_language sky-border-item">
          <a href="javascript:;"
             (click)="changePage(this.menuStepEnum.step2, null, this.menuTypeEnum.Language)"><small>{{this.nowLanguageName}}</small></a>
        </li>
      </ul>
    </nav>
  </div>

  <div [@slideInOut]="this.menusvc.menuStep === menuStepEnum.step2 ? 'open' : 'closed'">
    <ng-container [ngSwitch]="this.menusvc.menuType" *ngIf="this.menusvc.menuStep === menuStepEnum.step2">
      <ng-container *ngSwitchCase="this.menuTypeEnum.APIMenu">
        <ng-container *ngIf="this.menuData.CategoryType === this.categoryTypeEnum.Type1">
          <ng-container *ngIf="this.menuData.IsMobileCategory === true">
            <div class="head_secondary_container-02-m">
              <div class="members_mobile_title members_mobile_bg_w">
                <div class="head-container_m">
                  <h3 class="text-transform sky-border-item">{{this.menuData.CategoryName}}</h3>
                </div>
              </div>
              <nav class="primary_category">
                <ul>
                  <li class="members_mobile_bg" *ngFor="let mobileCategorie of this.menuData.MobileCategories">
                    <div class="head-container_m">
                      <div class="members_mobile_next sky-border-item">
                        <a href="javascript:;"
                           (click)="changePage(this.menuStepEnum.step3, mobileCategorie.Id, this.menuTypeEnum.APIMenu)">
                          <small class="text-transform">{{mobileCategorie.CategoryName}}</small>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
          </ng-container>
          <ng-container *ngIf="this.menuData.IsMobileCategory !== true">
            <div class="head_secondary_container-03-m">
              <div class="members_mobile_title members_mobile_bg_w">
                <div class="head-container_m">
                  <h3 class="text-transform sky-border-item">{{this.menuData.CategoryName}}</h3>
                </div>
              </div>
              <nav class="primary_category accordion accordionExample">
                <ul class="members_mobile_bg">
                  <li class="members_down_menu">
                    <a class="head-container_m d-block" [routerLink]="['product']"
                       [queryParams]="{ name: this.menuData.CategoryName, categoryIds: this.menuData.Id, page: 1 }">
                      <small class="members_mobile_blank sky-border-item">
                        {{"ViewAll" | translate}}
                      </small>
                    </a>
                  </li>
                  <li class="members_down_menu" *ngFor="let SubCategorie of this.menuData.SubCategories">
                    <a href="#" class="head-container_m d-block" type="text" data-toggle="collapse"
                       [attr.data-target]='".members_coding_" + SubCategorie.Id' aria-expanded="true"
                       [attr.aria-controls]='"members_coding_" + SubCategorie.Id'>
                      <small class="members_mobile_down sky-border-item">{{SubCategorie.SubCategoryName}}</small>
                    </a>
                    <ul class="members_mobile_bg_w collapse " [class]='"members_coding_" + SubCategorie.Id'
                        aria-labelledby="headingOne" data-parent=".accordionExample">
                      <li>
                        <a class="head-container_m d-block" [routerLink]="['product']"
                           [queryParams]="{ name: SubCategorie.SubCategoryName, subIds: SubCategorie.Id, page: 1 }">
                          <small class="members_mobile_blank sky-border-item">{{"ViewAll" | translate}}</small>
                        </a>
                      </li>
                      <li *ngFor="let BranchCategorie of SubCategorie.BranchCategories">
                        <a class="head-container_m d-block" [routerLink]="['product']"
                           [queryParams]="{ name: BranchCategorie.BranchCategoryName, branchIds: BranchCategorie.Id, page: 1 }">
                          <small class="members_mobile_blank sky-border-item">{{BranchCategorie.BranchCategoryName}}</small>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="this.menuData.CategoryType === this.categoryTypeEnum.Type2">
          <div class="head_secondary_container-03-m">
            <div class="members_mobile_title members_mobile_bg_w">
              <div class="head-container_m">
                <!-- <h3 class="text-transform sky-border-item">{{"MenuCategory" | translate}}</h3> -->
                <h3 class="text-transform sky-border-item">{{this.menuData.CategoryName}}</h3>
              </div>
            </div>
            <nav class="primary_category accordion accordionExample">
              <ul class="members_mobile_bg">
                <li class="members_down_menu">
                  <a class="head-container_m d-block" [routerLink]="['product']"
                     [queryParams]="{ categoryType: this.categoryTypeEnum.Type2, name: this.menuData.CategoryName, categoryIds: menuData.Id, productFeatureIds: menuData.ProductFeatureId, page: 1 }">
                    <small class="members_mobile_blank sky-border-item">{{"MenuEventViewAll" | translate}}</small>
                  </a>
                </li>
                <li class="members_down_menu" *ngFor="let categorieData of this.menuData.Categories">
                  <a [routerLink]="['product']" class="head-container_m d-block"
                     [queryParams]="{ categoryType: this.categoryTypeEnum.Type2, name: this.menuData.CategoryName, categoryIds: this.menuData.Id, eventCategorieIds: categorieData.Id, productFeatureIds: menuData.ProductFeatureId, page: 1 }">
                    <small class="members_mobile_blank sky-border-item">{{categorieData.CategoryName}}</small>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngIf="this.menuData.CategoryType === this.categoryTypeEnum.Type3">
          <div class="head_secondary_container-03-m">
            <div class="members_mobile_title members_mobile_bg_w">
              <div class="head-container_m">
                <h3 class="text-transform sky-border-item">{{this.menuData.CategoryName}}</h3>
              </div>
            </div>
            <nav class="primary_category accordion accordionExample">
              <ul class="members_mobile_bg">
                <li class="sky-item"
                    *ngIf="this.membersvc.isLoginSubject.value && this.baseDatasvc.memberData.loginType === this.loginTypeEnum.DynastyMember">
                  <a class="head-container_m d-block" [routerLink]="['activity/member']">
                    <small class="members_mobile_blank sky-border-item">會員獨享專區</small>
                  </a>
                </li>
                <li class="members_down_menu" *ngFor="let activitie of this.menuData.Activities">
                  <a class="head-container_m d-block" [routerLink]="['activity/' + activitie.UrlName]"
                     [queryParams]="{ Id: activitie.Id }">
                    <small class="members_mobile_blank sky-border-item">{{activitie.ActivityName}}</small>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngIf="this.menuData.CategoryType === this.categoryTypeEnum.Type4">
          <div class="head_secondary_container-03-m">
            <div class="members_mobile_title members_mobile_bg_w">
              <div class="head-container_m">
                <h3 class="text-transform sky-border-item">{{"MenuBrandCollection" | translate}}</h3>
              </div>
            </div>
            <nav class="primary_category accordion accordionExample">
              <ul class="members_mobile_bg">
                <li class="members_down_menu">
                  <a class="head-container_m d-block" [routerLink]="['all brands']">
                    <small class="members_mobile_blank sky-border-item">{{"MenuAllBrand" | translate}}</small>
                  </a>
                </li>
                <li class="members_down_menu">
                  <a class="head-container_m d-block" [routerLink]="['brands pavilion']">
                    <small class="members_mobile_blank sky-border-item">{{"MenuBrandPavilion" | translate}}</small>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="this.menuTypeEnum.Member">
        <app-mobile-member></app-mobile-member>
      </ng-container>
      <ng-container *ngSwitchCase="this.menuTypeEnum.Language">
        <app-mobile-language></app-mobile-language>
      </ng-container>
      <ng-container *ngSwitchDefault>output2</ng-container>
    </ng-container>
  </div>

  <div [@slideInOut]="this.menusvc.menuStep === menuStepEnum.step3 ? 'open' : 'closed'">
    <ng-container *ngIf="this.menusvc.menuStep === menuStepEnum.step3">
      <ng-container *ngIf=" this.menusvc.menuType === this.menuTypeEnum.APIMenu">
        <div class="head_secondary_container-03-m"
             *ngIf="this.menusvc.menuStep === menuStepEnum.step3 && this.menusvc.menuType === this.menuTypeEnum.APIMenu">
          <div class="members_mobile_title members_mobile_bg_w">
            <div class="head-container_m">
              <h3 class="text-transform sky-border-item">{{this.menuCategoryData.CategoryName}}</h3>
            </div>
          </div>

          <nav class="primary_category accordion accordionExample">
            <ul class="members_mobile_bg">
              <li class="members_down_menu">
                <a class="head-container_m d-block" [routerLink]="['product']"
                   [queryParams]="{ name: this.menuCategoryData.CategoryName, categoryIds: this.menuCategoryData.Id, page: 1 }">
                  <small class="members_mobile_blank sky-border-item">
                    <ng-container *ngIf="this.languagesvc.nowLangNo !== 'tw'">
                      {{"MenuViewAll" | translate}}
                    </ng-container>
                    <ng-container *ngIf="this.languagesvc.nowLangNo === 'tw'">
                      {{"MenuViewAll" | translate}}{{this.menuCategoryData.CategoryName}}
                    </ng-container>
                  </small>
                </a>
              </li>
              <li class="members_down_menu" *ngFor="let SubCategorie of this.menuCategoryData.SubCategories">
                <a href="#" class="head-container_m d-block" type="text" data-toggle="collapse"
                   [attr.data-target]='".members_coding_" + SubCategorie.Id' aria-expanded="true"
                   [attr.aria-controls]='"members_coding_" + SubCategorie.Id'>
                  <small class="members_mobile_down sky-border-item">{{SubCategorie.SubCategoryName}}</small>
                </a>
                <ul class="members_mobile_bg_w collapse " [class]='"members_coding_" + SubCategorie.Id'
                    aria-labelledby="headingOne" data-parent=".accordionExample">
                  <li>
                    <a class="head-container_m d-block" [routerLink]="['product']"
                       [queryParams]="{ name: this.menuCategoryData.CategoryName, categoryIds: this.menuCategoryData.Id, subIds: SubCategorie.Id, page: 1 }">
                      <small class="members_mobile_blank sky-border-item">{{"ViewAll" | translate}}</small>
                    </a>
                  </li>
                  <li *ngFor="let branchCategorie of SubCategorie.BranchCategories">
                    <a class="head-container_m d-block" [routerLink]="['product']"
                       [queryParams]="{ name: this.menuCategoryData.CategoryName, categoryIds: this.menuCategoryData.Id, subIds: SubCategorie.Id, branchIds: branchCategorie.Id, page: 1 }">
                      <small class="members_mobile_blank sky-border-item">{{branchCategorie.BranchCategoryName}}</small>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!-- <app-footer *ngIf="this.menusvc.menuStep === this.menuStepEnum.step1"></app-footer> -->
</div>
