import { ProductPriceType, ProductPriceTypeDisplay } from './../../../core/enums/product/product-price-type.enum';
import { ProductService } from './../../../service/product.service';
import { LanguageService } from './../../../service/language.service';
import { ProductFeature, ProductModel } from './../../../service/model/product/product.model';
import { MenuService } from './../../menu/menu.service';
import { ProductFeatureModel } from './../../../service/model/product-feature/product-feature.model';
import { Component, OnInit, AfterViewInit, Input, ViewChild, HostListener } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';

import { SearchService } from '../search.service';
import { BrandDataModel } from 'src/app/service/model/brand/brand.model';
import { ProductFeatureService } from 'src/app/service/product-feature.service';
import { ProductFeatureConditionModel } from 'src/app/service/model/product-feature/product-feature-condition.model';
import { MenuDataModel } from 'src/app/service/model/menu/menu.model';
import { CategoryType } from 'src/app/core/enums/server/category-type.enum';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ProductConditionModel } from 'src/app/service/model/product/product-condition.model';
import { ProductType } from 'src/app/core/enums/product/product-type.enum';

export enum searchValueTypeEnum {
  Categorie,
  SubCategorie,
  BranchCategorie,
  ProductFeature,
  Brand,
  priceType
}

@Component({
  selector: 'app-pc-search',
  templateUrl: './pc-search.component.html',
  styleUrls: ['./pc-search.component.css']
})
export class PcSearchComponent implements OnInit, AfterViewInit {

  wasInside = false;

  keyword = '';
  @ViewChild('tKey') tKey: NgModel;

  productKey = '';
  @ViewChild('tproductKey') tproductKey: NgModel;

  @Input() brandDataAry: BrandDataModel[] = [];
  productFeatureAry: ProductFeatureModel[] = [];
  searchValueType = searchValueTypeEnum;
  brandsData: BrandDataModel[];
  CategorieAry: MenuDataModel[];

  categorieIds: number[] = [];
  subCategorieIds: number[] = [];
  branchCategorieIds: number[] = [];
  brandIds: number[] = [];
  productFeatureIds: number[] = [];
  priceTypes: number[] = [];

  searchResultData: ProductModel[] = [];

  searchConIsOpen = false;

  productPriceTypeEnum = ProductPriceType;

  constructor(
    private route: Router,
    public searchsvc: SearchService,
    public languagesvc: LanguageService,
    private productFeaturesvc: ProductFeatureService,
    private menusvc: MenuService,
    private productsevc: ProductService
  ) { }

  ngOnInit(): void {

    this.searchsvc.openPcSearchMethod.subscribe(() => {
      this.clickInside();
    });

    this.getProductFeature();
    this.getCatalogList();
  }

  @HostListener('click')
  clickInside(): void {

    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout(): void {

    if (this.searchsvc.pcSearchIsopen) {

      if (!this.wasInside) {

        this.closeSearch();
      }

      this.wasInside = false;
    }
  }

  ngAfterViewInit(): void {

    this.tKey.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe(k => {

        this.brandsData = this.brandDataAry;

        const kw: string = k;

        if (kw.length > 0) {

          this.brandsData = this.brandDataAry.filter(x => {

            return x.BrandName.toLowerCase().includes(k.toLowerCase());
          });
        }
      });

    this.tproductKey.valueChanges.pipe(debounceTime(500)).subscribe(keyword => {

      // this.keyword = '';

      if (keyword.length > 0) {

        this.productKey = keyword;
        this.productSearch(keyword);
      }
    });
  }

  productSearch(key: string): void {

    this.searchConIsOpen = false;

    const condition: ProductConditionModel = {
      PageNumber: 1,
      PageSize: 5
    };

    if (this.checkProductNoVal(key)) {

      condition.ProductNo = key;
    }else{

      condition.KeyWord = key;
    }

    // condition.ProductTypes = [ProductType.General];

    this.productsevc.getProduct(condition).subscribe(result => {
      this.searchResultData = [];

      if (result.Success) {

        if (result.Data.AllDataCount > 0 && key.length > 0) {
          this.searchConIsOpen = true;
        }

        this.searchResultData = result.Data.Datas;
      }
    });
  }

  closeSearch(): void {

    this.searchsvc.pcSearchIsopen = false;
  }

  getProductFeature(): void {

    const condition: ProductFeatureConditionModel = { PageSize: 1000 };

    this.productFeaturesvc.getData(condition).subscribe(result => {

      if (result !== undefined && result.Data.Datas !== undefined) {

        this.productFeatureAry = result.Data.Datas;
      }
    },
      (error) => {

      });
  }
  getCatalogList(): void {

    this.menusvc.getData(false).subscribe(result => {

      if (result.Success) {

        this.CategorieAry = result.Data.filter(x => x.CategoryType === CategoryType.Type1);
      }
    }, (error) => { });
  }
  valueChange($event, valueType: searchValueTypeEnum, valueId: number): void {

    const checkStatus: boolean = $event.target.checked;

    switch (valueType) {

      case searchValueTypeEnum.Categorie: {

        const parentnode = $event.target.closest('.sky-item');
        const subinputnodes = Array.prototype.slice.call(parentnode.getElementsByClassName('subcategories')[0].getElementsByClassName('subcategoriesCheckbox'));

        if (checkStatus === true) {

          this.categorieIds.push(valueId);
        } else {

          this.categorieIds = this.categorieIds.filter(id => id !== valueId);
          subinputnodes.forEach(element => {

            const subValueId = Number(element.getAttribute('data-valueId'));

            element.checked = false;
            this.subCategorieIds = this.subCategorieIds.filter(id => id !== subValueId);
          });
        }


        break;
      }

      case searchValueTypeEnum.SubCategorie: {

        if (checkStatus === true) {

          this.subCategorieIds.push(valueId);
        } else {

          this.subCategorieIds = this.subCategorieIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.BranchCategorie: {

        if (checkStatus === true) {

          this.branchCategorieIds.push(valueId);
        } else {

          this.branchCategorieIds = this.branchCategorieIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.Brand: {

        if (checkStatus === true) {

          this.brandIds.push(valueId);
        } else {

          this.brandIds = this.brandIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.ProductFeature: {

        if (checkStatus === true) {

          this.productFeatureIds.push(valueId);
        } else {

          this.productFeatureIds = this.productFeatureIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.priceType: {

        if (checkStatus === true) {

          this.priceTypes.push(valueId);
        } else {

          this.priceTypes = this.priceTypes.filter(id => id !== valueId);
        }
        break;
      }

      default: {
        break;
      }
    }

  }
  search(): void {

    this.route.navigate([this.languagesvc.nowLangNo + '/searchResult'],
      {
        queryParams: {
          keyword: this.productKey,
          categorieIds: this.categorieIds.toString(),
          subCategorieIds: this.subCategorieIds.toString(),
          brandIds: this.brandIds.toString(),
          productFeatureIds: this.productFeatureIds.toString(),
          priceTypes: this.priceTypes.toString()
        }
      });
  }
  cancelSearch(): void {
    this.categorieIds = [];
    this.subCategorieIds = [];
    this.branchCategorieIds = [];
    this.brandIds = [];
    this.productFeatureIds = [];
    this.priceTypes = [];
    this.keyword = '';
    this.productKey = '';

    const checkboxs = Array.prototype.slice.call(document.getElementsByClassName('search_check_box'));

    checkboxs.forEach(element => {
      element.checked = false;
    });


    const collapses = Array.prototype.slice.call(document.getElementsByClassName('collapse'));

    collapses.forEach(element => {
      element.classList.remove('show');
    });

    this.searchsvc.pcSearchIsopen = false;
  }
  checkProductNoVal(str: string): boolean {
    const regExp = /^[a-zA-Z]{2}\d{4}$/;

    if (regExp.test(str)) {

      return true;
    }

    return false;
  }
}
