import { map } from 'rxjs/operators';
import { element } from 'protractor';
import { MenuType } from './../../core/enums/menu/menu-type.enum';
import { LanguageService } from './../../service/language.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { MenuDataModel } from '../../service/model/menu/menu.model';
import { ResultModel } from '../../service/model/result-model';

import { MenuStep } from './menu-step.enum';

import { ApiConfig } from '../../config/api-config';
import { DatePipe } from '@angular/common';
import { BaseDataService } from 'src/app/service/base-data.service';
import { MemberLoginType } from './../../core/enums/member/member-login-type.enum';

export enum MenuIdType {
  Category,
  SubCategory,
  BranchCategory,
  ProductFeature
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menuDataList: MenuDataModel[];
  pcMenuIsOpen = false;
  memberLoginTypeEnum = MemberLoginType;

  menuStep: MenuStep;
  menuType: MenuType;

  mobileMenuIsopen = false;

  mobilePopupIsShow = false;

  constructor(
    private http: HttpClient,
    private languagesvc: LanguageService,
    private datepipe: DatePipe,
    private baseDatasvc: BaseDataService
  ) { }

  getData(isMobile: boolean): Observable<ResultModel<MenuDataModel[]>> {

    let nowDate = this.datepipe.transform(new Date(), 'yyyy/MM/dd').toString();
    let MemberCardType = this.baseDatasvc.memberData ? (this.baseDatasvc.memberData.loginType == this.memberLoginTypeEnum.DynastyMember ? this.baseDatasvc.memberData.userData.dfpCardType : "") : "";

    if (this.baseDatasvc.flightData && this.baseDatasvc.flightData.loadDate) {
      nowDate = this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString();
    }
    let apiUrl = ApiConfig.APIDomain + ApiConfig.API.Menus +
    '?lngNo=' + this.languagesvc.nowLangNo +
    '&isMobile=' + (isMobile ? 'true' : 'false') +
    '&flightDate=' + nowDate + 
    '&MemberCardType=' + MemberCardType;

    //console.log(apiUrl);

    return this.http.get<ResultModel<MenuDataModel[]>>(apiUrl).pipe(map(result => {
      return result;
    }));
  }

  openMobileMenu(step?: MenuStep): void {
    document.getElementsByTagName('body')[0].classList.add('overflowHidden');
    document.getElementsByTagName('html')[0].classList.add('overflowHidden');
    this.menuStep = step !== undefined ? step : MenuStep.step1;
    this.mobileMenuIsopen = true;
  }

  closeMobileMenu(): void {
    document.getElementsByTagName('body')[0].classList.remove('overflowHidden');
    document.getElementsByTagName('html')[0].classList.remove('overflowHidden');
    this.menuStep = MenuStep.step1;
    this.mobileMenuIsopen = false;
  }

  closePcMenu(): void {

    jQuery('.sky-navbar-con-box').removeClass('show');
    jQuery('.sky-cover-bg').height(0);
    jQuery('.sky-navbar-bg').height(0);
  }

  getCategoryName(menuDataAry: MenuDataModel[], id: string, menuIdType: MenuIdType): string {

    let resultName = '';

    switch (menuIdType) {

      case MenuIdType.Category: {

        resultName = menuDataAry.find(x => x.Id === Number(id)).CategoryName;

        break;
      }
      case MenuIdType.SubCategory: {

        menuDataAry.forEach(categoryEl => {

          categoryEl.SubCategories.forEach(subCategoryEl => {

            if (subCategoryEl.Id === Number(id)) {

              resultName = subCategoryEl.SubCategoryName;
            }
          });
        });

        break;
      }
      case MenuIdType.BranchCategory: {

        menuDataAry.forEach(categoryEl => {

          categoryEl.SubCategories.forEach(subCategoryEl => {

            subCategoryEl.BranchCategories.forEach(branchCategoryEl => {

              if (branchCategoryEl.Id === Number(id)) {

                resultName = branchCategoryEl.BranchCategoryName;
              }
            });
          });
        });
        break;
      }
      case MenuIdType.ProductFeature: {

        menuDataAry.forEach(categoryEl => {

          categoryEl.ProductFeature.forEach(productFeatureEl => {

            if (productFeatureEl.Id === Number(id)) {

              resultName = productFeatureEl.ProductFeatureName;
            }
          });
        });
        break;
      }

      default:
        break;
    }

    return resultName;
  }
}
