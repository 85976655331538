import { LocalStorageService } from './local-storage.service';
import { ShoppingCarMainModel } from 'src/app/service/model/shopping/shopping-car-main.model';
import { ShoppingCartService } from 'src/app/service/shopping-cart.service';
import { Injectable } from '@angular/core';
import { MemberLoginData } from './member.service';
import { LoginResponseItineraryModel } from './model/member/loginResponse.model';
import { LocalStorageType } from '../core/enums/localStorage/local-storage-type.enum';
import { MenuDataModel } from './model/menu/menu.model';

@Injectable({
  providedIn: 'root'
})
export class BaseDataService {

  public shoppingCarData?: ShoppingCarMainModel = null;

  public shoppingCarCount = 0;

  public memberData?: MemberLoginData =
  this.localStoragesvc.getByName<MemberLoginData>(LocalStorageType.member) !== undefined ?
  this.localStoragesvc.getByName<MemberLoginData>(LocalStorageType.member) :
  null;

  public flightData?: LoginResponseItineraryModel = this.localStoragesvc.getByName<LoginResponseItineraryModel>(LocalStorageType.FlightData) !== undefined ?
  this.localStoragesvc.getByName<LoginResponseItineraryModel>(LocalStorageType.FlightData) :
  null;

  public appid?: string = this.localStoragesvc.getByName<string>(LocalStorageType.AppId) != undefined ?
  this.localStoragesvc.getByName<string>(LocalStorageType.AppId) :
  null;

  public flightErrorMsg?: string;

  constructor(
    private localStoragesvc: LocalStorageService
  ) { }

  SaveAppId(appId: string)
  {
    this.localStoragesvc.remove(LocalStorageType.AppId);
    this.localStoragesvc.add(LocalStorageType.AppId, appId);
    this.appid = appId;
  }

}
