<ul *ngIf="pager.pages && pager.pages.length" class="pagination pagination d-inline-flex">
  <!-- <li class="page-item previous-item">
      <a (click)="setPage(1)" class="prev page-link">{{"ProductPagerFirst" | transition}}</a>
  </li> -->
  <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
      <a (click)="setPage(pager.currentPage - 1)" class="prev page-link">{{"ProductPagerPrevious" | translate}}</a>
  </li>

 <ng-container >

  <li [ngClass]="{active:pager.currentPage === 1}" class="page-item number-item">
    <a (click)="setPage(1)" class="page-link">1</a>
  </li>

  <li *ngIf="pager.startPage > 1">...</li>

  <ng-container *ngFor="let page of pager.pages">
    <li *ngIf="page !== 1 && page != pager.totalPages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">
      <a (click)="setPage(page)" class="page-link">{{page}}</a>
    </li>
  </ng-container>

  <li *ngIf="pager.endPage < pager.totalPages">...</li>

  <li *ngIf="pager.totalPages > 1" [ngClass]="{active:pager.currentPage === pager.totalPages}" class="page-item number-item">
    <a (click)="setPage(pager.totalPages)" class="page-link">{{pager.totalPages}}</a>
  </li>

 </ng-container>


  <li *ngIf="pager.totalPages >= 1" [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
      <a (click)="setPage(pager.currentPage + 1)" class="next page-link">{{"ProductPagerNext" | translate}}</a>
  </li>
  <!-- <li class="page-item previous-item">
      <a (click)="setPage(pager.totalPages)" class="next page-link">{{"ProductPagerLast" | transition}}</a>
  </li> -->
</ul>
