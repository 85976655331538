import { BaseDataService } from './base-data.service';
import { TopNewsConditionModel } from './model/top-news/top-news-condition.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LanguageService } from './language.service';
import { TopNewsModel, TopNewsResultModel } from './model/top-news/top-news.model';
import { ResultModel } from './model/result-model';

import { ApiConfig } from '../config/api-config';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TopNewsService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(
    private http: HttpClient,
    private languagesvc: LanguageService,
    private datepipe: DatePipe,
    private baseDatasvc: BaseDataService
  ) { }

  getData(condition: TopNewsConditionModel): Observable<ResultModel<TopNewsResultModel>> {

    condition.lngNo = this.languagesvc.nowLangNo;
    let nowDate = this.datepipe.transform(new Date(), 'yyyy/MM/dd').toString();

    if (this.baseDatasvc.flightData && this.baseDatasvc.flightData.loadDate) {
      nowDate = this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString();
    }
    condition.FltDate = nowDate;

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.TopNews;

    return this.http.post<ResultModel<TopNewsResultModel>>(apiUrl, condition, this.httpOptions);
  }
}
