<!-- menu_moblie Start -->
<div class="mobile-warp" [style.height]="this.layoutsvc.windowHeight + 'px'">
  <div class="sky-head-fixed">
    <div class="head_secondary_bg-m">
        <div class="head-container-fixed_m">
            <div class="position_sky float-right">
                <button type="button" (click)='this.shoppingcartsvc.closeMobleShoppingCart()' style="background-color: transparent;">
                  <img src="./assets/content/images/ic-cancel-w.svg" alt="" width="32px">
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
  </div>

  <div class="member_modal_container_m">
    <app-mobile-shopping-cart-flight></app-mobile-shopping-cart-flight>
  </div>
  <!-- menu_moblie End -->
</div>

