import { AlertService } from './../../../shared/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataService } from 'src/app/service/base-data.service';
import { MemberCenterType } from './../../../core/enums/member/member-center-type.enum';
import { LanguageService } from './../../../service/language.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { GeneralPopModel, PopupService } from '../../../shared/popup/popup.service';
import { MemberService } from '../../../service/member.service';
import { MemberPopupType } from '../../../core/enums/member/member-popup-type.enum';
import { MemberLoginType } from '../../../core/enums/member/member-login-type.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pc-member',
  templateUrl: './pc-member.component.html',
  styleUrls: ['./pc-member.component.css']
})
export class PcMemberComponent implements OnInit {
  wasInside = false;

  @Input() isOpen: boolean;

  popupId = 'member-popup';

  memberPopupTypeEnum = MemberPopupType;

  memberLoginTypeEnum = MemberLoginType;

  memberLoginType: MemberLoginType;

  memberCenterTypeEnum = MemberCenterType;

  constructor(
    private route: Router,
    public popupsvc: PopupService,
    public membersvc: MemberService,
    public languagesvc: LanguageService,
    public baseDatasvc: BaseDataService,
    private translateService: TranslateService,
    private alertsvc: AlertService
  ) { }

  @HostListener('click')
  clickInside(): void {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout(): void {
    if (!this.wasInside) {
      this.membersvc.openPcMemberTool(false);
    }

    this.wasInside = false;
  }

  ngOnInit(): void {
    if (this.membersvc.subsVar === undefined) {
      this.membersvc.subsVar = this.membersvc.memberToolOpenFunction.subscribe(() => {
        this.clickInside();
      });
    }
  }

  openLogin(memberLoginType: MemberLoginType): void {
    // this.popupsvc.open(this.membersvc.memberPopupId);
    this.membersvc.openLoginPopCon(memberLoginType);
  }

  logout(): void {
    this.membersvc.LogOut(false);
    this.membersvc.closeLoginPop();
    // this.route.navigate([ this.languagesvc.nowLangNo + '/']);
    window.location.href = "/" + this.languagesvc.nowLangNo;

    // this.alertsvc.openAlert(this.translateService.instant('LogOutSuccessfully'), true);
  }

  toWishList(): void {
    if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData) &&
      this.baseDatasvc.memberData.loginType !== MemberLoginType.PnrLogin &&
      this.baseDatasvc.memberData.loginType !== MemberLoginType.TicketNumber) {
      this.route.navigate([this.languagesvc.nowLangNo + '/member/' + MemberCenterType[MemberCenterType.WishList]], {
        queryParams: {
          type: MemberCenterType[MemberCenterType.WishList]
        }
      });
      return;
    } else {
      const popData: GeneralPopModel = {
        title: '',
        content: this.translateService.instant('MemberWishListMemberLoginWording'),
        confirmButtonText: this.translateService.instant('MemberLoginAlertCloseButton'),
        loginButtonText: this.translateService.instant('MemberLoginAlertLoginButton')
      };

      this.popupsvc.openGeralPop(popData);
      // this.popupsvc.open(this.popupsvc.wishListCheckId, true);
    }
  }
}
