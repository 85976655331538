import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private injector: Injector) { }

  handleError(error: any): void {
    const router = this.injector.get(Router);
    if (Error instanceof HttpErrorResponse) {

    }
    else {
     console.error(error);
    }

    // return error page
    // router.navigate(['error']);
  }
}
