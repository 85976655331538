export enum ProductPriceType {
  priceA,
  priceB,
  priceC,
  priceD,
  priceE,
  priceF,
  priceG,
  priceH
}

export const ProductPriceTypeDisplay = new Map<number, string>([
  [ProductPriceType.priceA, '$1-50'],
  [ProductPriceType.priceB, '$51-100'],
  [ProductPriceType.priceC, '$101-200'],
  [ProductPriceType.priceD, '$201-300'],
  [ProductPriceType.priceE, '$301-400'],
  [ProductPriceType.priceF, '$401-500'],
  [ProductPriceType.priceG, '$501-1000'],
  [ProductPriceType.priceH, '$1000+']
]);
