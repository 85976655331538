import { element } from 'protractor';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiConfig } from './../config/api-config';
import { LanguageModel } from './model/language/language.model';
import { ResultModel } from './model/result-model';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { now } from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public languageAry: LanguageModel[] = [];
  nowLangNo = 'tw';

  constructor(
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private transiatesvc: TranslateService
  ) {

    const urlAry = this.router.url.split('/').map(String);
    this.nowLangNo = urlAry[1].slice(0, 2);
    this.transiatesvc.use(this.nowLangNo);
    this.getData();
  }

  getData(): Observable<ResultModel<LanguageModel[]>>{

    return this.http.get<ResultModel<LanguageModel[]>>(ApiConfig.APIDomain + ApiConfig.API.Languages).pipe(map(result => {

      if (result.Success) {

        this.languageAry = result.Data;
      }

      return result;
    }));
  }

  changeLanguage(langNo: string): void {


    const url: Observable<string> = this.activatedRoute.url.pipe(map(segments => segments.join('')));

    const urlAry = this.router.url.split('/');

    let newUrl = '';

    urlAry[1] = langNo;

    urlAry.forEach(element => {

      if (element.length > 0) {

        newUrl = newUrl + '/' + element;
      }
    });

    window.location.href = newUrl;
  }
}
