import { BrandService } from './../../service/brand.service';
import { LayoutService } from './../layout.service';
import { Component, OnInit } from '@angular/core';

import { SearchService } from './search.service';
import { BrandDataModel } from 'src/app/service/model/brand/brand.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  windowWidth = 0;
  isMobile = false;
  brandDataAry: BrandDataModel[] = [];
  constructor(
    public searchsvc: SearchService,
    public layoutsvc: LayoutService,
    public brandsvc: BrandService
  ) {

    this.windowWidth = document.body.clientWidth;

    if (this.windowWidth <= 991) {
      this.isMobile = true;
    }

    window.addEventListener('resize', () => {
      this.windowWidth = document.body.clientWidth;

      if (this.windowWidth <= 991 && this.isMobile === false) {
        this.isMobile = true;
      } else if (this.windowWidth > 991 && this.isMobile === true) {
        this.isMobile = false;
      }
    });
  }

  ngOnInit(): void {

    this.brandDataAry = this.brandsvc.brandsData;
  }
}
