import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'youtubeUrl'
})
export class YoutubeUrlPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    const youtubecode = value.replace('https://youtu.be/', '');

    // console.log('https://www.youtube.com/embed/' + youtubecode);

    return 'https://www.youtube.com/embed/' + youtubecode;
  }

}
