import { transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataService } from './../../../service/base-data.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { GeneralPopModel, PopupService } from './../../../shared/popup/popup.service';
import { LanguageService } from './../../../service/language.service';
import { AlertService } from './../../../shared/alert/alert.service';
import { ShoppingCartService } from './../../../service/shopping-cart.service';
import { Component, OnInit } from '@angular/core';

import { MenuService } from '../../menu/menu.service';
import { MemberService } from '../../../service/member.service';
import { MemberLoginType } from '../../../core/enums/member/member-login-type.enum';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TktLoginRequestModel } from 'src/app/service/model/member/tktLoginRequest.mode';
import { PnrLoginRequestModel } from 'src/app/service/model/member/pnrLoginRequest.model';
import { MemberPopupType } from 'src/app/core/enums/member/member-popup-type.enum';
import { DfpLoginRequestModel } from 'src/app/service/model/member/dfpLoginRequest.model';
import { CheckDfpIDRequestModel } from 'src/app/service/model/member/checkDfpIDRequest.model';
//import { GetDfpMemberInfoRequestModel } from 'src/app/service/model/member/getDfpMemberInfoRequest.model';

declare function excuteTurnstileFfp(lang):any;
declare function excuteTurnstileTkt(lang):any;

@Component({
  selector: 'app-mobile-member',
  templateUrl: './mobile-member.component.html',
  styleUrls: ['./mobile-member.component.css']
})
export class MobileMemberComponent implements OnInit {
  memberLoginTypeEnum = MemberLoginType;
  private captchaData: string;
  private captchaDataTkt: string;

  errorMsg: string[] = [];
  pwshow = false;
  isPnr = false;
  isTicket = false;
  tktCfInit = false;
  ffpCfInit = false;

  memberLogInForm = new FormGroup({
    DfpCardNumber: new FormControl('', [
      Validators.required
      //Validators.maxLength(9)
    ]),
    DfpPassword: new FormControl('', [
      Validators.required
      //Validators.minLength(6),
      //Validators.maxLength(20)
    ]),
    CaptchaCodeFfp: new FormControl('', [])
    //CaptchaCode: new FormControl('', [
      //Validators.required
      //Validators.minLength(4),
      //Validators.maxLength(4)
    //])
  });

  ticketLoginInForm = new FormGroup({
    Number: new FormControl('', [
      Validators.required
      //Validators.minLength(6)
      //Validators.maxLength(13)
    ]),
    FirstName: new FormControl('', [
      Validators.required
    ]),
    LastName: new FormControl('', [
      Validators.required
    ]),
    CaptchaCodeTkt: new FormControl('', [])
    //CaptchaCodeTkt: new FormControl('', [
      //Validators.required
      //Validators.minLength(4),
      //Validators.maxLength(4)
    //])
  });

  constructor(
    public menusvc: MenuService,
    public membersvc: MemberService,
    private shoppingCartsvc: ShoppingCartService,
    private alertsvc: AlertService,
    public languagesvc: LanguageService,
    private popupsvc: PopupService,
    private layoutsvc: LayoutService,
    public baseDatasvc: BaseDataService,
    private shoppingcartsvc: ShoppingCartService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.onChanges();
    this.resetCaptcha();
  }

  resetCaptcha(): void {
    //this.membersvc.getCaptcha().subscribe(result => {
//      if (result.isOK) {
//        this.captchaData = "data:image/png;base64,"+ result.data;
        //this.captchaDataTkt = "data:image/png;base64,"+ result.data;
        //console.log(this.captchaData);
      //}
    //});
  }
 
  ngAfterViewInit(): void {
    //this.resetCaptcha();
    let _lang = 'en';
    if (this.languagesvc != null && this.languagesvc.nowLangNo != '')
    {
      _lang = this.languagesvc.nowLangNo;
    }
    else
    {
      if (location.toString().toLowerCase().indexOf("/tw") > -1){_lang = 'tw';} else{_lang = 'en';}
    }
    if (this.membersvc.memberLoginType == this.memberLoginTypeEnum.DynastyMember)
    {
      //console.log("ngAfterViewInit-->DynastyMember")
      //console.log(this.ffpCfInit)
      if (!this.ffpCfInit){
        let cfscript = document.createElement('script');
        cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_ffp', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});});";
        if (!this.ffpCfInit && !this.tktCfInit){
          //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                           //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
        }
        document.getElementsByTagName('head')[0].appendChild(cfscript);
        this.ffpCfInit = true;
      }
    }
    if (this.membersvc.memberLoginType == this.memberLoginTypeEnum.TicketNumber)
    {
      //console.log("ngAfterViewInit-->TicketNumber")
      //console.log(this.tktCfInit)
      if (!this.tktCfInit){
        let cfscript = document.createElement('script');
        cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_tkt', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});});";
        if (!this.ffpCfInit && !this.tktCfInit){
          //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                           //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
        }
        document.getElementsByTagName('head')[0].appendChild(cfscript);
        this.tktCfInit = true;
      }
    }
    
  }

  changeMemberToFfp():boolean {
    //this.resetCaptcha();
    //console.log("changeMemberToFfp-->DynastyMember")
    //console.log(this.ffpCfInit)
    let _lang = 'en';
    if (this.languagesvc != null && this.languagesvc.nowLangNo != '')
    {
      _lang = this.languagesvc.nowLangNo;
    }
    else
    {
      if (location.toString().toLowerCase().indexOf("/tw") > -1){_lang = 'tw';} else{_lang = 'en';}
    }
    if (!this.ffpCfInit){
      let cfscript = document.createElement('script');
      //cfscript.text = "$('#cf-turnstile_ffp').html($('#cf-turnstile').html())";
      cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_ffp', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});});";
      if (!this.ffpCfInit && !this.tktCfInit){
        //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                         //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
      }
      document.getElementsByTagName('head')[0].appendChild(cfscript);
      this.ffpCfInit = true;
    }else
    {
      //console.log("excuteTurnstileFfp");
      excuteTurnstileFfp(_lang);
      this.ffpCfInit = true;
    }
    return true;
  }
  changeMemberToTkt():boolean {
    //this.resetCaptcha();
    //console.log("changeMemberToFfp-->TicketNumber")
    //console.log(this.tktCfInit)
    let _lang = 'en';
    if (this.languagesvc != null && this.languagesvc.nowLangNo != '')
    {
      _lang = this.languagesvc.nowLangNo;
    }
    else
    {
      if (location.toString().toLowerCase().indexOf("/tw") > -1){_lang = 'tw';} else{_lang = 'en';}
    }
    if (!this.tktCfInit){
      let cfscript = document.createElement('script');
      cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_tkt', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});});";
      if (!this.ffpCfInit && !this.tktCfInit){
        //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                         //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
      }
      document.getElementsByTagName('head')[0].appendChild(cfscript);
      this.tktCfInit = true;
    }
    else
    {
      //console.log("excuteTurnstileTkt");
      excuteTurnstileTkt(_lang);
      this.tktCfInit = true;
    }
    return true;
  }

  openLogin(type: MemberLoginType): void {
    this.errorMsg = [];
    this.alertsvc.openLoading(true);
    this.resetCaptcha();
    const mobile_member_body = document.getElementsByClassName('mobile-warp');
    mobile_member_body[0].scrollTo(0, 0);

    setTimeout(() => {
      this.membersvc.memberLoginType = type;
      this.alertsvc.openLoading(false);
    }, 100);
  }

  get DfpCardNumber(): AbstractControl { return this.memberLogInForm.get('DfpCardNumber'); }
  get DfpPassword(): AbstractControl { return this.memberLogInForm.get('DfpPassword'); }
  get Number(): AbstractControl { return this.ticketLoginInForm.get('Number'); }
  get FirstName(): AbstractControl { return this.ticketLoginInForm.get('FirstName'); }
  get LastName(): AbstractControl { return this.ticketLoginInForm.get('LastName'); }
  get CaptchaCode(): AbstractControl { return this.memberLogInForm.get('CaptchaCode'); }
  get CaptchaCodeTkt(): AbstractControl { return this.ticketLoginInForm.get('CaptchaCodeTkt'); }

  async memberFormSubmit(): Promise<void> {
    this.errorMsg = [];
    let captchacode = $('#CaptchaCodeFfp').text();
    if (this.memberLogInForm.invalid) {
      if (this.memberLogInForm.get('DfpCardNumber').invalid) {
        this.memberLogInForm.setErrors({ ...this.memberLogInForm.errors, 'DfpCardNumber': true });
      }

      if (this.memberLogInForm.get('DfpPassword').invalid) {
        this.memberLogInForm.setErrors({ ...this.memberLogInForm.errors, 'DfpPassword': true });
      }

      //if (this.memberLogInForm.get('CaptchaCode').invalid) {
//        this.memberLogInForm.setErrors({ ...this.memberLogInForm.errors, 'CaptchaCode': true });
      //}
      // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyCardOrPwd'));

      return;
    }

    const condition: CheckDfpIDRequestModel = {
      DfpCardNumber: this.DfpCardNumber.value,
      DfpPassword: this.DfpPassword.value,
      CaptchaCode: captchacode//his.CaptchaCodeFfp.value
    };

    this.alertsvc.openLoading(true);

    (await this.membersvc.CheckDfpID(condition)).subscribe(async result => {
      if (!result.isOK) {
        this.resetCaptcha();
        this.alertsvc.openLoading(false);
        this.errorMsg.push(result.message);
        return;
      }

      const loginCondigion: DfpLoginRequestModel = {
        DfpCardNumber: condition.DfpCardNumber
      };
      (await this.membersvc.DfpLogin(loginCondigion)).subscribe(loginResult => {
        this.alertsvc.openLoading(false);
        this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);

        if (loginResult.isOK) {
          if (loginResult.data.itinerary !== null || loginResult.data.itinerary.length > 0) {
            this.shoppingCartsvc.openMobleShoppingCart();
            this.menusvc.closeMobileMenu();
          } else {
            this.openFlightSelect();
          }
        } else {
          this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          this.menusvc.closeMobileMenu();
          this.openFlightSelect();
        }
      }, (error) => {
        this.resetCaptcha();
      });

    }, (error) => {
      this.resetCaptcha();
     });
  }

  async ticketFormSubmit(): Promise<void> {
    this.errorMsg = [];
    let captchacode = $('#CaptchaCodeTkt').text();
    if (this.ticketLoginInForm.invalid) {
      if (this.ticketLoginInForm.get('Number').invalid) {
        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'Number': true });
        // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyPnrOrTicketNumber'));
      }

      if (this.ticketLoginInForm.get('FirstName').invalid) {
        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'FirstName': true });
        // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyLastName'));
      }

      if (this.ticketLoginInForm.get('LastName').invalid) {
        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'LastName': true });
        // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyLastName'));
      }
      if (this.ticketLoginInForm.get('CaptchaCodeTkt').invalid) {
        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'CaptchaCodeTkt': true });
      }
      return;
    }

    const ticketNumber: string = this.ticketLoginInForm.get('Number').value;

    if (ticketNumber.length === 6 || ticketNumber.length === 13) {
      if (ticketNumber.length === 6) {
        const condition: PnrLoginRequestModel = {
          PaxLocator: this.Number.value,
          PaxFirstName: this.FirstName.value,
          PaxLastName: this.LastName.value,
          CaptchaCode: captchacode//this.CaptchaCodeTkt.value
        };

        this.alertsvc.openLoading(true);

        (await this.membersvc.PnrLogin(condition)).subscribe(result => {
          this.alertsvc.openLoading(false);
          if (result.isOK) {
            // this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);
            const popData: GeneralPopModel = {
              content: this.translateService.instant('MemberLoginAlertContent'),
              confirmButtonText: this.translateService.instant('MemberLoginAlertCloseButton'),
              loginButtonText: this.translateService.instant('MemberLoginAlertLoginButton')
            };

            this.popupsvc.openGeralPop(popData);

            this.shoppingCartsvc.openMobleShoppingCart();
            this.menusvc.closeMobileMenu();
          } else {
            this.resetCaptcha();
            this.errorMsg.push(result.message);
            // this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          }
        }, (error) => {
          this.resetCaptcha();
          this.errorMsg.push(error);
         });
      }

      if (ticketNumber.length === 13) {
        if (this.containSpecial(ticketNumber)) {
          this.errorMsg.push(this.translateService.instant('MemberLoginToolTicketError'));

          return;
        }

        const condition: TktLoginRequestModel = {
          TicketNumber: this.Number.value,
          PaxFirstName: this.FirstName.value,
          PaxLastName: this.LastName.value,
          CaptchaCode: captchacode//this.CaptchaCodeTkt.value
        };

        this.alertsvc.openLoading(true);

        (await this.membersvc.TktLogin(condition)).subscribe(result => {
          this.alertsvc.openLoading(false);
          // this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);
          const popData: GeneralPopModel = {
            content: this.translateService.instant('MemberLoginAlertContent'),
            confirmButtonText: this.translateService.instant('MemberLoginAlertCloseButton'),
            loginButtonText: this.translateService.instant('MemberLoginAlertLoginButton')
          };

          this.popupsvc.openGeralPop(popData);

          if (result.isOK) {
            this.shoppingCartsvc.openMobleShoppingCart();
            this.menusvc.closeMobileMenu();
          } else {
            this.resetCaptcha();
            this.errorMsg.push(result.message);
            // this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          }
        }, (error) => { 
          this.resetCaptcha();
        });
      }

      return;
    }

    this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyPnrOrTicketNumber'));

    return;
  }

  passwordShowHide(): void {
    this.pwshow = !this.pwshow;
  }

  openFlightSelect(): void {
    if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      this.shoppingcartsvc.openMobleShoppingCart();
    } else {
      this.menusvc.openMobileMenu();
    }
  }

  containSpecial(str: string) {
    var specialChars = "~·`!！@#$￥%^…&*()（）—-_=+[]{}【】、|\\;:；：'\"“‘,./<>《》?？，。";
    var len = specialChars.length;
    for (var i = 0; i < len; i++) {
      if (str.indexOf(specialChars.substring(i, i + 1)) != -1) {
        return true;
      }
    }
    return false;
  }

  onChanges(): void {
    this.ticketLoginInForm.valueChanges.subscribe(val => {
      const ticketInputValue = val.Number;
      if (ticketInputValue.length === 6) {
        this.isPnr = true;
        this.isTicket = false;
      } else if (ticketInputValue.length === 13) {
        this.isPnr = false;
        this.isTicket = true;
      } else {
        this.isPnr = false;
        this.isTicket = false;
      }
    });
  }
}
