export enum MemberLoginType {

  /// 會員登入
  DynastyMember,

  /// 機票號碼
  TicketNumber,

  /// 訂位代號
  PnrLogin,

  /// 員工 Id
  EmployID
}
