import { MemberLoginType } from 'src/app/core/enums/member/member-login-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataService } from 'src/app/service/base-data.service';
import { LanguageService } from './../../../../service/language.service';
import { MemberService } from './../../../../service/member.service';
import { MenuService } from './../../../menu/menu.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ShoppingCartService } from '../../../../service/shopping-cart.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginResponseItineraryModel, LoginResponseModel } from 'src/app/service/model/member/loginResponse.model';
import { MemberCenterType } from 'src/app/core/enums/member/member-center-type.enum';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mobile-shopping-cart-flight',
  templateUrl: './mobile-shopping-cart-flight.component.html',
  styleUrls: ['./mobile-shopping-cart-flight.component.css']
})
export class MobileShoppingCartFlightComponent implements OnInit {

  memberFlightData: LoginResponseModel = null;
  // memberFlightAry: LoginResponseItineraryModel[] = [];
  errorMsg: string[] = [];

  memberLoginTypeEnum = MemberLoginType;

  flightForm = new FormGroup({
    paxLocator: new FormControl('', [
      Validators.required
    ])
  });

  constructor(
    private route: Router,
    public shoppingCartsvc: ShoppingCartService,
    private menusvc: MenuService,
    public membersvc: MemberService,
    private languagesvc: LanguageService,
    public baseDatasvc: BaseDataService,
    private translateService: TranslateService,
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  get paxLocator(): AbstractControl { return this.flightForm.get('paxLocator'); }

  getData(): void {


  }

  formSubmit(): void {

    if (this.flightForm.invalid) {

      if (this.flightForm.get('paxLocator').invalid) {

        this.flightForm.setErrors({ ...this.flightForm.errors, 'paxLocator': true });

        // this.errorMsg.push('請選擇行程');
      }

      this.shoppingCartsvc.closeMobleShoppingCart();

      return;
    }

    const inputResult = this.flightForm.get('paxLocator').value;
    const inputResultAry = inputResult.split('/');
    const flithtData = this.baseDatasvc.memberData.loginData.itinerary.find(x => x.paxLocator === inputResultAry[0] && x.fltDepLclDate.toString() === inputResultAry[1]);

    this.membersvc.addFlightData(flithtData);

    this.membersvc.changeFlightData();

    this.shoppingCartsvc.shoppingGet().subscribe(shoppingGetResult => {

    }, (error) => {});

    this.shoppingCartsvc.closeMobleShoppingCart();
  }

  later(): void {

    this.shoppingCartsvc.closeMobleShoppingCart();
  }

  confirm(): void {
    this.shoppingCartsvc.closeMobleShoppingCart();
    this.route.navigate([this.languagesvc.nowLangNo + 'shoppingCart']);
  }

  convertToDay(data: LoginResponseItineraryModel): string {

    let returnStr = '';
    const minToDay = 1440;
    const minToHr = 60;

    if (!data.isOpen && data.timeToClose === 0 && data.timeToOpen === 0) {

      returnStr = this.translateService.instant('FlightChoosebottomNonOpen');

      return returnStr;
    }

    if (data.isOpen) {

      returnStr = this.translateService.instant('FlightChooseLeftTime');

      if (data.timeToClose > minToDay) {

        returnStr = returnStr +
          Math.floor(data.timeToClose / minToDay).toString() +
          this.translateService.instant('FlightChooseDays') +
          this.translateService.instant('FlightChooseLeft');

      } else if (data.timeToClose > minToHr) {

        returnStr = returnStr +
          Math.ceil(data.timeToClose / minToHr).toString() +
          this.translateService.instant('FlightChooseHours') +
          this.translateService.instant('FlightChooseLeft');
      } else {

        returnStr = returnStr +
          data.timeToClose.toString() +
          this.translateService.instant('FlightChooseMinutes') +
          this.translateService.instant('FlightChooseLeft');
      }

    } else {

      if (data.timeToOpen > minToDay) {

        returnStr = returnStr +
          Math.floor(data.timeToOpen / minToDay).toString() +
          this.translateService.instant('FlightChooseDays') +
          this.translateService.instant('FlightChooseToOpen');
      } else if (data.timeToOpen > minToHr) {

        returnStr = returnStr + Math.ceil(data.timeToOpen / minToHr).toString() +
          this.translateService.instant('FlightChooseHours') +
          this.translateService.instant('FlightChooseToOpen');
      } else {

        returnStr = returnStr + data.timeToOpen.toString() +
          this.translateService.instant('FlightChooseMinutes') +
          this.translateService.instant('FlightChooseToOpen');
      }
    }

    return returnStr;
  }

  goOrderList(flightData?: LoginResponseItineraryModel): void {
    this.shoppingCartsvc.closeMobleShoppingCart();
    if (flightData) {
      const shoppingCarNo = flightData.paxLocator +
                            flightData.loadDate +
                            flightData.itineraryRefNumber.toString() +
                            flightData.paxRefNumber.toString();

      this.route.navigate([this.languagesvc.nowLangNo + '/member/' + MemberCenterType[MemberCenterType.Order]],{
        queryParams: {
          ShoppingCartNo: shoppingCarNo,
          FltDate: this.datepipe.transform(flightData.fltDepLclDate, 'yyyyMMdd').toString()
        }
      });
    }else{
      this.route.navigate([this.languagesvc.nowLangNo + '/member/' + MemberCenterType[MemberCenterType.Order]]);
    }
  }

  browseFirst(): void {
    this.shoppingCartsvc.closeMobleShoppingCart();
    this.route.navigate([this.languagesvc.nowLangNo + '/']);
  }
}
