import { CryptoService } from './crypto.service';
import { LocalStorageType } from '../core/enums/localStorage/local-storage-type.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private cryptosvc: CryptoService) { }

  add(localStorageType: LocalStorageType, setvalue: any): void {

    const key = LocalStorageType[localStorageType];

    const value = this.cryptosvc.encryptData(setvalue);

    localStorage.setItem(key, value);
  }

  remove(localStorageType: LocalStorageType): void {

    const key = LocalStorageType[localStorageType];

    localStorage.removeItem(key);
  }

  getByName<T>(localStorageType: LocalStorageType): T {

    const key = LocalStorageType[localStorageType];

    const value = localStorage.getItem(key);

    const result = this.cryptosvc.decryptData(value);

    return result as T;
  }
}
