<div class="sky-navbar">
  <nav class="head_navbar_bg" (mouseleave)="this.closeMenu()">
    <ul class="sky-navbar-list">

      <li class="sky-navbar-container" *ngFor="let menuData of this.menuDataList">
        <a href="javascript:;" class="sky-navbar-first" (mouseenter)="this.clickMenu($event)">{{menuData.CategoryName}}</a>
        <div class="sky-navbar-con-box" [ngSwitch]="menuData.CategoryType">

          <div class="sub-container type1" *ngSwitchCase="this.categoryType.Type1">
            <div class="sky-navbar-sub sky-ml-60">
              <div class="sky-menu-headings">
                <h2>{{"MenuFeatures" | translate}}</h2>
              </div>

              <ul class="text-transform">
                <li class="sky-item" *ngFor="let productFeatureData of menuData.ProductFeature">
                  <a class="menu_link sub_link"
                  [routerLink]="['features', productFeatureData.ProductFeatureName]"
                  [queryParams]="{ name: productFeatureData.ProductFeatureName, categorieIds: menuData.Id, productFeatureIds: productFeatureData.Id, page: 1 }">
                    <p>{{productFeatureData.ProductFeatureName}}</p>
                  </a>
                </li>
              </ul>
            </div>

            <div class="sky-navbar-sub-row">
              <div class="sky-menu-headings">
                <h2>{{menuData.CategoryName}}</h2>
              </div>
              <div class="sky-menu-con">
                <a class="sky-item title menu_link"
                [routerLink]="['product']"
                [queryParams]="{ name: menuData.CategoryName, categoryIds: menuData.Id, page: 1 }">
                  <ng-container *ngIf="this.languagesvc.nowLangNo !== 'tw'">
                    {{"MenuViewAll" | translate}}
                  </ng-container>
                  <ng-container *ngIf="this.languagesvc.nowLangNo === 'tw'">
                    {{"MenuViewAll" | translate}}{{menuData.CategoryName}}
                  </ng-container>
                </a>
                <div class="row">
                  <ul class="col-3" *ngFor="let subCategoriesData of menuData.SubCategories">
                    <li class="sky-item">
                      <h3>
                        <a class="menu_link"
                        [routerLink]="['product']"
                        [queryParams]="{ name: menuData.CategoryName, categoryIds: menuData.Id, subIds: subCategoriesData.Id, page: 1 }">{{subCategoriesData.SubCategoryName}}</a>
                      </h3>
                    </li>
                    <li class="sky-item" *ngFor="let branchCategoriesData of subCategoriesData.BranchCategories">
                      <a class="menu_link sub_link" [class]="this.languagesvc.nowLangNo === 'en' ? 'en' : ''"
                      [routerLink]="['product']"
                      [queryParams]="{ name: menuData.CategoryName, categoryIds: menuData.Id, subIds: subCategoriesData.Id, branchIds: branchCategoriesData.Id, page: 1 }">
                        <p>{{branchCategoriesData.BranchCategoryName}}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="sky-navbar-sub-scroll">
              <div class="sky-menu-headings">
                <h2>{{"MenuBrand" | translate}}</h2>
              </div>
              <app-pc-menu-brand-search [brandsDataAry]='menuData.Brands'></app-pc-menu-brand-search>
            </div>
          </div>

          <div class="sub-container short-con type2" *ngSwitchCase="this.categoryType.Type2">
            <div class="sky-navbar-sub sky-ml-60">
              <div class="sky-menu-headings">
                <h2>{{"MenuCategory" | translate}}</h2>
              </div>
              <ul class="sky-menu-con">
                <a class="sky-item title mt-0 menu_link"
                [routerLink]="['product']"
                [queryParams]="{ categoryType: this.categoryType.Type2, name: menuData.CategoryName, categoryIds: menuData.Id, productFeatureIds: menuData.ProductFeatureId, page: 1 }">{{"MenuEventViewAll" | translate}}</a>
                <li class="sky-item" *ngFor="let categorieData of menuData.Categories">
                  <a class="menu_link sub_link"
                  [routerLink]="['product']"
                  [queryParams]="{ categoryType: this.categoryType.Type2, name: menuData.CategoryName, categoryIds: menuData.Id, eventCategorieIds: categorieData.Id, productFeatureIds: menuData.ProductFeatureId, page: 1 }">
                    <p>{{categorieData.CategoryName}}</p>
                  </a>
                </li>
              </ul>
            </div>
            <div class="sky-navbar-sub-scroll sky-ml-60">
              <div class="sky-menu-headings">
                <h2>{{"MenuBrand" | translate}}</h2>
              </div>
              <app-pc-menu-brand-search [brandsDataAry]='menuData.Brands'></app-pc-menu-brand-search>
            </div>
            <div class="sky-navbar-img">
              <ng-container *ngIf="menuData.MenuRightBanners.length > 0">
                <app-pc-menu-banner [bannerAry]="menuData.MenuRightBanners"></app-pc-menu-banner>
              </ng-container>
            </div>
          </div>

          <div class="sub-container short-con type3" *ngSwitchCase="this.categoryType.Type3">
            <div class="sky-navbar-sub sky-ml-60">
              <div class="sky-menu-headings">
                <h2>{{"MenuEvent" | translate}}</h2>
              </div>
              <ul class="sky-menu-con">
                <ng-container *ngFor="let activitie of menuData.Activities">
                <li class="sky-item" *ngIf="!activitie.IsFFPOnly && !activitie.IsStaffOnly">
                  <a class="menu_link"
                  [routerLink]="['activity/' + activitie.UrlName]" [queryParams]="{ Id: activitie.Id }">
                    <p>{{activitie.ActivityName}}</p>
                  </a>
                  <!-- <a routerLink="/{{this.languagesvc.nowLangNo}}/activity/{{activitie.UrlName}}">
                    <p>{{activitie.ActivityName}}</p>
                  </a> -->
                </li>
                <li class="sky-item" *ngIf="this.membersvc.isLoginSubject.value && this.baseDatasvc.memberData.loginType === this.loginTypeEnum.DynastyMember && activitie.IsFFPOnly ">
                  <a class="menu_link" [routerLink]="['activity/member']">
                    <p>{{activitie.ActivityName}}</p>
                  </a>
                </li>
                <li class="sky-item" *ngIf="this.membersvc.isLoginSubject.value && this.baseDatasvc.memberData.loginType === this.loginTypeEnum.EmployID  && activitie.IsStaffOnly">
                  <a class="menu_link" [routerLink]="['activity/member']">
                    <p>{{activitie.ActivityName}}</p>
                  </a>
                </li>
              </ng-container>
              </ul>
            </div>
            <div class="sky-navbar-sub-scroll sky-ml-60"></div>
            <div class="sky-navbar-img">
              <ng-container *ngIf="menuData.MenuRightBanners.length > 0">
                <app-pc-menu-banner [bannerAry]="menuData.MenuRightBanners"></app-pc-menu-banner>
              </ng-container>
            </div>
          </div>

          <div class="sub-container short-con type4" *ngSwitchCase="this.categoryType.Type4">
            <div class="sky-navbar-sub sky-ml-60">
              <div class="sky-menu-headings">
                <h2>{{"MenuBrandCollection" | translate}}</h2>
              </div>
              <ul class="sky-menu-con">
                <li class="sky-item">
                  <a class="menu_link" [routerLink]="['all brands']">
                    <p>{{"MenuAllBrand" | translate}}</p>
                  </a>
                </li>
                <li class="sky-item">
                  <a class="menu_link" [routerLink]="['brands pavilion']">
                    <p>{{"MenuBrandPavilion" | translate}}</p>
                  </a>
                </li>
              </ul>
            </div>
            <div class="sky-navbar-sub-scroll sky-ml-60">
              <div class="sky-menu-headings">
                <h2>{{"MenuBrand" | translate}}</h2>
              </div>
              <app-pc-menu-brand-search [brandsDataAry]='this.brandsvc.brandsData'></app-pc-menu-brand-search>
            </div>
            <div class="sky-navbar-img">
              <ng-container *ngIf="menuData.MenuRightBanners.length > 0">
                <app-pc-menu-banner [bannerAry]="menuData.MenuRightBanners"></app-pc-menu-banner>
              </ng-container>
            </div>
          </div>
          <ng-container *ngSwitchDefault></ng-container>
        </div>
      </li>

    </ul>
    <div class="sky-navbar-bg"></div>
  </nav>
  <div class="sky-cover-bg" (click)='this.menu(false)'></div>
</div>
