import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobilePageToolComponent } from './mobile-page-tool.component';



@NgModule({
  declarations: [MobilePageToolComponent],
  imports: [CommonModule],
  exports: [MobilePageToolComponent]
})
export class MobilePageToolModule { }
