<li class="sky-graphic-itme">
  <div class="sky-graphic">
    <div class="graphic_img"><img src="./assets/content/images/arrivals_1.png"></div>
    <div class="graphic_text">
      <h3>{{productData.ProductNo}}</h3>
      <h2 class="ellipsis-2 mt-1">{{productData.ProductName}}</h2>
      <div class="quantity_box">
        <button type="button" class="btn reduce" (click)="this.remove()">
          <span class="material-icons">
            remove
          </span>
        </button>
        <input class="quantity" type="text" [(ngModel)]="count" #tCx xxount="ngModel" placeholder="brand">
        <button type="button" class="btn add" (click)="this.add()">
          <span class="material-icons">add</span>
        </button>
      </div>
    </div>
  </div>
  <div class="position-relative">
    <div class="ic-movetowishlish">
      <a href="#" title=""></a>
    </div>
    <div class="ic-trash">
      <a href="javascript:;" title="" (click)="this.removeFromCart(productData.ProductNo, productData.ProductType, productData.Count)"></a>
    </div>
    <div class="text mt-2">
      <p>USD {{productData.ProductPrice}}<span></span></p>
      <h4 class="through">USD {{productData.ProductPrice}}</h4>
    </div>
  </div>
</li>
