<ng-container *ngIf="this.membersvc.isLoginSubject.value && this.memberLoginData">
  <div class="member_modal_title">
    <p *ngIf="!this.baseDatasvc.flightData">{{"FlightChooseTitleWording" | translate}}</p>
    <p *ngIf="this.baseDatasvc.flightData">{{"FlightChangeTitleWording" | translate}}</p>
  </div>

  <div class="member_modal_subtitle text-center" *ngIf="!this.baseDatasvc.flightData">{{"FlightChooseContentWording" | translate}}</div>
  <div class="member_modal_subtitle text-center" *ngIf="this.baseDatasvc.flightData">{{"FlightChangeContentWording" | translate}}</div>
  <div class="member_error_msg" *ngIf="this.errorMsg.length > 0">
    <div>
      <span *ngFor="let item of this.errorMsg">{{item}}</span>
    </div>
  </div>
  <form [formGroup]="flightForm" (ngSubmit)="this.formSubmit()" #form="ngForm">
    <div class="member_tablePanel">
      <table class="member_tableBlockRowspan">
        <thead>
          <tr>
            <th width="10%">{{"FlightChooseTableHeader_TheTableHeader" | translate}}</th>
            <th width="8%">{{"FlightChooseTableHeader_Flight" | translate}}</th>
            <th width="20%">{{"FlightChooseTableHeader_Trip" | translate}}</th>
            <th width="17%">{{"FlightChooseTableHeader_DepartureTime" | translate}}</th>
            <th width="15%">{{"FlightChooseTableHeader_BookingDeadline" | translate}}</th>
            <th width="10%">{{"FlightChooseTableHeader_Action" | translate}}</th>
          </tr>
        </thead>
        <tbody *ngIf="this.memberLoginData !== null">
          <tr *ngFor="let item of this.memberLoginData.itinerary">
            <td class="px-2">{{item.paxLocator}}</td>
            <td class="px-2">{{item.fltNumber}}</td>
            <td>
              <div class="row no-gutters px-2">
                <div class="col d-inline text-right">
                  <!-- <div>{{item.fltDepStationName}}</div> -->
                  {{item.fltDepStationCode}}
                </div>
                <div class="col d-inline text-center">一</div>
                <div class="col d-inline text-left">
                  <!-- <div>{{item.fltArvStationName}}</div> -->
                  {{item.fltArvStationCode}}
                </div>
              </div>
            </td>
            <td class="px-2">{{item.fltDepLclDate | date:'yyyy/MM/dd HH:mm'}}</td>
            <td class="px-2">
              <div>{{this.convertToDay(item)}}</div>
              <!-- <div class="table_small_color mt-2">x2020/06/04 13:00 deadline</div> -->
            </td>
            <td class="px-2 position-relative">
              <label class="i-radio" *ngIf="item.isOpen && item.orderNumber === null">
                <input type="radio"
                       value="{{item.paxLocator}}/{{item.fltDepLclDate.toString()}}/{{item.fltNumber}}"
                       formControlName="paxLocator"
                       name="paxLocator"
                       required>
                <span class="checkmark text-transform"></span>
              </label>

              <label *ngIf="item.orderNumber !== null">
                <!-- {{"FlightChooseReserved" | translate}} -->
                <button class="sky-item-button" style="padding: 5px;" type="button" (click)="this.goOrderList(item)">{{"FlightCheckOrder" | translate}}</button>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="member_modal_prompt">
      <p style="color:red;">{{"FlightChoosebottomWording" | translate}}</p>
      <!-- <button class="sky-item-button" type="button" (click)="this.goOrderList()">{{"FlightChooseMyOrder" | translate}}</button> -->
    </div>
    <div class="member_modal_foot">
      <h2>{{"LoginDynatyMemberBottomWordingTitle" | translate}}</h2>
      <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.DynastyMember">
        <ul>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_1" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_2" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_3" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_4" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_5" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_6" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_7" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_8" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_9" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_10" | translate | safeHtml'></li>
          <li [innerHTML]='"LoginDynatyMemberBottomWording_11" | translate | safeHtml'></li>
        </ul>
      </ng-container>
      <ng-container *ngIf="this.baseDatasvc.memberData.loginType !== this.memberLoginTypeEnum.DynastyMember">
        <ul>
          <li [innerHTML]='"LogiTicketLoginBottomWording_1" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_2" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_3" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_4" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_5" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_6" | translate | safeHtml'></li>
          <!--<li [innerHTML]='"LogiTicketLoginBottomWording_7" | translate | safeHtml'></li>-->
          <!--<li [innerHTML]='"LogiTicketLoginBottomWording_8" | translate | safeHtml'></li>-->
          <li [innerHTML]='"LogiTicketLoginBottomWording_9" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_10" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_11" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_12" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_13" | translate | safeHtml'></li>
        </ul>
      </ng-container>
    </div>
    <div class="tabled_button catalog-button">
      <button class="sky-item-button" type="button" (click)="this.later()" *ngIf="!this.baseDatasvc.flightData">{{"FlightChooseLater" | translate}}</button>
      <button class="sky-item-button" type="button" (click)="this.later()" *ngIf="this.baseDatasvc.flightData">{{"FlightChooseCancel" | translate}}</button>
      <button class="sky-item-button main" type="submit">{{"FlightChooseConfirm" | translate}}</button>
    </div>
  </form>
</ng-container>
