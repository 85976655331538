import { MobilePageToolService } from './mobile-page-tool.service';
import { LayoutService } from './../../layout/layout.service';
import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-mobile-page-tool',
  templateUrl: './mobile-page-tool.component.html',
  styleUrls: ['./mobile-page-tool.component.css'],
  animations: [
    trigger('slideInOut', [
      state('open',  style({
        transform: 'translateX(0%)'
      })),
      state('closed', style({
        transform: 'translateX(-100%)'
      })),
      transition('closed => open', [
        animate('0.3s linear')
      ]),
      transition('open => closed', [
        animate('0.3s linear')
      ])
    ])
  ]
})
export class MobilePageToolComponent implements OnInit, OnDestroy {

  @Input() id: string;

  private element: any;
  isOpen = false;

  constructor(
    private mobilePageToolsvc: MobilePageToolService,
    public layoutsvc: LayoutService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {

    if (!this.id) {

      return;
    }

    document.body.appendChild(this.element);

    this.mobilePageToolsvc.add(this);
  }

  ngOnDestroy(): void {
    this.mobilePageToolsvc.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.isOpen = this.mobilePageToolsvc.isOpen;
    document.getElementsByTagName('body')[0].classList.add('overflowHidden');
    document.getElementsByTagName('html')[0].classList.add('overflowHidden');
  }

  close(): void {
    this.isOpen = false;
    this.mobilePageToolsvc.isOpen = false;
    document.getElementsByTagName('body')[0].classList.remove('overflowHidden');
    document.getElementsByTagName('html')[0].classList.remove('overflowHidden');
  }

}
