import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  isMobile = false;
  windowWidth: number;
  windowHeight: number;
  isScrollDown = false;
  headerToolIsOpen = false;
  pcMenuIsOpen = false;
  headerHeight: number;
  isShowTop = true;

  constructor() { }
}
