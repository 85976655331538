import { Component, OnInit, HostListener } from '@angular/core';
import { MenuService } from './menu.service';
import { MenuDataModel } from '../../service/model/menu/menu.model';
import { LayoutService } from './../layout.service';
declare const jQuery: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  menuDataList: MenuDataModel[];
  isMobile = false;

  constructor(public layoutsvc: LayoutService, public menusvc: MenuService) { }

  ngOnInit(): void {

    if (window.innerWidth <= 991) {

      this.isMobile = true;
    } else {

      this.isMobile = false;
    }

    this.getData(this.layoutsvc.isMobile);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {

    if (this.layoutsvc.windowWidth <= 991 &&
        this.isMobile === false) {

      this.isMobile = true;
      this.getData(this.layoutsvc.isMobile);
    } else if (this.layoutsvc.windowWidth > 991 &&
               this.isMobile === true) {

      this.isMobile = false;
      this.getData(this.layoutsvc.isMobile);
    }
  }

  getData(isMobile: boolean): void {

    this.menusvc.getData(isMobile).subscribe(result => {

      if (this.layoutsvc.isMobile) {
        this.layoutsvc.headerHeight = $('header').height();
      }else{
        this.layoutsvc.headerHeight = $('header').height() + 55;
      }

      if (result.Success) {

        this.menuDataList = result.Data;
      } else {

      }
    },
    (error) => {

    });
  }
}
