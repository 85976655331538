<!-- Login start -->
<ng-container *ngIf="this.membersvc.memberLoginType === null">
  <div class="member_modal_container_m">
    <div class="head-container_m">
      <div class="sky-pt-100">
        <button class="sky-item-button main" type="button" (click)="this.openLogin(this.memberLoginTypeEnum.DynastyMember)">{{"MemberLoginToolDynastyMemberLogin" | translate}}</button>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'tw'">
          {{"MemberLoginToolNotAMemberYet" | translate}}
          <span><a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh" target="_blank">{{"MemberLoginToolSignUp" | translate}}</a></span>
        </p>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'en'">
          {{"MemberLoginToolNotAMemberYet" | translate}}
          <span><a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=en-us&country=tw&locale=en" target="_blank">{{"MemberLoginToolSignUp" | translate}}</a></span>
        </p>
        <div class="member-borer">
          <div class="text-transform">{{"MemberLoginToolOr" | translate}}</div>
        </div>
        <button class="sky-item-button" type="button" (click)="this.openLogin(this.memberLoginTypeEnum.TicketNumber)">{{"MemberLoginToolUseReservation/E-TicketNUMBER" | translate}}</button>
      </div>
    </div>
    <!--<ng-container>{{this.baseDatasvc.appid}}</ng-container>-->
  </div>
</ng-container>
<!-- Login end -->
<!-- DynastyMember Login start -->
<ng-container *ngIf="this.membersvc.memberLoginType === this.memberLoginTypeEnum.DynastyMember" [isshow]="this.changeMemberToFfp()">
  <div class="member_modal_container_m">
    <div class="head-container_m">
      <div class="sky-pt-30">
        <div class="member_modal_title_m">
          <p [innerHTML]='"LoginDynatyMemberTopWording" | translate | safeHtml'></p>
        </div>

        <div class="member_error_msg" *ngIf="this.errorMsg.length > 0">
          <div>
            <span *ngFor="let item of this.errorMsg">{{item}}</span>
          </div>
        </div>
        <form [formGroup]="memberLogInForm" (ngSubmit)="memberFormSubmit(form)" #form="ngForm">
          <div class="member_modal_input_m">
            <div class="mb-3">
              <label>{{"LoginDynatyMemberCardNo" | translate}}</label>
              <input class="form-control" 
                     [class]="(DfpCardNumber.invalid && (DfpCardNumber.dirty || DfpCardNumber.touched || this.memberLogInForm.hasError('DfpCardNumber')) ? 'error' : '')" 
                     type="text" 
                     placeholder=''
                     formControlName="DfpCardNumber" 
                     maxlength="9"
                     required>
            </div>
            <div class="mb-3">
              <div class="pwd_row">
                <label>{{"LoginDynatyMemberPassword" | translate}}</label>
                <input class="form-control" 
                       type="{{this.pwshow?'text':'password'}}" 
                       [class]="(DfpPassword.invalid && (DfpPassword.dirty || DfpPassword.touched || this.memberLogInForm.hasError('DfpPassword')) ? 'error' : '')" 
                       placeholder=''
                       formControlName="DfpPassword" 
                       required>
                <button class="eye_btn" type="button" (click)="this.passwordShowHide()"><img src="{{this.pwshow ? '../../../../../assets/content/images/ic_pasword_off.svg' : '../../../../../assets/content/images/ic_pasword_on.svg'}}"></button>
              </div>
            </div>
            <div class="mb-3">
              <div style="width:400px;display: inline-block;text-align: left;"></div>
              <!--<label>{{"CaptchaCode" | translate}}</label>
              <input class="form-control"
                    type="text" placeholder='{{"CaptchaCodePlaceHolder" | translate}}'
                    formControlName="CaptchaCode" required maxlength="4" />
              <a (click)="resetCaptcha()">
                  <img [src]="captchaData" alt="loading" style="height:40px;width:100px"/>
              </a>-->
              <div class="cf-turnstile" id="cf-turnstile_ffp" data-sitekey="0x4AAAAAAAKX6wTAA8oG0I5-"></div>
              <input type="text" id="CaptchaCodeFfp" hidden="true" />
            </div>
          </div>

          <button class="sign sky-item-button main" type="submit">{{"LoginFormSend" | translate}}</button>
        </form>

        <ul class="member_modal_text text-center">
          <li><a href="https://members.china-airlines.com/dynasty-flyer/crdqry.aspx" target="_blank">{{"LoginInquiryPassword" | translate}}</a></li>
          <li><a href="https://members.china-airlines.com/dynasty-flyer/pwdqry.aspx" target="_blank">{{"LoginInquiryDFPMemberNo" | translate}}</a></li>
          <li><a href="https://members.china-airlines.com/dynasty-flyer/pwdappl.aspx" target="_blank">{{"LoginApplyforPassword" | translate}}</a></li>
        </ul>

        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'tw'">
          {{"MemberLoginToolNotAMemberYet" | translate}}<span><a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh" target="_blank">{{"MemberLoginToolSignUp" | translate}}</a></span>
        </p>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'en'">
          {{"MemberLoginToolNotAMemberYet" | translate}}<span><a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=en-us&country=tw&locale=en" target="_blank">{{"MemberLoginToolSignUp" | translate}}</a></span>
        </p>
      </div>
    </div>
    <div class="member_modal_prompt">
      <p>{{"LoginResevation/E-ticketNumberChangeWording" | translate}}</p>
      <button class="sky-item-button" type="button" (click)="this.openLogin(this.memberLoginTypeEnum.TicketNumber)">{{"LoginUseResevation/E-ticketNumberButton" | translate}}</button>
    </div>
    <div class="member_modal_foot">
      <h2>{{"LoginDynatyMemberBottomWordingTitle" | translate}}</h2>
      <ul>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_1" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_2" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_3" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_4" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_5" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_6" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_7" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_8" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_9" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_10" | translate | safeHtml'></li>
        <li [innerHTML]='"LoginDynatyMemberBottomWording_11" | translate | safeHtml'></li>
      </ul>
    </div>
  </div>
</ng-container>
<!-- DynastyMember Login end -->
<!-- TicketNumber Login start -->
<ng-container *ngIf="this.membersvc.memberLoginType === this.memberLoginTypeEnum.TicketNumber" [isshow]="this.changeMemberToTkt()">
  <div class="member_modal_container_m">
    <div class="head-container_m">
      <div class="sky-pt-30">
        <div class="member_modal_title_m">
          <p [innerHTML]='"LoginReservation/E-TicketNumberTopWording" | translate | safeHtml'></p>
        </div>
        <div class="member_error_msg" *ngIf="this.errorMsg.length > 0">
          <div>
            <span *ngFor="let item of this.errorMsg">{{item}}</span>
          </div>
        </div>

        <form [formGroup]="ticketLoginInForm" (ngSubmit)="this.ticketFormSubmit(form)" #form="ngForm">
          <div class="member_modal_input_m">
            <div class="mb-3">
              <label>
                <span [class]='this.isPnr ? "font-weight-bold" : ""'>{{"LoginReservation" | translate}}</span> /
                <span [class]='this.isTicket ? "font-weight-bold" : ""'>{{"E-TicketNumber" | translate}}</span>*
              </label>
              <input class="form-control"
                     [class]="(Number.invalid && (Number.dirty || Number.touched || this.memberLogInForm.hasError('Number')) ? 'error' : '')"
                     type="text"
                     formControlName="Number"
                     required
                     placeholder=''>
            </div>
            <div class="mb-3">
              <label>{{"LoginLastName" | translate}}</label>
              <input class="form-control" placeholder='' [class]="(LastName.invalid && (LastName.dirty || LastName.touched || this.memberLogInForm.hasError('LastName')) ? 'error' : '')" type="text" formControlName="LastName"
                     required>
            </div>
            <div class="mb-3">
              <label>{{"LoginFirstName" | translate}}</label>
              <input class="form-control" placeholder='' [class]="(FirstName.invalid && (FirstName.dirty || FirstName.touched || this.memberLogInForm.hasError('FirstName')) ? 'error' : '')" type="text" formControlName="FirstName"
                     required>
            </div>
            <div class="mb-3">
              <div style="width:400px;display: inline-block;text-align: left;">
                <!--<label>{{"CaptchaCode" | translate}}</label>
                <input class="form-control"
                      type="text" placeholder='{{"CaptchaCodePlaceHolder" | translate}}'
                      formControlName="CaptchaCodeTkt" required maxlength="4" />
                <a (click)="resetCaptcha()">
                    <img [src]="captchaDataTkt" alt="loading" style="height:40px;width:100px"/>
                </a>-->
                <div class="cf-turnstile" id="cf-turnstile_tkt" data-sitekey="0x4AAAAAAAKX6wTAA8oG0I5-"></div>
                <input type="text" id="CaptchaCodeTkt" hidden="true" />
              </div>
            </div>
          </div>

          <button class="sign sky-item-button main" type="submit">{{"LoginFormSend" | translate}}</button>
        </form>

        <ul class="member_modal_text text-center">
          <li><a href="https://members.china-airlines.com/dynasty-flyer/crdqry.aspx" target="_blank">{{"LoginInquiryPassword" | translate}}</a></li>
          <li><a href="https://members.china-airlines.com/dynasty-flyer/pwdqry.aspx" target="_blank">{{"LoginInquiryDFPMemberNo" | translate}}</a></li>
          <li><a href="https://members.china-airlines.com/dynasty-flyer/pwdappl.aspx" target="_blank">{{"LoginApplyforPassword" | translate}}</a></li>
        </ul>

        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'tw'">
          {{"MemberLoginToolNotAMemberYet" | translate}}<span><a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh" target="_blank">{{"MemberLoginToolSignUp" | translate}}</a></span>
        </p>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'en'">
          {{"MemberLoginToolNotAMemberYet" | translate}}<span><a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=en-us&country=tw&locale=en" target="_blank">{{"MemberLoginToolSignUp" | translate}}</a></span>
        </p>
      </div>
    </div>
    <div class="member_modal_prompt">
      <p>{{"MemberToolLoginMobileWording" | translate}}</p>
      <button class="sky-item-button" type="button" (click)="this.openLogin(this.memberLoginTypeEnum.DynastyMember)">{{"LoginDynastyMemberLobinButton" | translate}}</button>
    </div>
    <div class="member_modal_foot">
      <h2>{{"LoginDynatyMemberBottomWordingTitle" | translate}}</h2>
      <ul>
        <li [innerHTML]='"LogiTicketLoginBottomWording_1" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_2" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_3" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_4" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_5" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_6" | translate | safeHtml'></li>
        <!--<li [innerHTML]='"LogiTicketLoginBottomWording_7" | translate | safeHtml'></li>-->
        <!--<li [innerHTML]='"LogiTicketLoginBottomWording_8" | translate | safeHtml'></li>-->
        <li [innerHTML]='"LogiTicketLoginBottomWording_9" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_10" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_11" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_12" | translate | safeHtml'></li>
        <li [innerHTML]='"LogiTicketLoginBottomWording_13" | translate | safeHtml'></li>
      </ul>
    </div>
  </div>
</ng-container>
<!-- TicketNumber Login end -->