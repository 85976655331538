import { ProductService } from './../../../service/product.service';
import { ProductPriceType } from './../../../core/enums/product/product-price-type.enum';
import { Router } from '@angular/router';
import { LayoutService } from './../../layout.service';
import { Component, OnInit, Input, ElementRef, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations';

import { SearchStepEnum } from './../search-step-enum.enum';
import { SearchService } from '../search.service';
import { BrandDataModel } from 'src/app/service/model/brand/brand.model';
import { NgModel } from '@angular/forms';
import { ProductFeatureModel } from 'src/app/service/model/product-feature/product-feature.model';
import { MenuDataModel } from 'src/app/service/model/menu/menu.model';
import { LanguageService } from 'src/app/service/language.service';
import { ProductFeatureService } from 'src/app/service/product-feature.service';
import { MenuService } from '../../menu/menu.service';
import { ProductFeatureConditionModel } from 'src/app/service/model/product-feature/product-feature-condition.model';
import { CategoryType } from 'src/app/core/enums/server/category-type.enum';
import { debounceTime } from 'rxjs/operators';
import { ProductModel } from 'src/app/service/model/product/product.model';
import { ProductConditionModel } from 'src/app/service/model/product/product-condition.model';
import { ProductType } from 'src/app/core/enums/product/product-type.enum';

export enum searchValueTypeEnum {
  Categorie,
  SubCategorie,
  BranchCategorie,
  ProductFeature,
  Brand,
  priceType
}

@Component({
  selector: 'app-mobile-search',
  templateUrl: './mobile-search.component.html',
  styleUrls: ['./mobile-search.component.css'],
  animations: [
    trigger('slideInOut', [
      state('open',  style({
        transform: 'translateX(0%)'
      })),
      state('closed', style({
        transform: 'translateX(100%)'
      })),
      transition('closed => open', [
        animate('0.3s linear')
      ]),
      transition('open => closed', [
        animate('0.3s linear')
      ])
    ])
  ]
})
export class MobileSearchComponent implements OnInit, AfterViewInit {

  private element: any;
  windowHeight: number;
  searchStepEnum = SearchStepEnum;
  @Input() brandDataAry: BrandDataModel[] = [];

  keyword = '';
  @ViewChild('tKey') tKey: NgModel;

  productKey = '';
  @ViewChild('tproductKey') tproductKey: NgModel;

  productFeatureAry: ProductFeatureModel[] = [];
  searchValueType = searchValueTypeEnum;
  brandsData: BrandDataModel[];
  CategorieAry: MenuDataModel[];

  categorieIds: number[] = [];
  subCategorieIds: number[] = [];
  branchCategorieIds: number[] = [];
  brandIds: number[] = [];
  productFeatureIds: number[] = [];
  priceTypes: number[] = [];

  searchResultData: ProductModel[] = [];

  searchConIsOpen = false;

  productPriceTypeEnum = ProductPriceType;

  constructor(
    private route: Router,
    public layoutsvc: LayoutService,
    private el: ElementRef,
    public searchsvc: SearchService,
    public languagesvc: LanguageService,
    private productFeaturesvc: ProductFeatureService,
    private menusvc: MenuService,
    private productsevc: ProductService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {

    document.getElementById('mobile_search').appendChild(this.element);

    this.windowHeight = window.outerHeight;

    window.addEventListener('resize', () => {

      this.windowHeight = window.outerHeight;
    });

    this.getProductFeature();
    this.getCatalogList();
  }
  ngAfterViewInit(): void {

    this.tproductKey.valueChanges.pipe(debounceTime(500)).subscribe(keyword => {

      // this.keyword = '';

      if (keyword.length > 0) {

        this.productKey = keyword;
        this.productSearch(keyword);
      }
    });
  }

  productSearch(key: string): void {

    this.searchConIsOpen = false;

    const condition: ProductConditionModel = {
      PageNumber: 1,
      PageSize: 5
    };

    if (this.checkProductNoVal(key)) {

      condition.ProductNo = key;
    }else{

      condition.KeyWord = key;
    }

    // condition.ProductTypes = [ProductType.General];

    this.productsevc.getProduct(condition).subscribe(result => {
      this.searchResultData = [];

      if (result.Success) {

        if (result.Data.AllDataCount > 0 && key.length > 0) {
          this.searchConIsOpen = true;
        }

        this.searchResultData = result.Data.Datas;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {

    this.windowHeight = window.innerHeight;
  }

  changePage(searchStep: SearchStepEnum): void {
    this.searchsvc.searchStep = searchStep;
  }
  backPage(searchStep: SearchStepEnum): void {
    switch (searchStep) {
      case (this.searchStepEnum.Step2): {
        this.searchsvc.searchStep = this.searchStepEnum.Step1;
        break;
      }
      case (this.searchStepEnum.Step3): {
        this.searchsvc.searchStep = this.searchStepEnum.Step2;
        break;
      }
      default: {
        this.searchsvc.searchStep = this.searchStepEnum.Step1;
      }
    }
  }
  closeSearch(): void {

    this.searchConIsOpen = false;
  }
  getProductFeature(): void {

    const condition: ProductFeatureConditionModel = { PageSize: 1000 };

    this.productFeaturesvc.getData(condition).subscribe(result => {

      if (result !== undefined && result.Data.Datas !== undefined) {

        this.productFeatureAry = result.Data.Datas;
      }
    },
      (error) => {

      });
  }
  getCatalogList(): void {

    this.menusvc.getData(false).subscribe(result => {
      if (result.Success) {
        this.CategorieAry = result.Data.filter(x => x.CategoryType === CategoryType.Type1);
      }
    }, (error) => { });
  }
  valueChange($event, valueType: searchValueTypeEnum, valueId: number): void {

    const checkStatus: boolean = $event.target.checked;

    switch (valueType) {

      case searchValueTypeEnum.Categorie: {

        const parentnode = $event.target.closest('.primary_category');
        const subinputnodes = Array.prototype.slice.call(parentnode.getElementsByClassName('subcategories')[0].getElementsByClassName('subcategoriesCheckbox'));

        if (checkStatus === true) {

          this.categorieIds.push(valueId);
        } else {

          this.categorieIds = this.categorieIds.filter(id => id !== valueId);
          subinputnodes.forEach(element => {

            const subValueId = Number(element.getAttribute('data-valueId'));

            element.checked = false;
            this.subCategorieIds = this.subCategorieIds.filter(id => id !== subValueId);
          });
        }


        break;
      }

      case searchValueTypeEnum.SubCategorie: {

        if (checkStatus === true) {

          this.subCategorieIds.push(valueId);
        } else {

          this.subCategorieIds = this.subCategorieIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.BranchCategorie: {

        if (checkStatus === true) {

          this.branchCategorieIds.push(valueId);
        } else {

          this.branchCategorieIds = this.branchCategorieIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.Brand: {

        if (checkStatus === true) {

          this.brandIds.push(valueId);
        } else {

          this.brandIds = this.brandIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.ProductFeature: {

        if (checkStatus === true) {

          this.productFeatureIds.push(valueId);
        } else {

          this.productFeatureIds = this.productFeatureIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.priceType: {

        if (checkStatus === true) {

          this.priceTypes.push(valueId);
        } else {

          this.priceTypes = this.priceTypes.filter(id => id !== valueId);
        }
        break;
      }

      default: {
        break;
      }
    }

  }
  search(): void {
    this.searchsvc.closeMobileSearch();
    this.route.navigate([ this.languagesvc.nowLangNo + '/searchResult'],
                        { queryParams: {
                          keyword: this.productKey,
                          categorieIds: this.categorieIds.toString(),
                          subCategorieIds: this.subCategorieIds.toString(),
                          brandIds: this.brandIds.toString(),
                          productFeatureIds: this.productFeatureIds.toString(),
                          priceTypes: this.priceTypes.toString()
                        } });
  }
  cancelSearch(): void {
    this.categorieIds = [];
    this.subCategorieIds = [];
    this.branchCategorieIds = [];
    this.brandIds = [];
    this.productFeatureIds = [];
    this.priceTypes = [];

    const searchContainer = document.getElementsByClassName('sky-search-item-mb')[0];

    const checkboxs = Array.prototype.slice.call(document.getElementsByClassName('search_check_box'));

    checkboxs.forEach(element => {

      element.checked = false;
    });

    const filterItems = Array.prototype.slice.call(document.getElementsByClassName('filterItem'));

    filterItems.forEach(element => {

      element.setAttribute('aria-expanded', false);
      element.classList.remove('collapsed');
      element.classList.add('collapsed');
    });

    const collapses = Array.prototype.slice.call(document.getElementsByClassName('collapse'));

    collapses.forEach(element => {

      element.classList.remove('show');
    });
  }
  checkProductNoVal(str: string): boolean {

    const regExp = /^[a-zA-Z]{2}\d{4}$/;

    if (regExp.test(str)) {

      return true;
    }

    return false;
  }
}
