import { AlertService } from './../../../../shared/alert/alert.service';
import { BaseDataService } from 'src/app/service/base-data.service';
import { LanguageService } from 'src/app/service/language.service';
import { TranslateService } from '@ngx-translate/core';
import { ShoppingCartService } from 'src/app/service/shopping-cart.service';
import { DatePipe, TranslationWidth } from '@angular/common';
import { FormGroup, FormControl, Validators, AbstractControl, FormGroupDirective } from '@angular/forms';
import { PopupService } from './../../../../shared/popup/popup.service';
import { LoginResponseItineraryModel, LoginResponseModel } from './../../../../service/model/member/loginResponse.model';
import { DfpLoginRequestModel } from './../../../../service/model/member/dfpLoginRequest.model';
import { MemberService } from './../../../../service/member.service';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCarQueryModel } from 'src/app/service/model/shopping/shopping-car-query.model';
import { MemberCenterType } from 'src/app/core/enums/member/member-center-type.enum';
import { MemberLoginType } from 'src/app/core/enums/member/member-login-type.enum';
import { MemberPopupType } from 'src/app/core/enums/member/member-popup-type.enum';
import { EmpLoginRequestModel } from 'src/app/service/model/member/empLoginRequest.model';
import { PnrLoginRequestModel } from 'src/app/service/model/member/pnrLoginRequest.model';
import { TktLoginRequestModel } from 'src/app/service/model/member/tktLoginRequest.mode';
import { Console } from 'console';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-pc-member-flight',
  templateUrl: './pc-member-flight.component.html',
  styleUrls: ['./pc-member-flight.component.css']
})
export class PcMemberFlightComponent implements OnInit {
  memberLoginData: LoginResponseModel = null;
  memberFlightAry: LoginResponseItineraryModel[] = [];

  errorMsg: string[] = [];
  memberCenterTypeEnum = MemberCenterType;

  memberLoginTypeEnum = MemberLoginType;

  flightForm = new FormGroup({
    paxLocator: new FormControl('', [
      Validators.required
    ]),
    fltDepLclDate: new FormControl('')
  });

  constructor(
    private route: Router,
    public popupsvc: PopupService,
    public membersvc: MemberService,
    private shoppingCartsvc: ShoppingCartService,
    private datepipe: DatePipe,
    private translateService: TranslateService,
    public languagesvc: LanguageService,
    public baseDatasvc: BaseDataService,
    private alertsvc: AlertService
  ) {
  }

  ngOnInit(): void {
    if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      this.membersvc.closeLoginPop();
    } else {
      this.init();
    }

    this.membersvc.openFlightMethod.subscribe(() => {
      this.init();
    })
  }

  init(): void {
    this.flightForm.reset();
    this.membersvc.memberConIsOpen = false;
    this.memberLoginData = null;

    if (this.membersvc.openFlightReload) {
      this.getData();
    } else {
      if (this.baseDatasvc.memberData.loginData) {
        this.memberLoginData = this.baseDatasvc.memberData.loginData;
        this.memberFlightAry = this.baseDatasvc.memberData.loginData.itinerary;
        //console.log(this.baseDatasvc.memberData.userData.EMPID);
      }
    }
  }

  get paxLocator(): AbstractControl { return this.flightForm.get('paxLocator'); }
  get fltDepLclDate(): AbstractControl { return this.flightForm.get('fltDepLclDate'); }

  async getData(): Promise<void> {
    this.memberLoginData = null;

    this.alertsvc.openLoading(true);
    //console.log(this.baseDatasvc.memberData);
    if (this.baseDatasvc.memberData.loginType === MemberLoginType.DynastyMember) {
      const loginCondigion: DfpLoginRequestModel = {
        //2023-12-25有userData不一定有訂位
        DfpCardNumber: this.baseDatasvc.memberData.userData.dfpCardNumber
        //DfpCardNumber: this.baseDatasvc.memberData.loginData.dfpCardNumber
      };
      //console.log(this.baseDatasvc.memberData.userData.dfpCardNumber);
      //console.log(this.baseDatasvc.memberData.loginData.dfpCardNumber);
      (await this.membersvc.DfpLogin(loginCondigion)).subscribe(loginResult => {
        this.alertsvc.openLoading(false);

        if (loginResult.isOK) {
          if (!loginResult.data.itinerary || loginResult.data.itinerary.length === 0) {
            this.membersvc.memberPopupType = MemberPopupType.NOFlight;
            this.popupsvc.open(this.membersvc.memberPopupId, true);
          } else {
            this.memberLoginData = loginResult.data;
            this.memberFlightAry = loginResult.data.itinerary;
            this.formReset();
          }
        } else {
          this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          this.popupsvc.open(this.membersvc.memberPopupId, true);
        }
      }, (error) => {
        this.alertsvc.openLoading(false);
        // this.alertsvc.openAlert('Server Error', false);
      });
    } else if (this.baseDatasvc.memberData.loginType === MemberLoginType.EmployID) {
      //console.log(this.baseDatasvc.memberData);
      const empLoginCondition: EmpLoginRequestModel = {
        //2023-12-25有userData不一定有訂位
        EmployID: this.baseDatasvc.memberData.userData.EMPID
        //EmployID: this.baseDatasvc.memberData.loginData.dfpCardNumber
      };
      //console.log("getData-->this.baseDatasvc.memberData.loginType === MemberLoginType.EmployID");
      //console.log(empLoginCondition);

      (await this.membersvc.EmpLogin(empLoginCondition)).subscribe(empResult => {
        this.alertsvc.openLoading(false);
        //console.log("getData-->empResult");
        //console.log(empResult);
        if (empResult.isOK) {
          if (!empResult.data.itinerary || empResult.data.itinerary.length === 0) {
            this.membersvc.memberPopupType = MemberPopupType.NOFlight;
            this.popupsvc.open(this.membersvc.memberPopupId, true);
          } else {
            //this.baseDatasvc.memberData.userData.EMPID;
            //console.log("員工登入");
            //console.log(empResult.data);
            this.memberLoginData = empResult.data;
            this.memberFlightAry = empResult.data.itinerary;
            //console.log("getData-->memberLoginData");
            //console.log(this.memberLoginData);
            //console.log("getData-->baseDatasvc.memberData");
            //console.log(this.baseDatasvc.memberData);
            //console.log("getData-->baseDatasvc.memberData");
            //console.log(this.baseDatasvc.memberData.userData);
            //console.log(this.baseDatasvc.memberData.userData.EMPID);
            //this.baseDatasvc.memberData.userData = {
            //              GUID: "",
            //GROUP: "",
            //EMPID: this.baseDatasvc.memberData.loginData.dfpCardNumber
            //};
            //console.log(this.baseDatasvc.memberData.userData.EMPID);
            this.formReset();
          }
        } else {
          this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          this.popupsvc.open(this.membersvc.memberPopupId, true);
        }
      });
    }
    else if (this.baseDatasvc.memberData.loginType === MemberLoginType.PnrLogin) {
      const pnrLoginCondition: PnrLoginRequestModel = {
        PaxLocator: this.baseDatasvc.memberData.loginData.PaxLocator,
        PaxFirstName: this.baseDatasvc.memberData.loginData.paxFirstName,
        PaxLastName: this.baseDatasvc.memberData.loginData.paxLastName
      };

      (await this.membersvc.PnrLogin(pnrLoginCondition)).subscribe(pnrResult => {
        if (!pnrResult.data.itinerary || pnrResult.data.itinerary.length === 0) {
          this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          this.popupsvc.open(this.membersvc.memberPopupId, true);
        } else {
          this.memberLoginData = pnrResult.data;
          this.memberFlightAry = pnrResult.data.itinerary;
          this.formReset();
        }
        this.alertsvc.openLoading(false);
      }, (error) => { });
    }
    else if (this.baseDatasvc.memberData.loginType === MemberLoginType.TicketNumber) {
      const tktLoginCondition: TktLoginRequestModel = {
        TicketNumber: this.baseDatasvc.memberData.loginData.TicketNumber,
        PaxFirstName: this.baseDatasvc.memberData.loginData.paxFirstName,
        PaxLastName: this.baseDatasvc.memberData.loginData.paxLastName
      };

      (await this.membersvc.TktLogin(tktLoginCondition)).subscribe(tktResult => {
        if (!tktResult.data.itinerary || tktResult.data.itinerary.length === 0) {
          this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          this.popupsvc.open(this.membersvc.memberPopupId, true);
        } else {
          this.memberLoginData = tktResult.data;
          this.memberFlightAry = tktResult.data.itinerary;
          this.formReset();
        }
        this.alertsvc.openLoading(false);
      }, (error) => { });
    }
    else {
      this.alertsvc.openLoading(false);
      this.memberLoginData = this.baseDatasvc.memberData.loginData;
      this.memberFlightAry = this.baseDatasvc.memberData.loginData.itinerary;
    }
  }
  formReset(): void {
    if (this.baseDatasvc.flightData) {
      const value = this.baseDatasvc.flightData.paxLocator + '/' + this.baseDatasvc.flightData.fltDepLclDate.toString();

      this.flightForm.controls['paxLocator'].setValue(value);
    }
  }
  formSubmit(): void {
    this.errorMsg = [];

    if (this.flightForm.invalid) {
      if (this.flightForm.get('paxLocator').invalid) {
        this.flightForm.setErrors({ ...this.flightForm.errors, 'paxLocator': true });

        // this.errorMsg.push('請選擇行程');
      }

      this.membersvc.closeLoginPop();

      return;
    }

    const inputResult = this.flightForm.get('paxLocator').value;

    if (inputResult) {
      const inputResultAry = inputResult.split('/');
      const flithtData = this.memberFlightAry.find(x => x.paxLocator === inputResultAry[0] &&
        x.fltDepLclDate.toString() === inputResultAry[1] &&
        x.fltNumber == inputResultAry[2]);

      this.membersvc.addFlightData(flithtData);

      this.membersvc.changeFlightData();

      this.shoppingCartsvc.shoppingGet().subscribe(shoppingGetResult => {
      }, (error) => { });
    }

    window.location.href = "/" + this.languagesvc.nowLangNo

    // this.membersvc.closeLoginPop();
  }

  later(): void {
    this.membersvc.closeLoginPop();
    this.shoppingCartsvc.pcShoppingCartIsopen = false;
  }

  convertToDay(data: LoginResponseItineraryModel): string {
    let returnStr = '';
    const minToDay = 1440;
    const minToHr = 60;

    if (!data.isOpen && data.timeToClose === 0 && data.timeToOpen === 0) {
      returnStr = this.translateService.instant('FlightChoosebottomNonOpen');

      return returnStr;
    }

    if (data.isOpen) {
      returnStr = this.translateService.instant('FlightChooseLeftTime');

      if (data.timeToClose > minToDay) {
        returnStr = returnStr +
          Math.floor(data.timeToClose / minToDay).toString() +
          this.translateService.instant('FlightChooseDays') +
          this.translateService.instant('FlightChooseLeft');
      } else if (data.timeToClose > minToHr) {
        returnStr = returnStr +
          Math.ceil(data.timeToClose / minToHr).toString() +
          this.translateService.instant('FlightChooseHours') +
          this.translateService.instant('FlightChooseLeft');
      } else {
        returnStr = returnStr +
          data.timeToClose.toString() +
          this.translateService.instant('FlightChooseMinutes') +
          this.translateService.instant('FlightChooseLeft');
      }
    } else {
      if (data.timeToOpen > minToDay) {
        returnStr = returnStr +
          Math.floor(data.timeToOpen / minToDay).toString() +
          this.translateService.instant('FlightChooseDays') +
          this.translateService.instant('FlightChooseToOpen');
      } else if (data.timeToOpen > minToHr) {
        returnStr = returnStr + Math.ceil(data.timeToOpen / minToHr).toString() +
          this.translateService.instant('FlightChooseHours') +
          this.translateService.instant('FlightChooseToOpen');
      } else {
        returnStr = returnStr + data.timeToOpen.toString() +
          this.translateService.instant('FlightChooseMinutes') +
          this.translateService.instant('FlightChooseToOpen');
      }
    }

    return returnStr;
  }

  goOrderList(flightData?: LoginResponseItineraryModel): void {
    //console.log(flightData);
    this.membersvc.closeLoginPop();
    if (this.baseDatasvc.memberData.loginType === MemberLoginType.PnrLogin ||
        this.baseDatasvc.memberData.loginType === MemberLoginType.DynastyMember) {
        this.route.navigate([this.languagesvc.nowLangNo + '/member/' + MemberCenterType[MemberCenterType.Order]]);
    } else {
        if (flightData) {
          const shoppingCarNo = flightData.paxLocator +
            flightData.loadDate +
            flightData.itineraryRefNumber.toString() +
            flightData.paxRefNumber.toString();
          //console.log(shoppingCarNo);
          this.route.navigate([this.languagesvc.nowLangNo + '/member/' + MemberCenterType[MemberCenterType.Order]], {
            queryParams: {
              ShoppingCartNo: shoppingCarNo,
              FltDate: this.datepipe.transform(flightData.fltDepLclDate, 'yyyyMMdd').toString()
            }
          });
        } else {
            this.route.navigate([this.languagesvc.nowLangNo + '/member/' + MemberCenterType[MemberCenterType.Order]]);
        }
    }
  }
}
