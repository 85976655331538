export enum ShoppingCarErrorCode {

  /// <summary>
  /// 查無此商品
  /// </summary>
  NotProduct,

  /// <summary>
  /// 購物車無任何商品，故無法加入加購商品
  /// </summary>
  NotAnyProductItem,

  /// <summary>
  /// 庫存不足
  /// </summary>
  OutOfStock
}
