import { ApiConfig } from './../config/api-config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResultModel } from './model/result-model';
import { jwtResponseModel } from './model/jwt/jwtResponse.model';
import { jwtPayloadmodel } from './model/jwt/jwtPayload.model';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { CryptoService } from './crypto.service';

//import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class loginService {

  constructor(
    private http: HttpClient,
    private cryptosvc: CryptoService
    ) { }
  async JwtLogin(condition: jwtPayloadmodel):Promise<Observable<string>>  {
    //const encryptValue = this.cryptosvc.encryptData(condition);
      const _loginData = "account=" + this.cryptosvc.encryptData("cishop" + new Date().getTime().toString()) + "&password=&payload=" + this.cryptosvc.encryptData(JSON.stringify(condition));
      const apiUrl = ApiConfig.APIDomain + '/Login';
      const _headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
      let Options = {
        headers: _headers
      };

      return this.http.post<jwtResponseModel>(apiUrl, _loginData, Options).pipe(map(result => {
        return result.Data;
      }));
  }
}
