<div class="mobile-warp" [style.height]="this.layoutsvc.windowHeight + 'px'">
  <div class="sky-head-fixed">
    <div class="head_secondary_bg-m">
      <div class="head-container-fixed_m">
        <div class="float-left">
          <ng-container *ngIf='this.searchsvc.searchStep !== this.searchStepEnum.Step1'>
            <button type="button" style="background-color: transparent;margin-left: -20px;"
              (click)='backPage(this.searchsvc.searchStep)'>
              <img src="./assets/content/images/ic-left-w.svg" alt="" width="32px">
              <span class="text-transform">{{"MobileAdvancedSearch" | translate}}</span>
            </button>
          </ng-container>
          <ng-container *ngIf='this.searchsvc.searchStep === this.searchStepEnum.Step1'>
            <span class="text-transform">{{"MobileAdvancedSearch" | translate}}</span>
          </ng-container>
        </div>
        <div class="position_sky float-right">
          <button type="button" (click)='this.searchsvc.closeMobileSearch()' style="background-color: transparent;">
            <img src="./assets/content/images/ic-cancel-w.svg" alt="" width="32px">
          </button>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <div class="head_search_container-m sky-search-item-mb">
    <div class="sheet-width_m members_mobile_bg_w">
      <input class="form-control icon_calendar" type="text"
      [(ngModel)]="productKey" #tproductKey="ngModel"
      [placeholder]='"SearchKeyWordPlaceHolder" | translate'
      (keyup.enter)="this.productSearch(this.productKey)">
    </div>
    <div class="sheet-down_bg_m collapse search_coding_1" [class]="this.searchConIsOpen ? 'show': ''">
      <div class="row position-relative">
        <!-- <div class="col-12 itme">
          <h2 class="title">{{"SearchProductKeyWord" | translate}}</h2>
          <ul>
            <li class="sky-item"><a href="#">
                <p>eye care</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye cream</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye gel</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye liner gel</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye revitalizer</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye treatment</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye shadow</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye shadow duo</p>
              </a></li>
            <li class="sky-item"><a href="#">
                <p>eye shadow pen</p>
              </a></li>
          </ul>
        </div> -->
        <div class="col-12">
          <h2 class="title">{{"SearchProductTitle" | translate}}</h2>
          <ul>
            <li class="sky-graphic-itme" *ngFor="let item of this.searchResultData">
              <a class="sky-graphic" [routerLink]="['product/detail/' + item.No]" [queryParams]="{ Id: item.No }" (click)="this.closeSearch()">
                <div class="graphic_img float-left"><img [src]="item.ProductImgs[0].SmallImgFileName" *ngIf="item.ProductImgs.length > 0"></div>
                <div class="graphic_text float-left">
                  <h2 class="ellipsis-2">{{item.ProductName}}</h2>
                  <p *ngIf="item.Price || item.Price > 0">USD {{item.Price}}<span></span></p>
                  <small *ngIf="item.OldPrice && item.Price < item.OldPrice">USD {{item.OldPrice}}</small>
                </div>
                <div class="clearfix"></div>
              </a>
            </li>
          </ul>
        </div>
        <a class="ic-cancel position-absolute" href="#" type="text" data-toggle="collapse"
          data-target=".search_coding_1" aria-expanded="true"><img src="./assets/content/images/ic-cancel.svg"></a>
      </div>
    </div>
    <div class="members_mobile_title members_mobile_bg_w"
      *ngIf="this.searchsvc.searchStep === this.searchStepEnum.Step3">
      <div class="head-container_m sky-border-item">
        <h3 class="text-transform">{{"SearchFilterCategory" | translate}}</h3>
      </div>
    </div>

    <div [@slideInOut]="this.searchsvc.searchStep === this.searchStepEnum.Step1 ? 'open' : 'closed'">
      <ng-container *ngIf="this.searchsvc.searchStep === this.searchStepEnum.Step1">
        <!-- <nav class="primary_category">
          <ul>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_next">
                  <a href="javascript:;" (click)='this.changePage(this.searchStepEnum.Step2)'>
                    <small class="text-transform sky-border-item">SKINCARE</small>
                  </a>
                </div>
              </div>
            </li>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_next">
                  <a href="javascript:;" (click)='this.changePage(this.searchStepEnum.Step2)'>
                    <small class="text-transform sky-border-item">COSMETICS</small>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </nav> -->
        <div class="members_mobile_title members_mobile_bg_w">
          <div class="head-container_m">
            <h3 class="text-transform sky-border-item">{{"SearchFilterCategory" | translate}}</h3>
          </div>
        </div>
        <nav class="primary_category">
          <ul class="">
            <!-- <li class="members_mobile_bg" *ngFor="let item of this.CategorieAry">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-border-item sky-pt-15">
                  <a href="#">
                    <label class="i-checkbox">
                      <input type="checkbox">
                      <small class="checkmark text-transform">{{item.CategoryName}}</small>
                    </label>
                  </a>
                </div>
              </div>
            </li> -->
            <li class="members_mobile_bg members_down_menu" *ngFor="let categorieData of this.CategorieAry, let i = index">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 " type="text">
                    <label class="i-checkbox" data-toggle="collapse" [attr.data-target]="'#members_coding_' + i + ''"
                    aria-expanded="false" [attr.aria-controls]="'#members_coding_' + i + ''">
                      <input type="checkbox" class="search_check_box" (change)="valueChange($event, this.searchValueType.Categorie, categorieData.Id)">
                      <small class="checkmark">{{categorieData.CategoryName}}</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="members_mobile_bg_w collapse subcategories"  [id]="'members_coding_' + i">
                <ul>
                  <li *ngFor="let subCategorieData of categorieData.SubCategories">
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox" class="subcategoriesCheckbox search_check_box" (change)="valueChange($event, this.searchValueType.SubCategorie, subCategorieData.Id)">
                          <small class="checkmark">{{subCategorieData.SubCategoryName}}</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
        <div class="members_mobile_title members_mobile_bg_w">
          <div class="head-container_m">
            <h3 class="text-transform sky-border-item">{{"SearchFilterFeatures" | translate}}</h3>
          </div>
        </div>
        <nav class="primary_category">
          <ul class="">
            <li class="members_mobile_bg" *ngFor="let item of this.productFeatureAry">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-border-item sky-pt-15">
                  <a href="#">
                    <label class="i-checkbox">
                      <input type="checkbox" class="search_check_box" (change)="valueChange($event, this.searchValueType.ProductFeature, item.Id)">
                      <small class="checkmark text-transform">{{item.ProductFeatureName}}</small>
                    </label>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
        <div class="members_mobile_title members_mobile_bg_w">
          <div class="head-container_m">
            <h3 class="text-transform sky-border-item">{{"SearchFilterPrice" | translate}}</h3>
          </div>
        </div>
        <nav class="primary_category">
          <ul class="">
            <li class="members_mobile_bg" *ngFor="let item of this.productPriceTypeEnum | enumToArray">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-border-item sky-pt-15">
                  <a href="#">
                    <label class="i-checkbox">
                      <input type="checkbox" class="search_check_box" name="{{item}}" (change)="valueChange($event, this.searchValueType.priceType, this.productPriceTypeEnum[item])">
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceA">$1-50</small>
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceB">$51-100</small>
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceC">$101-200</small>
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceD">$201-300</small>
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceE">$301-400</small>
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceF">$401-500</small>
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceG">$501-1000</small>
                      <small class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceH">$1000+</small>
                    </label>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </ng-container>
    </div>

    <div [@slideInOut]="this.searchsvc.searchStep === this.searchStepEnum.Step2 ? 'open' : 'closed'">
      <ng-container *ngIf="this.searchsvc.searchStep === this.searchStepEnum.Step2">
        <nav class="primary_category">
          <ul>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-pt-15 sky-border-item position-relative">
                  <label class="i-checkbox" (click)='this.changePage(this.searchStepEnum.Step3)'>
                    <input type="checkbox">
                    <small class="checkmark text-transform">SKINCARE</small>
                    <div class="position_sky container_position_rt sky-pl-70">
                      <img src="./assets/content/images/ic-next.svg" alt="" width="20px">
                    </div>
                  </label>
                </div>
              </div>
            </li>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-pt-15 sky-border-item position-relative">
                  <label class="i-checkbox" (click)='this.changePage(this.searchStepEnum.Step3)'>
                    <input type="checkbox">
                    <small class="checkmark text-transform">COSMETICS</small>
                    <div class="position_sky container_position_rt sky-pl-70">
                      <img src="./assets/content/images/ic-next.svg" alt="" width="20px">
                    </div>
                  </label>
                </div>
              </div>
            </li>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-pt-15 sky-border-item position-relative">
                  <label class="i-checkbox">
                    <input type="checkbox">
                    <small class="checkmark text-transform">FRAGRANCES</small>
                    <div class="position_sky container_position_rt sky-pl-70">
                      <img src="./assets/content/images/ic-next.svg" alt="" width="20px">
                    </div>
                  </label>
                </div>
              </div>
            </li>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-pt-15 sky-border-item position-relative">
                  <label class="i-checkbox">
                    <input type="checkbox">
                    <small class="checkmark text-transform">SKY BOUTIQUE</small>
                    <div class="position_sky container_position_rt sky-pl-70">
                      <img src="./assets/content/images/ic-next.svg" alt="" width="20px">
                    </div>
                  </label>
                </div>
              </div>
            </li>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-pt-15 sky-border-item position-relative">
                  <label class="i-checkbox">
                    <input type="checkbox">
                    <small class="checkmark text-transform">SELECTED LIQUOR</small>
                    <div class="position_sky container_position_rt sky-pl-70">
                      <img src="./assets/content/images/ic-next.svg" alt="" width="20px">
                    </div>
                  </label>
                </div>
              </div>
            </li>
            <li class="members_mobile_bg">
              <div class="head-container_m">
                <div class="members_mobile_blank sky-pt-15 sky-border-item position-relative">
                  <label class="i-checkbox">
                    <input type="checkbox">
                    <small class="checkmark text-transform">GIFTS</small>
                    <div class="position_sky container_position_rt sky-pl-70">
                      <img src="./assets/content/images/ic-next.svg" alt="" width="20px">
                    </div>
                  </label>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </ng-container>
    </div>

    <!-- <div [@slideInOut]="this.searchsvc.searchStep === this.searchStepEnum.Step3 ? 'open' : 'closed'">
      <ng-container *ngIf="this.searchsvc.searchStep === this.searchStepEnum.Step3">
        <nav class="primary_category accordion accordionExample">
          <ul class="members_mobile_bg">
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" type="text" data-toggle="collapse" data-target=".members_coding_1"
                      aria-expanded="true" aria-controls="members_coding_1">
                      <input type="checkbox">
                      <small class="checkmark">Cleanser</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_1" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_2"
                      aria-expanded="true" aria-controls="members_coding_2">
                      <input type="checkbox">
                      <small class="checkmark">Toner & Mist</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_2" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox">
                      <input type="checkbox" data-toggle="collapse" data-target=".members_coding_3" aria-expanded="true"
                        aria-controls="members_coding_3">
                      <small class="checkmark">Cleanser</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_3" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_4"
                      aria-expanded="true" aria-controls="members_coding_4">
                      <input type="checkbox">
                      <small class="checkmark">Serum & Concentrate</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_4" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="row sky-border-item sky-head-relative no-gutters">
                        <div class="col-3 members_mobile_blank sky-pt-15 ">
                          <label class="i-checkbox">
                            <input type="checkbox">
                            <small class="checkmark">Cleanser</small>
                          </label>
                        </div>
                        <div class="col-9 position_sky members_mobile_down container_position_rt sky-pl-70" type="text"
                          data-toggle="collapse" data-target=".members_coding_1" aria-expanded="true"
                          aria-controls="members_coding_1"></div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox">
                      <input type="checkbox" data-toggle="collapse" data-target=".members_coding_5" aria-expanded="true"
                        aria-controls="members_coding_5">
                      <small class="checkmark">Lotion & Cream</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_5" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_6"
                      aria-expanded="true" aria-controls="members_coding_6">
                      <input type="checkbox">
                      <small class="checkmark">Face Mask</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_6" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_7"
                      aria-expanded="true" aria-controls="members_coding_7">
                      <input type="checkbox">
                      <small class="checkmark">Sunscreen</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_7" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_8"
                      aria-expanded="true" aria-controls="members_coding_8">
                      <input type="checkbox">
                      <small class="checkmark">Eye Treatment</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_8" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_9"
                      aria-expanded="true" aria-controls="members_coding_9">
                      <input type="checkbox">
                      <small class="checkmark">Body Care</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_9" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_10"
                      aria-expanded="true" aria-controls="members_coding_10">
                      <input type="checkbox">
                      <small class="checkmark">Hand Treatment</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_10" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li class="members_down_menu">
              <div class="head-container_m">
                <div class="row sky-border-item sky-head-relative no-gutters">
                  <div class="members_mobile_blank sky-pt-15 ">
                    <label class="i-checkbox" data-toggle="collapse" data-target=".members_coding_11"
                      aria-expanded="true" aria-controls="members_coding_11">
                      <input type="checkbox">
                      <small class="checkmark">Lip Treatment</small>
                      <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                    </label>
                  </div>
                </div>
              </div>

              <div class="members_mobile_bg_w collapse members_coding_11" aria-labelledby="headingOne"
                data-parent=".accordionExample">
                <ul>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Cleansing Oil</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Facial Foam</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark text-transform">SELECTED LIQUOR</small>
                        </label>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="head-container_m">
                      <div class="members_mobile_blank sky-pt-15 sky-border-item">
                        <label class="i-checkbox">
                          <input type="checkbox">
                          <small class="checkmark">Makeup Remover</small>
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </ng-container>
    </div> -->

    <div class="sky-head-fixed-button sky-head-flex-button ">
      <button class="sky-item-button_w_m" type="button" (click)="this.cancelSearch()">{{"SearchButtonReset" | translate}}</button>
      <button class="sky-item-button_m" type="button" (click)="this.search()">{{"SearchButtonSearch" | translate}}</button>
    </div>
  </div>

</div>
