<div class="head_search_container-m sky-search-item-mb">

  <ng-container>
    <div class="members_mobile_title members_mobile_bg_w">
      <div class="head-container_m">
        <h3 class="text-transform sky-border-item">{{"MobileProductFilterCATEGORIES" | translate}}</h3>
      </div>
    </div>
    <nav class="primary_category">
      <ul class="">
        <li class="members_mobile_bg members_down_menu" *ngFor="let categorieData of this.CategorieAry, let i = index">
          <div class="head-container_m">
            <div class="row sky-border-item sky-head-relative no-gutters">
              <div class="members_mobile_blank sky-pt-15 " type="text">
                <label class="i-checkbox" data-toggle="collapse" [attr.data-target]="'#members_coding_' + i + ''"
                aria-expanded="false" [attr.aria-controls]="'#members_coding_' + i + ''">
                  <input type="checkbox"
                  class="search_check_box"
                  [checked]="this.categorieIds.includes(categorieData.Id)? 'check' : ''"
                  (change)="valueChange($event, this.searchValueType.Categorie, categorieData.Id)">
                  <small class="checkmark">{{categorieData.CategoryName}}</small>
                  <div class="col-2 position_sky members_mobile_down container_position_rt sky-pl-70"></div>
                </label>
              </div>
            </div>
          </div>
          <div class="members_mobile_bg_w collapse subcategories" [class]="this.categorieIds.includes(categorieData.Id)? 'show' : ''"  [id]="'members_coding_' + i">
            <ul>
              <li *ngFor="let subCategorieData of categorieData.SubCategories">
                <div class="head-container_m">
                  <div class="members_mobile_blank sky-pt-15 sky-border-item">
                    <label class="i-checkbox">
                      <input type="checkbox" class="subcategoriesCheckbox search_check_box"
                      [checked]="this.subCategorieIds.includes(subCategorieData.Id)? 'check' : ''"
                      (change)="valueChange($event, this.searchValueType.SubCategorie, subCategorieData.Id)">
                      <small class="checkmark">{{subCategorieData.SubCategoryName}}</small>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
    <div class="members_mobile_title members_mobile_bg_w">
      <div class="head-container_m">
        <h3 class="text-transform sky-border-item">{{"MobileProductFilterFEATURES" | translate}}</h3>
      </div>
    </div>
    <nav class="primary_category">
      <ul class="">
        <li class="members_mobile_bg" *ngFor="let item of this.productFeatureAry">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box" (change)="valueChange($event, this.searchValueType.ProductFeature, item.Id)">
                  <small class="checkmark text-transform">{{item.ProductFeatureName}}</small>
                </label>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <div class="members_mobile_title members_mobile_bg_w">
      <div class="head-container_m">
        <h3 class="text-transform sky-border-item">{{"MobileProductFilterPRICE(USD)" | translate}}</h3>
      </div>
    </div>
    <nav class="primary_category">
      <ul class="">
        <li class="members_mobile_bg">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box">
                  <small class="checkmark text-transform">$1- $50</small>
                </label>
              </a>
            </div>
          </div>
        </li>
        <li class="members_mobile_bg">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box">
                  <small class="checkmark text-transform">$51-$100</small>
                </label>
              </a>
            </div>
          </div>
        </li>
        <li class="members_mobile_bg">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box">
                  <small class="checkmark text-transform">$101-$200</small>
                </label>
              </a>
            </div>
          </div>
        </li>
        <li class="members_mobile_bg">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box">
                  <small class="checkmark text-transform">$201- $300</small>
                </label>
              </a>
            </div>
          </div>
        </li>
        <li class="members_mobile_bg">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box">
                  <small class="checkmark text-transform">$301-$500</small>
                </label>
              </a>
            </div>
          </div>
        </li>
        <li class="members_mobile_bg">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box">
                  <small class="checkmark text-transform">$501- $1000</small>
                </label>
              </a>
            </div>
          </div>
        </li>
        <li class="members_mobile_bg">
          <div class="head-container_m">
            <div class="members_mobile_blank sky-border-item sky-pt-15">
              <a href="#">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box">
                  <small class="checkmark text-transform">$1000+</small>
                </label>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </ng-container>
  <div class="sky-head-fixed-button sky-head-flex-button ">
    <button class="sky-item-button_w_m" type="button" (click)="this.cancelSearch()">{{"SearchButtonReset" | translate}}</button>
    <button class="sky-item-button_m" type="button" (click)="this.search()">{{"SearchButtonSearch" | translate}}</button>
  </div>
</div>
