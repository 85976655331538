import { ProductType } from './../../../core/enums/product/product-type.enum';
import { BaseDataService } from 'src/app/service/base-data.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { LanguageService } from './../../../service/language.service';
import { ProductModel } from './../../../service/model/product/product.model';
import { Component, OnInit, Input } from '@angular/core';
import { MemberService } from 'src/app/service/member.service';
import { ShoppingCartService } from 'src/app/service/shopping-cart.service';
import { AlertService } from '../../alert/alert.service';
import { ShoppingCarItemModel } from 'src/app/service/model/shopping/shopping-car-item.model';
import { WishListService } from 'src/app/service/wish-list.service';
import { ShoppingCartStep } from 'src/app/core/enums/shopping-cart/shopping-cart-step.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-image-text',
  templateUrl: './product-image-text.component.html',
  styleUrls: ['./product-image-text.component.css']
})
export class ProductImageTextComponent implements OnInit {

  @Input() productAllCount: number;
  @Input() productList: ProductModel[];
  @Input() productEnList: ProductModel[] = [];
  @Input() productTwPriceAry = [];
  @Input() productTwOldPriceAry = [];
  @Input() productType: ProductType = ProductType.General;

  constructor(
    private router: Router,
    private membersvc: MemberService,
    private shoppingcartsvc: ShoppingCartService,
    private alertsvc: AlertService,
    public languagesvc: LanguageService,
    public wishListSvc: WishListService,
    private datepipe: DatePipe,
    public baseDatasvc: BaseDataService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
  }

  async addShoppingCart(productNo: string, productType: ProductType): Promise<void> {

    (await this.shoppingcartsvc.shoppingAdd(productNo, productType)).subscribe(result => {

      if (result.Success) {

        this.alertsvc.openAlert(this.translateService.instant('AddShoppingCart'), true);
      }
    });
  }
  async buyNow(productNo: string, productType: ProductType): Promise<void> {

    (await this.shoppingcartsvc.shoppingAdd(productNo, productType)).subscribe(result => {

      if (result.Success) {

        this.alertsvc.openAlert(this.translateService.instant('AddShoppingCart'), true);

        this.router.navigate([this.languagesvc.nowLangNo + '/shoppingCart', ShoppingCartStep[ShoppingCartStep.step1]]);
      }
    });
  }
  getProductEnName(No: string): string {

    let enName = '';

    const product = this.productEnList.find(x => x.No === No);

    if (product) {

      enName = product.ProductName;
    }

    return enName;
  }
}
