<!-- 購物車下拉選單 Start -->
<div class="member_shopping_pc position-absolute shadow" *ngIf="this.shoppingcartsvc.pcShoppingCartIsopen">

  <ng-container *ngIf="!this.membersvc.isLoginSubject.value">
    <div class="member_container_pc">
      <div class="member_bg">
        <div class="member_error_msg mb-0 pl-3" style="justify-content: left;">
          <div>{{"ShoppingCartLoginNotice" | translate}}</div>
        </div>
      </div>
      <div class="member_container_padding">
        <button class="sky-item-button main" type="button"
          (click)="this.openLogin(this.membersvc.memberPopupId, memberLoginTypeEnum.DynastyMember)">{{"MemberLoginToolDynastyMemberLogin" | translate}}</button>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'tw'">
          {{"MemberLoginToolNotAMemberYet" | translate}}
          <span>
            <a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh"
              target="_blank">
              {{"MemberLoginToolSignUp" | translate}}
            </a>
          </span>
        </p>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'en'">
          {{"MemberLoginToolNotAMemberYet" | translate}}
          <span>
            <a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=en-us&country=tw&locale=en"
              target="_blank">
              {{"MemberLoginToolSignUp" | translate}}
            </a>
          </span>
        </p>

        <div class="member-borer">
          <div class="text-transform">or</div>
        </div>
        <button class="sky-item-button" type="button"
          (click)="this.openLogin(this.membersvc.memberPopupId, memberLoginTypeEnum.TicketNumber)">{{"MemberLoginToolUseReservation/E-TicketNUMBER" | translate}}</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="this.membersvc.isLoginSubject.value">
    <div class="member_container_pc">
      <ng-container *ngIf="this.isDone">
        <!-- 提貨航班 head -->
        <ng-container *ngIf="this.baseDatasvc.flightData && this.baseDatasvc.memberData.loginData">
          <div class="member_container_padding member_bg">
            <div>
              <p>{{"ShoppingCartToolFlightdelivery" | translate}}</p>
              <p class="title">
                <span
                  class="font-weight-bold">{{this.baseDatasvc.flightData.fltCarrier}}{{this.baseDatasvc.flightData.fltNumber}}
                  ({{this.baseDatasvc.flightData.fltDepStationCode}} -
                  {{this.baseDatasvc.flightData.fltArvStationCode}})</span>
                <small>{{this.baseDatasvc.flightData.fltDepLclDate | date:'yyyy/MM/dd HH:mm'}}</small>
              </p>
            </div>
            <div><a href="javascript:;" class="font-weight-bold"
                (click)="this.openFlightSelect()">{{"ShoppingCartToolChangeFlightdelivery" | translate}}</a></div>
          </div>
        </ng-container>
        <!-- 請選擇提貨航班 -->
        <ng-container *ngIf="this.baseDatasvc.memberData.loginData &&
        (this.baseDatasvc.flightData === null ||
        !this.baseDatasvc.memberData.loginData ||
        this.baseDatasvc.memberData.loginData.itinerary.length === 0)">
          <div class="member_bg">
            <div class="member_error_msg mb-0 pl-3" style="justify-content: left;">
              <div>{{"ShoppingCartToolChooseAndStart" | translate}}</div>
            </div>
          </div>
          <div class="member_container_padding">
            <div class="member_shopping_prompt">
              <p></p>
              <div>
                <button class="sky-item-button main"
                type="button"
                (click)="this.openFlightSelect()">{{"ShoppingCartToolChooseFlight" | translate}}</button>
                </div>
            </div>
          </div>
        </ng-container>
        <!-- 搜尋不到有效航班 -->
        <ng-container *ngIf="!this.baseDatasvc.memberData.loginData ||
        (this.baseDatasvc.memberData.loginData.itinerary.length === 0 && this.baseDatasvc.memberData.loginData)">
          <div class="member_bg">
            <div class="member_error_msg mb-0 pl-3" style="justify-content: left;">
              <ng-container *ngIf="this.baseDatasvc.flightErrorMsg">
                <div>{{this.baseDatasvc.flightErrorMsg}}</div>
              </ng-container>
              <ng-container *ngIf="!this.baseDatasvc.flightErrorMsg">
                <div>{{"ShoppingCartToolNoFilghtWording" | translate}}</div>
              </ng-container>
            </div>
          </div>
          <div class="member_container_padding">
            <div class="member_shopping_prompt">
              <p>{{"ShoppingCartToolArrangeATrip" | translate}}</p>
              <div>
                <a href="https://www.china-airlines.com" target="_blank"
                  class="sky-item-button main">{{"ShoppingCartToolGoBuyTicket" | translate}}</a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="this.baseDatasvc.flightData !== null">
          <!-- 您目前尚未選購任何商品 -->
          <ng-container *ngIf="!this.shoppingCarData ||
          ((!this.shoppingCarData.Details || this.shoppingCarData.Details.length == 0) &&
          (!this.shoppingCarData.Members || this.shoppingCarData.Members.length == 0))">
            <!-- 沒資料 -->
            <div class="member_container_padding">
              <div class="member_shopping_prompt">
                <p>{{"ShoppingCartToolHaveNoProducts" | translate}}</p>
                <!-- <div><button class="sky-item-button main" type="button">CHOOSE FLIGHT</button></div> -->
              </div>
            </div>
          </ng-container>
          <!-- 購物車內有商品 -->
          <ng-container *ngIf="this.shoppingCarData &&
          !this.shoppingCarData.IsComplete &&
          ((this.shoppingCarData.Details && this.shoppingCarData.Details.length > 0) ||
          (this.shoppingCarData.Members && this.shoppingCarData.Members.length > 0))">
            <div class="member_container_padding button mt-3">
              <div class="member_shopping_prompt">
                <p class="shopping_cart_tool_wording">{{"ShoppingCartToolWording" | translate}}</p>
                <div>
                  <button type="button" (click)="this.goToCart()"
                    class="sky-item-button main d-inline-block">{{"ShoppingCartToolGoToCart" | translate}}</button>
                  <!-- <a [routerLink]="['/shoppingCart']" class="sky-item-button main d-inline-block" type="reset">GO TO CART</a> -->
                </div>
              </div>
            </div>
            <div class="pt-0">
              <ul class="sky-tool-shoppingCart-tool-warrp">
                <!-- <app-shopping-row *ngFor="let item of this.shoppingCarData.Details" [productData]="item"></app-shopping-row> -->
                <ng-container *ngIf="this.shoppingCarData.Details">
                  <li class="sky-graphic-itme" *ngFor="let item of this.shoppingCarData.Details, let i = index">
                    <div class="sky-graphic">
                      <div class="graphic_img">
                        <img [src]="item.ProductImgs[0].SmallImgFileName">
                      </div>
                      <div class="graphic_text">
                        <h3>{{item.ProductNo}}</h3>
                        <h2 class="ellipsis-2 mt-1">{{item.ProductName}}</h2>
                        <div class="quantity_box">
                          <button type="button" class="btn reduce" [class]="item.Count === 1 || this.baseDatasvc.flightData.orderNumber ? 'disabled' : ''"
                          [disabled]="item.Count === 1 || this.baseDatasvc.flightData.orderNumber">
                            <span class="material-icons" (click)="this.remove(item.ProductNo, item.Count)">remove</span>
                          </button>
                          <div class="quantity">{{item.Count}}</div>
                          <button type="button" class="btn add"
                          [class]="this.baseDatasvc.flightData.orderNumber ? 'disabled' : ''"
                          [disabled]="this.baseDatasvc.flightData.orderNumber"
                            (click)="this.add(item.ProductNo, item.ProductType, item.Count, item.MaxCount)">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="position-relative">
                      <div class="ic-movetowishlish" [class]="this.wishListsvc.isInWishList(item.ProductNo, item.IsKeep) ? 'active' : ''">
                        <a href="javascript:;" (click)="this.wishListsvc.addToWishList(item.ProductNo, item.ProductType, this.wishListsvc.isInWishList(item.ProductNo, item.IsKeep))"
                          title=""></a>
                      </div>
                      <div class="ic-trash">
                        <a href="javascript:;" title=""
                          (click)="this.removeFromCart(item.ProductNo, item.ProductType, item.Count)"></a>
                      </div>
                      <div class="text mt-2">
                        <p>
                          <!-- <ng-container *ngIf="item.IsSpecialProduct">USD {{item.FinalPrice}}</ng-container>
                          <ng-container *ngIf="!item.IsSpecialProduct">USD {{item.ProductPrice * item.Count}}</ng-container> -->
                          <ng-container>USD {{item.ProductPrice * item.Count}}</ng-container>
                          <ng-container *ngIf="this.detailTwPriceAry.length > 0 && this.detailTwPriceAry[i]">
                            <span class="sub_font">/ TWD {{this.detailTwPriceAry[i]}}</span>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </li>
                </ng-container>
                <ng-container *ngIf="this.shoppingCarData.Members">
                  <li class="sky-graphic-itme" *ngFor="let item of this.shoppingCarData.Members, let i = index">
                    <div class="sky-graphic">
                      <div class="graphic_img">
                        <img [src]="item.ProductImgs[0].SmallImgFileName">
                      </div>
                      <div class="graphic_text">
                        <h3>{{item.ProductNo}}</h3>
                        <h2 class="ellipsis-2 mt-1">{{item.ProductName}}</h2>
                        <div class="quantity_box">
                          <button type="button" class="btn reduce" [class]="item.Count === 1 ? 'disabled' : ''"  [disabled]="item.Count === 1">
                            <span class="material-icons"
                              (click)="this.remove(item.ProductNo, item.ProductType, item.Count)">
                              remove
                            </span>
                          </button>
                          <div class="quantity">{{item.Count}}</div>
                          <button type="button" class="btn add"
                            (click)="this.add(item.ProductNo, item.ProductType, item.Count, item.MaxCount)">
                            <span class="material-icons">add</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="position-relative">
                      <div class="ic-movetowishlish" [class]="this.wishListsvc.isInWishList(item.ProductNo, item.IsKeep) ? 'active' : ''">
                        <a href="javascript:;" (click)="this.wishListsvc.addToWishList(item.ProductNo, item.ProductType, this.wishListsvc.isInWishList(item.ProductNo, item.IsKeep))"
                          title=""></a>
                      </div>
                      <div class="ic-trash">
                        <a href="javascript:;" title=""
                          (click)="this.removeFromCart(item.ProductNo, item.ProductType, item.Count)"></a>
                      </div>
                      <div class="text mt-2">
                        <p>
                          USD {{item.FinalPrice}}
                          <ng-container *ngIf="this.memberTwPriceAry.length > 0 && this.memberTwPriceAry[i]">
                            <span class="sub_font">/ TWD {{this.memberTwPriceAry[i]}}</span>
                          </ng-container>
                        </p>
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ul>
            </div>
            <div class="member_container_padding">
              <div class="member_shopping_prompt flex-end">
                <div>
                  <button class="sky-item-button d-inline-block" type="button"
                    (click)="this.mutipleAddToWishList()">{{"ShoppingCartToolMoveToWishList" | translate}}</button>
                  <button class="sky-item-button d-inline-block ml-2" type="button"
                    (click)="this.removeAll()">{{"ShoppingCartToolRemoveAll" | translate}}</button>
                </div>
              </div>
            </div>
          </ng-container>
          <!-- 已有訂單 -->
          <ng-container *ngIf="this.shoppingCarData.IsComplete">
            <div class="member_container_padding">
              <div class="member_shopping_prompt">
                <p>{{"ShoppingCartHasOrder" | translate}}</p>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!this.isDone">
        <div class='lmask'>
          <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

</div>
<!-- 購物車下拉選單 End -->
