import { BaseDataService } from 'src/app/service/base-data.service';
import { MemberLoginType } from './../../../core/enums/member/member-login-type.enum';
import { CategoryType } from './../../../core/enums/server/category-type.enum';
import { AlertService } from './../../../shared/alert/alert.service';
import { MemberCenterType } from './../../../core/enums/member/member-center-type.enum';
import { MenuType } from './../../../core/enums/menu/menu-type.enum';
import { LanguageService } from './../../../service/language.service';
import { LayoutService } from './../../layout.service';
import { Component, OnInit, Input, ElementRef, HostListener } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../../../layout/menu/menu.service';

import { MenuStep } from '../menu-step.enum';
import { MenuDataModel, SubCategory } from '../../../service/model/menu/menu.model';
import { MemberService } from '../../../service/member.service';
import { LanguageModel } from 'src/app/service/model/language/language.model';
import { CheckEmpIDRequestModel } from 'src/app/service/model/member/check-empID-request.model';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css'],
  animations: [
    trigger('slideInOut', [
      state('open',  style({
        transform: 'translateX(0%)'
      })),
      state('closed', style({
        transform: 'translateX(100%)'
      })),
      transition('closed => open', [
        animate('0.3s linear')
      ]),
      transition('open => closed', [
        animate('0.3s linear')
      ])
    ])
  ]
})

export class MobileMenuComponent implements OnInit {

  private element: any;
  windowHeight: number;
  menuStepEnum = MenuStep;

  isRoot = true;

  @Input() menuDataList: MenuDataModel[];

  menuData: MenuDataModel;
  menuCategoryData: MenuDataModel;
  menuTypeEnum = MenuType;
  menuType: MenuType;
  memberCenterTypeEnum = MemberCenterType;
  languageAry: LanguageModel[] = [];
  nowLanguageName = '';
  categoryTypeEnum = CategoryType;
  loginTypeEnum = MemberLoginType;

  constructor(
    private route: Router,
    public layoutsvc: LayoutService,
    public menusvc: MenuService,
    public membersvc: MemberService,
    public languagesvc: LanguageService,
    private el: ElementRef,
    private alertsvc: AlertService,
    public baseDatasvc: BaseDataService,
    private translateService: TranslateService,
  ) {

    this.element = el.nativeElement;
  }

  ngOnInit(): void {

    document.getElementById('mobile_menu').appendChild(this.element);

    this.windowHeight = window.outerHeight;

    this.getLanguageData();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {

    this.windowHeight = window.innerHeight;
  }

  changePage(step: MenuStep, id: number, menuType: MenuType): void {
    this.menusvc.menuType = menuType;
    this.menusvc.menuStep = step;

    switch (step) {
      case (MenuStep.step2): {
        if (id !== null) {
          this.menuData = this.menuDataList.find(x => x.Id === id);
        }
        break;
      }
      case (MenuStep.step3): {
        if (id !== null) {
          this.menuCategoryData = this.menuData.MobileCategories.find(x => x.Id === id);
        }
        break;
      }
    }

    if (menuType === MenuType.Member) {
      this.membersvc.memberLoginType = null;
    }
  }
  backPage(nowStep: MenuStep): void {

    switch (nowStep) {
      case (MenuStep.step2): {
        this.menusvc.menuStep = MenuStep.step1;
        break;
      }

      case (MenuStep.step3): {
        this.menusvc.menuStep = MenuStep.step2;
        break;
      }

      default: {
        this.menusvc.menuStep = MenuStep.step1;
      }
    }
  }
  closeMenuEvent($event): void {
    this.menusvc.closeMobileMenu();
  }
  logOut(): void {
    this.membersvc.LogOut(false);
    this.menusvc.closeMobileMenu();
    // this.route.navigate([ this.languagesvc.nowLangNo + '/']);
    window.location.href = "/" + this.languagesvc.nowLangNo;

    // this.alertsvc.openAlert(this.translateService.instant('LogOutSuccessfully'), true);
  }
  getLanguageData(): void {
    this.languagesvc.getData().subscribe(result => {
      if (result.Success) {
        this.languageAry = result.Data;

        this.nowLanguageName = this.languageAry.find(x => x.LanguageNo === this.languagesvc.nowLangNo).LanguageName;
      }
    },
    (error) => {

    });
  }
  empLogin(): void {

    this.alertsvc.openLoading(true);

    const condition: CheckEmpIDRequestModel = {};

    this.membersvc.CheckEmpID(condition).subscribe(result => {
      if (result.isOK) 
      {
        var validateUrl = new RegExp(/^((((https?):\/\/))(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+)([).!';/?:,][[:blank:|:blank:]])?$/);
        var url = result.data.URL;
        if (url.match(validateUrl)) 
        {
            window.location.href = url;
        } else {
 
        }
      }
    });
  }

  toWishList(): void {

    if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {

      if (this.baseDatasvc.memberData.loginType === MemberLoginType.DynastyMember) {

        this.route.navigate([ this.languagesvc.nowLangNo + '/member/' + MemberCenterType[MemberCenterType.WishList]]);

        return;
      }
    }

    this.menusvc.closeMobileMenu();
  }
}
