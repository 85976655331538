import { ApiConfig } from './../config/api-config';
import { ResultModel } from './model/result-model';
import { Observable, Subject, Subscription } from 'rxjs';
import { LanguageService } from 'src/app/service/language.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { OrderQueryModel } from './model/order/order-query.model';
import { OrderMainModel } from './model/order/order-main.model';
import { CancelOrderModel } from './model/order/cancel-order.model';
import { CryptoService } from './crypto.service';
import { FrontendLoginType } from '../core/enums/jwt/FrontendLogin-type.enum';

@Injectable({
  providedIn: 'root'
})
export class OrderListService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  private orderPageReloadMethodCall = new Subject<any>();
  orderPageReloadMethod = this.orderPageReloadMethodCall.asObservable();

  constructor(
    private cryptosvc: CryptoService,
    private http: HttpClient,
    private languagesvc: LanguageService
  ) { }

  /// 取得訂單列表
  getOrderList(condition: OrderQueryModel): Observable<ResultModel<OrderMainModel[]>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.OrderList;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.NONE + ',' +
                                                                  '' + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };
    condition.LngNo = this.languagesvc.nowLangNo;
    return this.http.post<ResultModel<OrderMainModel[]>>(apiUrl, condition, Options);
  }

  /// 取得訂單
  getOrder(condition: OrderQueryModel): Observable<ResultModel<OrderMainModel>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Order + '?orderNumber=' + condition.OrderNumber;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.NONE + ',' +
                                                                  condition.OrderNumber + ',' +
                                                                  new Date().getTime())});


    let Options = {
      headers: _headers
    };
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<ResultModel<OrderMainModel>>(apiUrl, null, Options);
  }

  /// 取消訂單
  cancelOrder(condition: CancelOrderModel): Observable<ResultModel<OrderMainModel>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.CancelOrder;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.NONE + ',' +
                                                                  condition.FltDate + condition.OrderNumber + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<ResultModel<OrderMainModel>>(apiUrl, condition, Options);
  }

  orderPageReload(): void {
    this.orderPageReloadMethodCall.next();
  }
}
