import { element } from 'protractor';
import { Injectable } from '@angular/core';
import { ProductType } from 'src/app/core/enums/product/product-type.enum';

export interface GeneralPopModel {
  title?: string;
  content?: string;
  confirmButtonText?: string;
  fightButtonText?: string;
  loginButtonText?: string;
  removeAllProductConfirmText?: string;
  removeProductConfirmText?: string;
  productData?: GeneralPopProductDataModel;
}

export interface GeneralPopProductDataModel {
  popRemoveProductNo: string;
  popRemoveProductType: ProductType;
  popRemoveCount: number;
  isShoppingCart: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  private popups: any[] = [];

  wishListCheckId = 'wishListCheck';
  generalPop = 'generalPop';

  generalPopData?: GeneralPopModel = null;


  constructor() { }

  add(popup: any): void {
    this.popups.push(popup);
  }

  remove(id: string): void {
    this.popups = this.popups.filter(x => x.id !== id);
  }

  open(id: string, isCenter?: boolean, isTop?: boolean): void {
    const popup = this.popups.find(x => x.id === id);

    popup.open();

    if (isCenter !== null) {
      popup.isCenter = isCenter;
    }else{
      popup.isCenter = false;
    }

    if (isTop) {
      popup.isTop = true;
    }else{
      popup.isTop = false;
    }
  }

  close(id: string): void {
    const popup = this.popups.find(x => x.id === id);
    popup.close();
  }

  closeAll(): void {

    this.popups.forEach(element => {

      const popup = this.popups.find(x => x.id === element.id);
      popup.close();
    });
  }

  openGeralPop(data: GeneralPopModel): void {

    this.generalPopData = data;

    this.open(this.generalPop, true);
  }
}
