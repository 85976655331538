<div class="row mb-4 ">
  <div class="col-12" *ngFor="let productData of this.productList, let i = index">
      <div class="productBox_line">
          <div class="productBox_line_con">
              <a  class="product_link"
                  routerLink="/{{this.languagesvc.nowLangNo}}/product/detail/{{productData.No}}" [queryParams]="{ productType: this.productType }"></a>
              <div class="product_img_box" [style.background-image]="'url('+ productData.ProductImgs[0].SmallImgFileName +')'" *ngIf="productData.ProductImgs.length > 0">
                <button type="button" class="sky-love" [class]="this.wishListSvc.isInWishList(productData.No, productData.IsKeep) ? 'active' : ''"
                (click)="this.wishListSvc.addToWishList(productData.No, productData.ProductType, this.wishListSvc.isInWishList(productData.No, productData.IsKeep))"></button>
              </div>
              <div class="product_des_box">
                  <div class="product_label_box">
                      <div class="sky-label" *ngFor="let item of productData.ProductFeatures.slice(0, 2)">
                        <div class="new" [style.background-color]="item.BackgroundColor" [style.color]="item.Color" *ngIf="item.IconImg === null">{{item.ProductFeatureName}}</div>
                      </div>
                  </div>
                  <p class="itemNo">{{"ProductDetailItemNo" | translate}} : {{productData.No}}</p>
                  <p class="brandName">{{productData.BrandName}}</p>
                  <p class="productName">
                    {{productData.ProductName}}
                    <ng-container *ngIf="this.productEnList.length > 0">
                      <br>
                      {{this.getProductEnName(productData.No)}}
                    </ng-container>
                  </p>

                  <div class="product_price_box">
                      <p class="price" *ngIf="productData.Price || productData.Price > 0">
                        <ng-container>USD {{productData.Price}}</ng-container>
                        <ng-container *ngIf="this.productTwPriceAry.length > 0 && this.productTwPriceAry[i]">
                          <span class="sub_font">/ TWD {{this.productTwPriceAry[i]}}</span>
                        </ng-container>
                      </p>
                      <small *ngIf="productData.OldPrice && productData.Price < productData.OldPrice">
                        <ng-container>USD {{productData.OldPrice}}</ng-container>
                        <ng-container *ngIf="this.productTwOldPriceAry.length > 0 && this.productTwOldPriceAry[i]">
                          <span class="sub_font">/ TWD {{this.productTwOldPriceAry[i]}}</span>
                        </ng-container>
                      </small>
                  </div>
              </div>

              <div class="product_btn_Box">
                  <button class="sky-item-button" type="button" (click)="this.addShoppingCart(productData.No, productData.ProductType)">{{"ProductButtonAddToCart" | translate}}</button>
                  <a class="sky-item-button main" href="javascript:;" (click)="this.buyNow(productData.No, productData.ProductType)">{{"ProductButtonBuyNow" | translate}}</a>
              </div>
          </div>
      </div>
  </div>
</div>
