import { IpServiceService } from './ip-service.service';
//import { GemaltoBulkDecryptRequestModel } from './model/member/gemaltoBulkDecryptRequest.model';
//import { GemaltoBulkEncryptRequestModel } from './model/member/gemaltoBulkEncryptRequest.model';
import { ApiConfig } from './../config/api-config';
import { EShoppingApiResultModel } from './model/member/eShoppingApiResult.model';
import { Observable } from 'rxjs';
import { GetMileageRequestModel } from './model/member/getMileageRequest.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FrontendLoginType } from '../core/enums/jwt/FrontendLogin-type.enum';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  encryptSecretKey = 'F^Q7i!uSJaX2ltaYv&KW';
  memberClientIp = '1.0.0.1';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient,
    private ipsvc: IpServiceService
  ) { }

  /// AES 加密
  encryptData(data): string {

    try {

      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
    } catch (e) {

    }
  }

  /// AES 解密
  decryptData(data): any {

    if (data){

      try {

        const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);

        if (bytes.toString()) {

          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }

        return data;
      } catch (e) {

      }
    }
  }

//  /// 5.1  個資加密
//  async GemaltoBulkEncrypt(condition: GemaltoBulkEncryptRequestModel): Promise<Observable<EShoppingApiResultModel<string[]>>> {
//
//    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
//    // condition.ClientIP = userIp ? userIp.ip : this.memberClientIp;
//    var _headers = new HttpHeaders({'Content-Type': 'application/json',
//                                    'Authorization' : 'Bearer ' + this.encryptData(FrontendLoginType.NONE + ',' +
//                                                                  '' + ',' +
//                                                                  new Date().getTime())});
//    let Options = {
//      headers: _headers
//    };
//    return this.http.post<EShoppingApiResultModel<string[]>>(
//      (ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.GemaltoBulkEncrypt), condition, Options);
//  }
//
//  /// 5.2  個資解密
//  async GemaltoBulkDecrypt(condition: GemaltoBulkDecryptRequestModel): Promise<Observable<EShoppingApiResultModel<string[]>>> {
//
//    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
//    // condition.clientIP = userIp ? userIp.ip : this.memberClientIp;
//    var _headers = new HttpHeaders({'Content-Type': 'application/json',
//                                    'Authorization' : 'Bearer ' + this.encryptData(FrontendLoginType.NONE + ',' +
//                                                                  '' + ',' +
//                                                                  new Date().getTime())});
//    let Options = {
//      headers: _headers
//    };
//    return this.http.post<EShoppingApiResultModel<string[]>>(
//      (ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.GemaltoBulkDecrypt), condition, Options);
//  }
}
