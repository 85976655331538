import { LanguageModel } from './../../../service/model/language/language.model';
import { LanguageService } from './../../../service/language.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-language',
  templateUrl: './mobile-language.component.html',
  styleUrls: ['./mobile-language.component.css']
})
export class MobileLanguageComponent implements OnInit {

  languageAry: LanguageModel[] = [];
  constructor(
    public languagesvc: LanguageService
  ) { }

  ngOnInit(): void {

    this.getData();
  }

  getData(): void {

    this.languagesvc.getData().subscribe(result => {

      if (result.Success) {

        this.languageAry = result.Data;
      }

    }, (error) => {});
  }

}
