import { LayoutService } from './../layout.service';
import { Component, OnInit } from '@angular/core';

import { CookieService } from '../../service/cookie.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.css']
})
export class CookieConsentComponent implements OnInit {

  constructor(public cookiesvc: CookieService, public layoutsvc: LayoutService) { }
  ngOnInit(): void {
    this.cookiesvc.checkConsent();
  }
}
