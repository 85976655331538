export enum CategoryType {
  Type1, // TYPE 1 (商品特色/商品分類/相關品牌/Banner)

  Type2, // TYPE 2 (依據label/相關品牌/Banner)

  Type3, // TYPE 3 (依據標籤/相關品牌/Banner)

  Type4, // TYPE 4 (依據標籤/Banner)

  Type5, // TYPE 5 (依據品牌館/Banner)

  Type6 // TYPE 6 (主選單連結)
}
