export enum ProductType {
  /// <summary>
  /// 一般商品
  /// </summary>
  General,

  /// <summary>
  /// 贈品
  /// </summary>
  Gift,

  /// <summary>
  /// 加購商品
  /// </summary>
  AddPurchase,

  /// <summary>
  /// 會員商品
  /// </summary>
  Member
}
