import { SearchService } from 'src/app/layout/search/search.service';
import { BaseDataService } from 'src/app/service/base-data.service';
import { MemberService } from './../../../service/member.service';
import { ShoppingCartService } from 'src/app/service/shopping-cart.service';
import { LanguageService } from './../../../service/language.service';
import { ProductFeatureModel } from './../../../service/model/product-feature/product-feature.model';
import { ProductFeatureConditionModel } from './../../../service/model/product-feature/product-feature-condition.model';
import { ProductFeatureService } from './../../../service/product-feature.service';
import { LayoutService } from './../../layout.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { CategoryType } from '../../../core/enums/server/category-type.enum';
import { MenuDataModel } from '../../../service/model/menu/menu.model';
import { BrandService } from './../../../service/brand.service';
import { MenuService } from '../../../layout/menu/menu.service';
import { MemberLoginType } from 'src/app/core/enums/member/member-login-type.enum';
declare const jQuery: any;

@Component({
  selector: 'app-pc-menu',
  templateUrl: './pc-menu.component.html',
  styleUrls: ['./pc-menu.component.css']
})
export class PcMenuComponent implements OnInit {

  wasInside = false;

  @Input() menuDataList: MenuDataModel[];

  categoryType = CategoryType;
  menuMaxHeight = 490;
  menuMinHeight = 490;
  productFeatureAry: ProductFeatureModel[] = [];
  loginTypeEnum = MemberLoginType;

  rightBannerOptions: any;

  constructor(
    private menusvc: MenuService,
    public layoutsvc: LayoutService,
    public brandsvc: BrandService,
    public languagesvc: LanguageService,
    private shoppingCartsvc: ShoppingCartService,
    public membersvc: MemberService,
    public baseDatasvc: BaseDataService,
    private searchsvc: SearchService
  ) { }

  ngOnInit(): void {

    this.rightBannerOptions = { // 設定 Owl Carousel Options
      items: 1,
      dots: false,
      nav: true,
      loop: true,
      autoHeight: true,
      navText: [
        '<a href="javascript:;" uk-slideshow-item="previous"><img src="./assets/content/images/ic-banner-2-previous.svg" width="48px"></a>',
        '<a href="javascript:;" uk-slideshow-item="next"><img src="./assets/content/images/ic-banner-2-next.svg" width="48px"></a>'
      ],
    };
  }

  @HostListener('click')
  clickInside(): void {

    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout(): void {

    if (!this.wasInside) {

      this.menusvc.closePcMenu();
    }

    this.wasInside = false;
  }

  menu(isOpen: boolean): void {
    if (isOpen) {
      const coverBgY = 55;
      const menuMarginTop = jQuery('.sky-navbar').offset().top;
      const coverBgHeight = this.layoutsvc.windowHeight;
      const bodyHeight = jQuery('body').height();
      jQuery('.sky-cover-bg').height(bodyHeight);
      this.layoutsvc.headerToolIsOpen = true;
    } else {
      jQuery('.sky-navbar-con-box').removeClass('show');
      jQuery('.sky-cover-bg').height(0);
      jQuery('.sky-navbar-bg').height(0);
      this.layoutsvc.headerToolIsOpen = false;
    }
  }

  openMenu(el): void {
    jQuery('.sky-navbar-con-box').removeClass('show');
    const maxHeight = 436;
    const minHeight = 436;
    const subMenu = el;
    const subMenuCon = el.find('.sub-container');
    subMenu.addClass('show');
    // subMenuCon.hasClass('short-con') ? minHeight : maxHeight
    jQuery('.sky-navbar-bg').height(maxHeight);
    jQuery('.sky-cover-bg').addClass('show');
    this.menu(true);
  }

  closeMenu(): void {
    this.menu(false);
    const subMenu = jQuery('.sky-navbar-con-box');
    subMenu.removeClass('show');
    jQuery('.sky-cover-bg').removeClass('show');
  }

  clickMenu($event): void {
    const subMenu = jQuery($event.target).parent('li').find('.sky-navbar-con-box');

    if (subMenu.hasClass('show')) {
      subMenu.removeClass('show');
      jQuery('.sky-cover-bg').removeClass('show');
    } else {
      this.openMenu(subMenu);
    }
  }
}
