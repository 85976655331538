import { LanguageService } from './language.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ResultModel } from './../service/model/result-model';
import { BrandBanner, BrandDataModel } from './../service/model/brand/brand.model';
import { ApiConfig } from '../config/api-config';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  public brandsData: BrandDataModel[] = null;

  constructor(
    private http: HttpClient,
    private languagesvc: LanguageService
  ) {

    this.getBrandsData();

  }

  getData(): Observable<ResultModel<BrandDataModel[]>> {

    return this.http.get<ResultModel<BrandDataModel[]>>(
      ApiConfig.APIDomain + ApiConfig.API.Brand.Brands + '?lngNo=' + this.languagesvc.nowLangNo);
  }

  getBrandsData(): void {

    this.http.get<ResultModel<BrandDataModel[]>>(
      ApiConfig.APIDomain + ApiConfig.API.Brand.Brands + '?lngNo=' + this.languagesvc.nowLangNo
    )
    .subscribe(result => {

      if (result.Success) {
        this.brandsData = result.Data;
      }


    },
    (error) => {

    });

  }
}

