export const ApiConfig = {
  //Domain: 'https://hdqcat02.china-airlines.com:9443/',
  //APIDomain: 'https://hdqcat02.china-airlines.com:9443/skyapi',
  Domain: 'https://cishop.cilink.com.tw/',
  APIDomain: 'https://cishop.cilink.com.tw/skyapi',
  //Domain: 'http://localhost:4200/',
  //APIDomain: 'http://localhost:50585',

  API: {
    Menus: '/Menus',

    Languages: '/Languages',

    SideSlogan: '/SideSlogan',

    TopNews: '/TopNews',

    Announcements: '/Announcements',

    PurchaseNotes: '/PurchaseNotes',

    ContactUs: '/ContactUs',

    CommonProblems: '/CommonProblems',

    ProductFeature: '/ProductFeature',

    BrandPavilion: '/BrandPavilion',

    Index: {
      TopBanner: '/Index/TopBanner',

      MiddleBanner: '/Index/MiddleBanner',

      RightBttomBanner: '/Index/RightBttomBanner',

      ElectronicCatalog: '/Index/ElectronicCatalog',

      Panel: '/Index/Panel'
    },

    Brand: {
      Brands: '/Brands'
    },

    Products: '/Products',

    ProductRecommends: '/RecommendProducts',

    eShoppingApi: {
      DfpLogin: '/eShoppingApi/DfpLogin',

      EmpLogin: '/eShoppingApi/EmpLogin',

      PnrLogin: '/eShoppingApi/PnrLogin',

      TktLogin: '/eShoppingApi/TktLogin',

      CheckDfpID: '/eShoppingApi/CheckDfpID',

      CheckDfpIDAndName: '/eShoppingApi/CheckDfpIDAndName',

      ExChange: '/eShoppingApi/ExChange',

      GetMileage: '/eShoppingApi/GetMileage',

      GetAwardList: '/eShoppingApi/GetAwardList',

      CheckEmpID: '/eShoppingApi/CheckEmpID',

      GetLoginByGuid: '/eShoppingApi/GetLoginByGuid',

      CaptchaUrl:  '/eShoppingApi/captcha'
    },

    Shopping: {
      Add: '/Shopping/Add',

      Get: '/Shopping/Get',

      Remove: '/Shopping/Remove',

      Complete: '/Shopping/Complete',

      CheckOrder: '/Shopping/CheckOrder',

      RemoveAll: '/Shopping/RemoveAll'
    },

    FlightSafetyPolicy: '/FlightSafetyPolicy',

    IntellectualPropertyDescription: '/IntellectualPropertyDescription',

    QACateogries: '/QACateogries',

    Notifications: '/Notifications',

    ActivityProducts: '/ActivityProducts',

    MemberProducts: '/MemberProducts',

    OrderList: '/OrderList',

    Order: '/Order',

    CancelOrder: '/Order/CancelOrder',

    ProductKeepAdd: '/ProductKeepAdd',

    ProductKeepRemove: '/ProductKeepRemove',
    
    ExceptionLog: '/ExceptionLog'
  }
};
