import { MemberLoginType } from './../../core/enums/member/member-login-type.enum';
import { BaseDataService } from 'src/app/service/base-data.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { MenuType } from './../../core/enums/menu/menu-type.enum';
import { LayoutService } from './../layout.service';
import { TopNewsService } from './../../service/top-news.service';
import { TopNewsConditionModel } from './../../service/model/top-news/top-news-condition.model';
import { LanguageModel } from './../../service/model/language/language.model';
import { LanguageService } from './../../service/language.service';
import { Component, OnInit, HostListener, Output, EventEmitter, Pipe } from '@angular/core';

import { ShoppingCartService } from '../../service/shopping-cart.service';
import { MemberService } from '../../service/member.service';
import { MenuService } from '../menu/menu.service';
import { SearchService } from '../search/search.service';
import { HeaderService } from './../../service/header.service';

import { TopNewsModel } from '../../service/model/top-news/top-news.model';
import { ShoppingCarQueryModel } from 'src/app/service/model/shopping/shopping-car-query.model';
import { data } from 'jquery';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MenuStep } from '../menu/menu-step.enum';
import { TranslateService } from '@ngx-translate/core';
import { CheckEmpIDRequestModel } from 'src/app/service/model/member/check-empID-request.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  topNewsArr: TopNewsModel[];
  loginIsOpen = false;
  languageAry: LanguageModel[] = [];
  nowLanguageName = '';
  @Output() openMenu = new EventEmitter<any>();

  memberLoginTypeEnum = MemberLoginType;

  headerHeight = 0;

  constructor(
    private route: Router,
    public headersvc: HeaderService,
    public membersvc: MemberService,
    public menusvc: MenuService,
    public searchsvc: SearchService,
    public shoppingcartsvc: ShoppingCartService,
    public languagesvc: LanguageService,
    private topnewssvc: TopNewsService,
    private datepipe: DatePipe,
    public layoutsvc: LayoutService,
    private transiatesvc: TranslateService,
    private alertsvc: AlertService,
    public baseDatasvc: BaseDataService,
  ) { }

  ngOnInit(): void {
    this.layoutsvc.headerHeight = $('.main-header').height();

    this.getTopNews();
    this.getLanguageData();
  }

  @HostListener('window:resize', ['$event'])

  onResize(event: any): void {
  }

  openMenuEvent($event): void {
    this.openMenu.emit($event);
    this.menusvc.openMobileMenu();
    this.membersvc.memberConIsOpen = false;
    this.shoppingcartsvc.pcShoppingCartIsopen = false;
    this.searchsvc.pcSearchIsopen = false;
  }

  openAccount($event): void {
    if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      /// 已登入
    }

    this.membersvc.openPcMemberTool(!this.membersvc.memberConIsOpen);
    this.shoppingcartsvc.pcShoppingCartIsopen = false;
    this.searchsvc.pcSearchIsopen = false;
    this.menusvc.closePcMenu();
  }

  openSearch($event): void {
    this.searchsvc.openPcSearch(!this.searchsvc.pcSearchIsopen);
    this.membersvc.memberConIsOpen = false;
    this.shoppingcartsvc.pcShoppingCartIsopen = false;
    this.menusvc.closePcMenu();
  }

  openShoppingCart($event): void {
    if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
    }

    this.shoppingcartsvc.openPcShoppingCart(this.shoppingcartsvc.pcShoppingCartIsopen ? false : true);
    this.membersvc.memberConIsOpen = false;
    this.searchsvc.pcSearchIsopen = false;
    this.menusvc.closePcMenu();
  }

  openMobleShoppingCart(): void {
    if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      this.menusvc.menuType = MenuType.Member;
      this.membersvc.memberLoginType = null;
      this.menusvc.openMobileMenu(MenuStep.step2);
    } else {
      if (this.baseDatasvc.memberData.loginData && this.baseDatasvc.memberData.loginData.itinerary.length > 0) {
        this.route.navigate([this.languagesvc.nowLangNo + '/shoppingCart']);
      } else {
        this.shoppingcartsvc.openMobleShoppingCart();
      }
    }
  }

  getTopNews(): void {
    const condition: TopNewsConditionModel = {};

    this.topnewssvc.getData(condition).subscribe(result => {
      this.layoutsvc.headerHeight = $('.main-header').height();

      if (result.Success) {
        this.topNewsArr = result.Data.Datas;
      }
    },
      (error) => {
      });
  }

  getLanguageData(): void {
    this.languagesvc.getData().subscribe(result => {
      if (result.Success) {
        this.languageAry = result.Data;

        this.nowLanguageName = this.languageAry.find(x => x.LanguageNo === this.languagesvc.nowLangNo).LanguageName;
      }
    },
      (error) => {
      });
  }

  empLogin(): void {
    this.alertsvc.openLoading(true);

    const condition: CheckEmpIDRequestModel = {};

    this.membersvc.CheckEmpID(condition).subscribe(result => {
      if (result.isOK) 
      {
        var validateUrl = new RegExp(/^((((https?):\/\/))(%[0-9A-Fa-f]{2}|[-()_.!~*';/?:@&=+$,A-Za-z0-9])+)([).!';/?:,][[:blank:|:blank:]])?$/);
        var url = result.data.URL;
        if (url.match(validateUrl)) 
        {
            window.location.href = url;
        } else {
 
        }
      }
    });
  }

  logout(): void {
    this.membersvc.LogOut(false);
    window.location.href = "/" + this.languagesvc.nowLangNo;
    // this.alertsvc.openAlert(this.transiatesvc.instant('LogOutSuccessfully'), true);
  }
}
