import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { ShoppingCartService } from '../../../service/shopping-cart.service';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'app-mobile-shopping-cart',
  templateUrl: './mobile-shopping-cart.component.html',
  styleUrls: ['./mobile-shopping-cart.component.css']
})
export class MobileShoppingCartComponent implements OnInit {

  private element: any;
  constructor(
    public layoutsvc: LayoutService,
    private el: ElementRef,
    public shoppingcartsvc: ShoppingCartService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    document.getElementById('mobile_shooping_cart').appendChild(this.element);
  }

}
