import { ApiConfig } from './../config/api-config';
import { ProductFeatureResultModel } from './model/product-feature/product-feature.model';
import { ResultModel } from './model/result-model';
import { Observable } from 'rxjs';
import { ProductFeatureConditionModel } from './model/product-feature/product-feature-condition.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LanguageService } from './language.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductFeatureService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  constructor(
    private http: HttpClient,
    private languagesvc: LanguageService
  ) { }

  getData(condition: ProductFeatureConditionModel): Observable<ResultModel<ProductFeatureResultModel>> {

    condition.lngNo = this.languagesvc.nowLangNo;

    return this.http.post<ResultModel<ProductFeatureResultModel>>(
      (ApiConfig.APIDomain + ApiConfig.API.ProductFeature), condition, this.httpOptions);
  }
}
