<form [formGroup]="flightForm" (ngSubmit)="this.formSubmit()" #form="ngForm" *ngIf="this.membersvc.isLoginSubject.value &&
(this.baseDatasvc.memberData.loginData && this.baseDatasvc.memberData.loginData.itinerary.length > 0)">
    <div class="head-container_m">
        <div class="sky-pt-30">
            <div class="member_modal_title_m">
                <p *ngIf="!this.baseDatasvc.flightData">{{"FlightChooseTitleWording" | translate}}</p>
                <p *ngIf="this.baseDatasvc.flightData">{{"FlightChangeTitleWording" | translate}}</p>
            </div>

            <div class="member_modal_subtitle" *ngIf="!this.baseDatasvc.flightData">
                {{"FlightChooseContentWording" | translate}}</div>
            <div class="member_modal_subtitle" *ngIf="this.baseDatasvc.flightData">
                {{"FlightChangeContentWording" | translate}}</div>

            <div class="member_error_msg" *ngIf="this.errorMsg.length > 0">
                <div>
                    <span *ngFor="let item of this.errorMsg">{{item}}</span>
                </div>
            </div>

            <ul class="member_tablePanel_m" *ngIf="this.baseDatasvc.memberData.loginData !== null">
                <li *ngFor="let item of this.baseDatasvc.memberData.loginData.itinerary">
                    <label class="row no-gutters py-3">
            <div class="col-10">
              <div>
                <div class="d-inline">{{item.paxLocator}}</div>
                <div class="d-inline ml-2">{{item.fltNumber}}</div>
                <div class="d-inline ml-2">{{item.fltDepLclDate | date:'yyyy/MM/dd HH:mm'}}</div>
              </div>
              <div class="row no-gutters mt-2">
                <div class="col-5 d-inline text-left">
                  <div>{{item.fltDepStationName}}</div>
                  <div class="mt-1">{{item.fltDepStationCode}}</div>
                </div>
                <div class="col-2 d-inline text-center">一</div>
                <div class="col-5 d-inline text-left">
                  <div>{{item.fltArvStationName}}</div>
                  <div class="mt-1">{{item.fltArvStationCode}}</div>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-inline">
                  {{this.convertToDay(item)}}
                  <!-- <span class="table_small_color ml-2">
                    {{item.fltDepLclDate | date:'yyyy/MM/dd HH:mm'}} deadline
                  </span> -->
                </div>
              </div>
            </div>
            <div class="col-2 position-relative">
              <div class="sky-position_center">
                <div class="i-radio" *ngIf="item.isOpen && item.orderNumber === null">
                  <input type="radio" value="{{item.paxLocator}}/{{item.fltDepLclDate.toString()}}"
                    formControlName="paxLocator" name="paxLocator" required>
                  <span class="checkmark text-transform"></span>
                </div>
                <div *ngIf="item.orderNumber !== null">
                  <!-- {{"FlightChooseReserved" | translate}} -->
                  <button class="sky-item-button" style="padding: 5px;" type="button" (click)="this.goOrderList(item)">{{"FlightCheckOrder" | translate}}</button>
                </div>
              </div>
            </div>
          </label>
                </li>
            </ul>
        </div>
    </div>

    <div class="member_modal_prompt">
        <p style="color:red;">{{"FlightChoosebottomWording" | translate}}</p>
        <button class="sky-item-button" type="button" (click)="this.goOrderList()">{{"FlightChooseMyOrder" | translate}}</button>
    </div>

    <div class="member_modal_foot">
      <h2>{{"LoginDynatyMemberBottomWordingTitle" | translate}}</h2>
      <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.DynastyMember">
        <ul>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_1" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_2" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_3" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_4" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_5" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_6" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_7" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_8" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_9" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_10" | translate | safeHtml'></li>
            <li [innerHTML]='"LoginDynatyMemberBottomWording_11" | translate | safeHtml'></li>
        </ul>
      </ng-container>
      <ng-container *ngIf="this.baseDatasvc.memberData.loginType !== this.memberLoginTypeEnum.DynastyMember">
        <ul>
          <li [innerHTML]='"LogiTicketLoginBottomWording_1" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_2" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_3" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_4" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_5" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_6" | translate | safeHtml'></li>
          <!--<li [innerHTML]='"LogiTicketLoginBottomWording_7" | translate | safeHtml'></li>-->
          <!--<li [innerHTML]='"LogiTicketLoginBottomWording_8" | translate | safeHtml'></li>-->
          <li [innerHTML]='"LogiTicketLoginBottomWording_9" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_10" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_11" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_12" | translate | safeHtml'></li>
          <li [innerHTML]='"LogiTicketLoginBottomWording_13" | translate | safeHtml'></li>
        </ul>
      </ng-container>
    </div>

    <div class="sky-head-fixed-button sky-head-flex-button ">
        <button class="sky-item-button_w_m" type="button" (click)="this.later()" *ngIf="!this.baseDatasvc.flightData">{{"FlightChooseLater" | translate}}</button>
        <button class="sky-item-button_w_m" type="button" (click)="this.later()" *ngIf="this.baseDatasvc.flightData">{{"FlightChooseCancel" | translate}}</button>
        <button class="sky-item-button_m" type="submit" >{{"FlightChooseConfirm" | translate}}</button>
    </div>
</form>

<div class="head-container_m" *ngIf="this.membersvc.isLoginSubject.value &&
(!this.baseDatasvc.memberData.loginData || this.baseDatasvc.memberData.loginData.itinerary.length === 0)">
    <div class="sky-pt-30">

        <!-- 警告提示 Start-->
        <div class="member_modal_caveat mb-5">
            <p>{{"FlghtNoneContentWording" | translate}}</p>
        </div>
        <!-- 警告提示 End-->

        <a href="https://www.china-airlines.com" class="sign sky-item-button main mb-4">{{"FlghtNoneGoBuyTicket" | translate}}</a>
        <button class="sign sky-item-button" type="button" (click)="this.browseFirst()">{{"FlghtNoneBrowseFirst" | translate}}</button>
    </div>
</div>
