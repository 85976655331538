import { MemberOrderDownloadComponent } from './member/member-order/member-order-download/member-order-download.component';
import { LanguageService } from './../service/language.service';
import { ShoppingCartModule } from './shopping-cart/shopping-cart.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

export function languageMatch(url: UrlSegment[]) {

  return url.length > 0  ? ({consumed: url}) : null;
}

export function productMatch(url: UrlSegment[]) {

  return url.length > 0 && url[1].path === 'product' ? ({consumed: url}) : null;
}

export function newsMatch(url: UrlSegment[]) {

  return url.length > 0 && url[1].path === 'news' ? ({consumed: url}) : null;
}

const routes: Routes = [
  {
    path: ':lingua',
    component: LayoutComponent,
    children: [
      { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
      { path: 'home', redirectTo: '', pathMatch: 'full' },
      { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
      { path: 'shoppingCart', loadChildren: () => import('./shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule) },
      { path: 'searchResult', loadChildren: () => import('./search-result/search-result.module').then(m => m.SearchResultModule) },
      { path: 'purchaseNotes', loadChildren: () => import('./purchase-notes/purchase-notes.module').then(m => m.PurchaseNotesModule) },
      { path: 'member', loadChildren: () => import('./member/member.module').then(m => m.MemberModule) },
      { path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
      { path: 'features', loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule) },
      { path: 'contactUs', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
      { path: 'commonProblems', loadChildren: () => import('./common-problems/common-problems.module').then(m => m.CommonProblemsModule) },
      { path: 'all brands', loadChildren: () => import('./brand/brand.module').then(m => m.BrandModule) },
      { path: 'brands pavilion', loadChildren: () => import('./brand-pavilion/brand-pavilion.module').then(m => m.BrandPavilionModule) },
      { path: 'activity', loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule) }
    ]
    // loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
  },
  // Not found
  { path: '', redirectTo: '/tw', pathMatch: 'full' },
  { path: '**', redirectTo: '/tw' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    enableTracing: false,
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})

export class AppRoutingModule {
  constructor() {}
}


