<!-- header Start -->
<header class="main-header" (window:resize)="onResize($event)"
        [class]='this.layoutsvc.isScrollDown &&
!this.layoutsvc.headerToolIsOpen &&
!this.searchsvc.pcSearchIsopen &&
!this.membersvc.memberConIsOpen &&
!this.shoppingcartsvc.pcShoppingCartIsopen ? "scrollDown" : ""'>
  <div class="wrap_marquee marquee_coding_1" *ngIf="this.topNewsArr != null && this.topNewsArr.length > 0">
    <div class="main-container ">
      <div class="topNews-warp">
        <div class="">
          <p class="wrap_marquee-title position-relative">{{"HeaderNewsNotice" | translate}}：</p>
        </div>
        <!-- position-relative id="roll" -->
        <div class="out carousel slide carousel-vertical" data-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item" [class]="this.i == 0 ? 'active' : ''" *ngFor="let topNewsData of this.topNewsArr, let i = index" data-interval="2000">
              <a [href]="topNewsData.UrlLink" [target]='topNewsData.IsBlank ? "_blank" : ""' class="ellipsis-1">{{topNewsData.Info}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- pc -->
  <div class="sky-head-relative d-none d-lg-block d-xl-block">
    <div class="head_bg">
      <div class="main-container">
        <div class="head_map">
          <ul class="position_sky container_position_rt">
            <li class="empLogin">
              <ng-container *ngIf="this.membersvc.isLoginSubject.value && this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.EmployID">
                <a href="javascript:;" (click)="this.logout()">
                  <p>{{"HeaderRightEmpHello" | translate}}&nbsp;&nbsp;{{this.baseDatasvc.memberData.loginData ? this.baseDatasvc.memberData.loginData.dfpCardNumber : ""}}&nbsp;&nbsp;{{"HeaderRightEmpLogOut" | translate}}</p>
                </a>
              </ng-container>
              <ng-container *ngIf="!this.membersvc.isLoginSubject.value || (this.membersvc.isLoginSubject.value  && this.baseDatasvc.memberData.loginType !== this.memberLoginTypeEnum.EmployID)">
                <a href="javascript:;" (click)="this.empLogin()">
                  <p>{{"HeaderRightEmpLogin" | translate}}</p>
                </a>
              </ng-container>
            </li>
            <li class="news">
              <a [routerLink]="['news']" title="">
                <p>{{"HeaderRightNews" | translate}}</p>
              </a>
            </li>
            <li class="shopping">
              <a [routerLink]="['purchaseNotes']" title="">
                <p>{{"HeaderRightShoppingNotice" | translate}}</p>
              </a>
            </li>
            <li class="contact">
              <a [routerLink]="['contactUs']" title="">
                <p>{{"HeaderRightContactUs" | translate}}</p>
              </a>
            </li>
            <li class="language">
              <img src="../../../assets/content/images/ic-earth.svg" class="earth-icon">
              <a href="#" class=" dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true"
                 aria-expanded="true">
                <p>{{this.nowLanguageName}}<span uk-icon="icon: triangle-down"></span></p>
              </a>
              <div class="dropdown-menu sky-drop-down shadow">
                <a class="dropdown-item" *ngFor="let item of this.languageAry"
                   (click)="this.languagesvc.changeLanguage(item.LanguageNo)">{{item.LanguageName}}</a>
              </div>
            </li>
          </ul>
        </div>
        <div class="head_container">
          <div class="position_sky container_position_lt">
            <ul class="logo">
              <li>
                <a [routerLink]="['/' + this.languagesvc.nowLangNo + '']" title="">
                  <img src="../../../assets/content/images/img-logo-sb-header@3x.png" alt="" width="200px">
                </a>
              </li>
              <li>
                <!-- <a href="https://www.china-airlines.com" target="_blank" title=""></a> -->
                <img src="../../../assets/content/images/img_logo_CAL_header.svg" usemap="#cal_header" alt="" width="208px">
                <map name="cal_header">
                  <area shape="rect" coords="0,0,185,32" href="https://www.china-airlines.com" target="_blank" alt="">
                  <area shape="rect" coords="185,0,208,32" href="https://www.skyteam.com" target="_blank" alt="">
                </map>
              </li>
            </ul>
          </div>
          <div class="position_sky container_position_rt">
            <ul class="features">
              <li class="search">
                <a href="javascript:;" (click)="this.openSearch($event)" id="search-btn" title="">
                  <p>{{"HeaderRightButtonSearch" | translate}}</p>
                </a>
              </li>
              <li class="account">
                <a href="javascript:;" (click)="this.openAccount($event)" title="">
                  <p *ngIf="this.membersvc.isLoginSubject.value">{{"HeaderRightButtonAccount" | translate}}</p>
                  <p *ngIf="!this.membersvc.isLoginSubject.value">{{"HeaderRightButtonLogin" | translate}}</p>
                </a>
              </li>
              <li class="ic-cart position-relative">
                <a href="javascript:;" (click)="this.openShoppingCart($event)">
                  <img src="../../../assets/content/images/ic-cart.svg" alt="" width="32px">
                </a>
                <div class="cartCount" *ngIf="this.baseDatasvc.shoppingCarCount > 0">{{this.baseDatasvc.shoppingCarCount}}</div>
              </li>
            </ul>
          </div>

          <app-pc-member></app-pc-member>

          <app-shopping-cart></app-shopping-cart>
        </div>
      </div>
    </div>

    <app-menu></app-menu>

    <app-search></app-search>
  </div>

  <!-- moblie -->
  <div class="sky-head-fixed d-block d-lg-none d-xl-none">
    <div class="head_bg-m">
      <div class="main-container">
        <div class="head-logo float-left">
          <button type="button" (click)="openMenuEvent($event)" style="margin-right: 5px;">
            <img src="../../../assets/content/images/ic-menu.svg" alt="" width="32px">
          </button>
          <a [routerLink]="['/' + this.languagesvc.nowLangNo + '']">
            <img src="../../../assets/content/images/img-logo-sb-header@3x.png" alt="" width="105px">
          </a>
        </div>
        <div class="head-icon float-right">
          <button type="button" (click)="this.searchsvc.openMobileSearch()">
            <img src="../../../assets/content/images/ic-search.svg" alt="" width="32px">
          </button>
          <div class="ic-cart">
            <button type="button" (click)="this.openMobleShoppingCart()">
              <img src="../../../assets/content/images/ic-cart.svg" alt="" width="32px">
            </button>
            <div class="cartCount">{{this.baseDatasvc.shoppingCarCount}}</div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="head_bg_s-m"></div>
  </div>
</header>
<!-- header End -->
