<div class="sky-search-sheet shadow-sm" [class]="this.searchsvc.pcSearchIsopen ? 'active' : ''">
  <div class="main-container">
    <div class="sheet-width">
      <input class="form-control icon_calendar" type="text"
      [(ngModel)]="productKey" #tproductKey="ngModel"
      [placeholder]='"SearchKeyWordPlaceHolder" | translate'
      (keyup.enter)="this.productSearch(this.productKey)">
      <div class="sheet-down_bg collapse search_coding_1" [class]="this.searchConIsOpen ? 'show': ''">
        <div class="row position-relative" *ngIf="this.searchResultData.length > 0">
          <div class="col-4">
            <h2 class="title">{{"SearchProductKeyWord" | translate}}</h2>
            <!-- <ul>
              <li class="sky-item"><a href="#">
                  <p>eye care</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye cream</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye gel</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye liner gel</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye revitalizer</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye treatment</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye shadow</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye shadow duo</p>
                </a></li>
              <li class="sky-item"><a href="#">
                  <p>eye shadow pen</p>
                </a></li>
            </ul> -->
          </div>
          <div class="col-8">
            <h2 class="title">{{"SearchProductTitle" | translate}}</h2>
            <ul class="sky-graphic-itme-box">
              <li class="sky-graphic-itme" *ngFor="let item of this.searchResultData">
                <a class="sky-graphic" [routerLink]="['product/detail/' + item.No]" [queryParams]="{ Id: item.No }" (click)="this.closeSearch()">
                  <div class="graphic_img float-left"><img [src]="item.ProductImgs[0].SmallImgFileName" *ngIf="item.ProductImgs.length > 0"></div>
                  <div class="graphic_text float-left">
                    <h2 class="ellipsis-2">{{item.ProductName}}</h2>
                    <p *ngIf="item.Price || item.Price > 0">USD {{item.Price}}<span></span></p>
                    <small *ngIf="item.OldPrice && item.Price < item.OldPrice">USD {{item.OldPrice}}</small>
                  </div>
                  <div class="clearfix"></div>
                </a>
              </li>
            </ul>
          </div>
          <a class="ic-cancel position-absolute" href="#" type="text" data-toggle="collapse"
            data-target=".search_coding_1" aria-expanded="true"><img
              src="../../../assets/content/images/ic-cancel.svg"></a>
        </div>
      </div>
    </div>

    <div class="row sky-search-item">

      <div class="col-3 sky-search-scroll">
        <div class="sky-menu-headings">
          <h2>{{"SearchFilterCategory" | translate}}</h2>
        </div>
        <div class="sky-search_next">
          <ul class="">
            <li class="sky-item" *ngFor="let categorieData of this.CategorieAry, let i = index">
              <div class="filterItem" data-toggle="collapse" [attr.data-target]="'#coding_1_' + i"  aria-expanded="false"
                [attr.aria-controls]="'coding_1_' +  i">
                <label class="i-checkbox">
                  <input type="checkbox" class="search_check_box" (change)="valueChange($event, this.searchValueType.Categorie, categorieData.Id)">
                  <span class="checkmark text-transform">{{categorieData.CategoryName}}</span>
                  <div class="sky-search_next_icon float-right"></div>
                </label>
                <div class="clearfix"></div>
              </div>
              <div class="collapse subcategories" [id]="'coding_1_' + i">
                <ul class="sky-search-item_3">
                  <li *ngFor="let subCategorieData of categorieData.SubCategories">
                    <label class="i-checkbox">
                      <input type="checkbox" class="subcategoriesCheckbox search_check_box" [attr.data-valueId]="subCategorieData.Id" (change)="valueChange($event, this.searchValueType.SubCategorie, subCategorieData.Id)">
                      <span class="checkmark">{{subCategorieData.SubCategoryName}}</span>
                    </label>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-3 sky-search-scroll">
        <div class="sky-menu-headings">
          <h2>{{"SearchFilterBrands" | translate}}</h2>
        </div>
        <ul class="sky-search_next">
          <li class="sky-item">
            <div class="searchTextInput s-size">
              <input type="text" [(ngModel)]="keyword" #tKey="ngModel" [placeholder]='"SearchKeyWordBrandPlaceHolder" | translate'>
            </div>
          </li>
          <li class="sky-item" *ngFor="let item of this.brandsData">
            <label class="i-checkbox">
              <input type="checkbox" class="search_check_box" name="{{item.Id}}" (change)="valueChange($event, this.searchValueType.Brand, item.Id)">
              <span class="checkmark text-transform">{{item.BrandName}}</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="col-3 sky-search-scroll">
        <div class="sky-menu-headings">
          <h2>{{"SearchFilterFeatures" | translate}}</h2>
        </div>
        <ul class="sky-search_next">
          <li class="sky-item" *ngFor="let item of this.productFeatureAry">
            <label class="i-checkbox">
              <input type="checkbox" class="search_check_box" name="{{item.Id}}" (change)="valueChange($event, this.searchValueType.ProductFeature, item.Id)">
              <span class="checkmark text-transform">{{item.ProductFeatureName}}</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="col-3 sky-search-scroll">
        <div class="sky-menu-headings">
          <h2>{{"SearchFilterPrice" | translate}}</h2>
        </div>
        <ul class="sky-search_next">
          <li class="sky-item" *ngFor="let item of this.productPriceTypeEnum | enumToArray">
            <label class="i-checkbox">
              <input type="checkbox" class="search_check_box" name="{{item}}" (change)="valueChange($event, this.searchValueType.priceType, this.productPriceTypeEnum[item])">
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceA">$1-50</span>
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceB">$51-100</span>
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceC">$101-200</span>
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceD">$201-300</span>
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceE">$301-400</span>
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceF">$401-500</span>
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceG">$501-1000</span>
              <span class="checkmark text-transform" *ngIf="this.productPriceTypeEnum[item] === this.productPriceTypeEnum.priceH">$1000+</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="catalog-button">
      <button class="sky-item-button" type="button" (click)="this.cancelSearch()">{{"SearchButtonCancel" | translate}}</button>
      <button class="sky-item-button main" type="button" (click)="this.search()">{{"SearchButtonSearch" | translate}}</button>
    </div>
  </div>
  <!-- <div class="sky-cover-bg"></div> -->
</div>
