import { OrderListService } from 'src/app/service/order-list.service';
import { IpServiceService } from './ip-service.service';
import { CheckDfpIDAndNameResponseModel } from './model/member/CheckDfpIDAndNameResponse.model';
import { CheckDfpIDAndNameRequestModel } from './model/member/CheckDfpIDAndNameRequest.model';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './../shared/alert/alert.service';
import { BaseDataService } from './base-data.service';
import { GetLoginByGuidResponseModel } from './model/member/get-login-by-guid-response.model';
import { GetLoginByGuidRequestModel } from './model/member/get-login-by-guid-request.model';
import { CheckEmpIDResponseModel } from './model/member/check-empID-response.model';
import { CheckEmpIDRequestModel } from './model/member/check-empID-request.model';
import { MemberCenterType } from './../core/enums/member/member-center-type.enum';
import { PopupService } from './../shared/popup/popup.service';
import { LocalStorageType } from './../core/enums/localStorage/local-storage-type.enum';
import { LocalStorageService } from './local-storage.service';
import { GetAwardListResponseModel } from './model/member/getAwardListResponse.model';
import { GetAwardListRequestModel } from './model/member/getAwardListRequest.model';
import { GetDfpMemberInfoResponseModel } from './model/member/getDfpMemberInfoResponse.model';
//import { GetDfpMemberInfoRequestModel } from './model/member/getDfpMemberInfoRequest.model';
import { GetMileageResponseModel } from './model/member/getMileageResponse.model';
import { GetMileageRequestModel } from './model/member/getMileageRequest.model';
import { CheckDfpIDRequestModel } from './model/member/checkDfpIDRequest.model';
import { ExChangeResponseModel } from './model/member/exChangeResponse.model';
import { ExChangeRequestModel } from './model/member/exChangeRequest.model';
import { TktLoginRequestModel } from './model/member/tktLoginRequest.mode';
import { PnrLoginRequestModel } from './model/member/pnrLoginRequest.model';
import { EmpLoginRequestModel } from './model/member/empLoginRequest.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginResponseItineraryModel, LoginResponseModel } from './model/member/loginResponse.model';
import { EShoppingApiResultModel } from './model/member/eShoppingApiResult.model';
import { DfpLoginRequestModel } from './model/member/dfpLoginRequest.model';
import { ApiConfig } from './../config/api-config';
import { LanguageService } from './language.service';
import { Injectable, enableProdMode, EventEmitter, ANALYZE_FOR_ENTRY_COMPONENTS } from '@angular/core';
import { MemberPopupType } from '../core/enums/member/member-popup-type.enum';
import { MemberLoginType } from '../core/enums/member/member-login-type.enum';
import { DatePipe } from '@angular/common';
import { ShoppingCarQueryModel } from './model/shopping/shopping-car-query.model';
import { LayoutService } from '../layout/layout.service';
import { loginService } from 'src/app/service/login.Service';
import { jwtPayloadmodel } from './model/jwt/jwtPayload.model';
import { FrontendLoginType } from '../core/enums/jwt/FrontendLogin-type.enum';
import { ResultModel } from './model/result-model';
import { CryptoService } from './crypto.service';

export interface MemberLoginData {
  loginType?: MemberLoginType; /// 登入類別
  loginData?: LoginResponseModel; /// 登入資料
  userData?: GetDfpMemberInfoResponseModel; /// 使用者資訊
  loginTime?: Date;
}

@Injectable({
  providedIn: 'root'
})

export class MemberService {
  isLoginSubject = new BehaviorSubject<boolean>(this.hasUserData());
  memberConIsOpen = false;
  memberLoginType: MemberLoginType;
  memberPopupType: MemberPopupType;
  //memberPnr =  '';
  //memberPaxName = '';
  memberPopupId = 'member-popup';
  memberClientIp = '1.0.0.1';
  empGuid = '';

  callBackURL = ApiConfig.Domain + this.languagesvc.nowLangNo + '/emploginResult';
  errorURL = ApiConfig.Domain + this.languagesvc.nowLangNo + '/emploginResult';

  // callBackURL = 'http://localhost:4200/' + this.languagesvc.nowLangNo + '/emploginResult';
  // errorURL = 'http://localhost:4200/' + this.languagesvc.nowLangNo + '/emploginResult';

  invokeFirstComponentFunction = new EventEmitter();
  memberToolOpenFunction = new EventEmitter();
  subsVar: Subscription;

  private memberPageReloadMethodCall = new Subject<any>();
  memberPageReloadMethod = this.memberPageReloadMethodCall.asObservable();

  private openFlightMethodCall = new Subject<any>();
  openFlightMethod = this.openFlightMethodCall.asObservable();

  private changeFlightMethodCall = new Subject<any>();
  changeFlightMethod = this.changeFlightMethodCall.asObservable();

  openFlightReload = false;
  isCheckDfpIDAndName = false;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private cryptosvc: CryptoService,
    private http: HttpClient,
    private languagesvc: LanguageService,
    private localStoragesvc: LocalStorageService,
    private popupsvc: PopupService,
    private datepipe: DatePipe,
    private baseDatasvc: BaseDataService,
    private alertsvc: AlertService,
    private layoutsvc: LayoutService,
    private translateService: TranslateService,
    private ipsvc: IpServiceService,
    private orderListsvc: OrderListService,
    private loginService: loginService

  ) {
  }

  openLoginPopCon(memberLoginType?: MemberLoginType, isCheckDfpIDAndName?: boolean): void {
    this.popupsvc.closeAll();

    this.popupsvc.open(this.memberPopupId);
    this.memberConIsOpen = false;
    this.memberLoginType = memberLoginType !== undefined ? memberLoginType : MemberLoginType.DynastyMember;
    this.memberPopupType = MemberPopupType.Login;
    this.isCheckDfpIDAndName = isCheckDfpIDAndName ? isCheckDfpIDAndName : false;
  }

  closeLoginPop(): void {
    this.popupsvc.close(this.memberPopupId);
  }

  openFlightPopCon(isReload: boolean): void {
    //2022-09-30預設不reload
    //2023-02-24調整為reload
    isReload = true;
    if (isReload) {
      this.openFlightReload = isReload;
      this.openFlightMethodCall.next();
    }
    this.memberConIsOpen = false;
    this.memberPopupType = MemberPopupType.SelectFlight;
    this.popupsvc.closeAll();
    this.popupsvc.open(this.memberPopupId);
  }

  openPcMemberTool(isOpen: boolean): void {
    if (isOpen) {
      this.memberConIsOpen = true;
      this.memberToolOpenFunction.emit();
    } else {
      this.memberConIsOpen = false;
    }
  }

  /// 產生購物車代號
  generateShoppingCarNo(): string {
    let shoppingCarNo = '';
    if (this.baseDatasvc.flightData) {
      shoppingCarNo = this.baseDatasvc.flightData.paxLocator +
        this.baseDatasvc.flightData.loadDate +
        this.baseDatasvc.flightData.itineraryRefNumber.toString() +
        this.baseDatasvc.flightData.paxRefNumber.toString();
    }
    return shoppingCarNo;
  }

  generateShoppingCarNoByData(data: LoginResponseItineraryModel): string {
    let shoppingCarNo = '';

    if (data !== null) {
      shoppingCarNo = data.paxLocator +
        data.loadDate +
        data.itineraryRefNumber.toString() +
        data.paxRefNumber.toString();
    }

    return shoppingCarNo;
  }

  /// Member Id 登出
  LogOut(isShowAlert: boolean): void {
    if (this.baseDatasvc.memberData.loginType === MemberLoginType.EmployID) {
    }

    this.baseDatasvc.shoppingCarData = null;
    this.baseDatasvc.shoppingCarCount = 0;
    this.baseDatasvc.memberData = null;
    this.localStoragesvc.remove(LocalStorageType.member);
    this.baseDatasvc.flightData = null;
    this.localStoragesvc.remove(LocalStorageType.FlightData);
    this.isLoginSubject.next(null);

    if (isShowAlert) {
      this.alertsvc.openAlert(this.translateService.instant('LogOutSuccessfully'), true);
    }
  }

  /// 判斷是否有存會員資料
  hasUserData(): boolean {
    return !!this.localStoragesvc.getByName(LocalStorageType.member);
  }

  /// 存入航班資訊
  addFlightData(data: LoginResponseItineraryModel): void {
    this.localStoragesvc.add(LocalStorageType.FlightData, data);
    this.baseDatasvc.flightData = data;
  }

  /// 刪除航班資訊
  removeFlightData(): void {
    this.localStoragesvc.remove(LocalStorageType.FlightData);
    this.baseDatasvc.flightData = null;
  }

  /// 1.1 使用會員ID取得訂位資訊
  async DfpLogin(condition: DfpLoginRequestModel): Promise<Observable<EShoppingApiResultModel<LoginResponseModel>>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.DfpLogin;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.DFP + ',' +
                                                                  condition.DfpCardNumber + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };

    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
    // condition.ClientIP = userIp ? userIp.ip : this.memberClientIp;
    if (this.baseDatasvc.appid) {
      condition.AppId = this.baseDatasvc.appid;
    }
    condition.LngNo = this.languagesvc.nowLangNo;

    this.baseDatasvc.flightErrorMsg = '';

    return this.http.post<EShoppingApiResultModel<LoginResponseModel>>((apiUrl), condition, Options).pipe(map(result => {
      if (result.isOK) {
        if (result.data !== null) {
          if (this.baseDatasvc.flightData !== null && this.baseDatasvc.flightData !== undefined) {
            const newFlightData = result.data.itinerary.find(x => x.paxLocator === this.baseDatasvc.flightData.paxLocator &&
              x.fltDepLclDate === this.baseDatasvc.flightData.fltDepLclDate);
            if (newFlightData !== null && newFlightData !== undefined) {
              this.baseDatasvc.flightData = newFlightData;
              this.localStoragesvc.remove(LocalStorageType.FlightData);
            } else {
              this.baseDatasvc.flightData = null;
              this.localStoragesvc.remove(LocalStorageType.FlightData);
            }
          }
          
          this.baseDatasvc.memberData.loginData = result.data;
          this.localStoragesvc.add(LocalStorageType.member, this.baseDatasvc.memberData);

          this.isLoginSubject.next(true);
          this.memberPageReloadMethodCall.next();
          this.orderListsvc.orderPageReload();
        } else {
          this.baseDatasvc.flightErrorMsg = result.message;
        }
      }

      return result;
    }));
  }

  /// 1.2 使用員工ID取得訂位資訊
  async EmpLogin(condition: EmpLoginRequestModel): Promise<Observable<EShoppingApiResultModel<LoginResponseModel>>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.EmpLogin;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.EMP + ',' +
                                                                  condition.EmployID + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };

    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
    // condition.ClientIP = userIp ? userIp.ip : this.memberClientIp;

    if (this.baseDatasvc.appid) {
      condition.AppId = this.baseDatasvc.appid;
    }
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<LoginResponseModel>>((apiUrl), condition, Options).pipe(map(result => {
      //console.log(result);

      if (result.isOK) {
        if (result.data !== null) {
          const memberData: MemberLoginData = {
            loginType: MemberLoginType.EmployID,
            userData: {
              GUID: condition.GUID,
              GROUP: condition.GROUP,
              EMPID: condition.EMPID,
              dfpCardType: "EMP"
            },
            loginTime: result.dataTime
          };

          this.baseDatasvc.memberData = memberData;
          this.localStoragesvc.add(LocalStorageType.member, memberData);

          if (this.baseDatasvc.flightData !== null && this.baseDatasvc.flightData !== undefined) {
            const newFlightData = result.data.itinerary.find(x => x.paxLocator === this.baseDatasvc.flightData.paxLocator &&
              x.fltDepLclDate === this.baseDatasvc.flightData.fltDepLclDate);
            if (newFlightData !== null && newFlightData !== undefined) {
              this.baseDatasvc.flightData = newFlightData;
              this.localStoragesvc.remove(LocalStorageType.FlightData);
            } else {
              this.baseDatasvc.flightData = null;
              this.localStoragesvc.remove(LocalStorageType.FlightData);
            }
          }

          this.baseDatasvc.memberData.loginData = result.data;
          //2022-08-15
          this.localStoragesvc.add(LocalStorageType.member, this.baseDatasvc.memberData);
          this.isLoginSubject.next(true);
          this.memberPageReloadMethodCall.next();
          this.orderListsvc.orderPageReload();
        }
      }

      return result;
    }));
  }

  /// 1.3 使用訂位代號取得訂位資訊
  async PnrLogin(condition: PnrLoginRequestModel): Promise<Observable<EShoppingApiResultModel<LoginResponseModel>>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.PnrLogin;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.PNR + ',' +
                                                                  condition.PaxLocator + ',' +
                                                                  new Date().getTime())});

    let Options = {
      headers: _headers
    };

    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
    // condition.ClientIP = userIp ? userIp.ip : this.memberClientIp;
    if (this.baseDatasvc.appid) {
      condition.AppId = this.baseDatasvc.appid;
    }
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<LoginResponseModel>>((apiUrl), condition, Options).pipe(map(result => {
      if (result.isOK) {
        if (result.data !== null) {
          // this.LogOut(false);

          if (this.baseDatasvc.flightData !== null && this.baseDatasvc.flightData !== undefined) {
            const newFlightData = result.data.itinerary.find(x => x.paxLocator === this.baseDatasvc.flightData.paxLocator &&
              x.fltDepLclDate === this.baseDatasvc.flightData.fltDepLclDate);
            if (newFlightData !== null && newFlightData !== undefined) {
              this.baseDatasvc.flightData = newFlightData;
              this.localStoragesvc.remove(LocalStorageType.FlightData);
            } else {
              this.baseDatasvc.flightData = null;
              this.localStoragesvc.remove(LocalStorageType.FlightData);
            }
          }

          this.baseDatasvc.shoppingCarData = null;
          this.baseDatasvc.memberData = null;
          this.localStoragesvc.remove(LocalStorageType.member);

          result.data.PaxLocator = condition.PaxLocator;

          const memberData: MemberLoginData = {
            loginType: MemberLoginType.PnrLogin,
            loginData: result.data,
            loginTime: result.dataTime
          };

          this.baseDatasvc.memberData = memberData;
          this.localStoragesvc.add(LocalStorageType.member, memberData);
          this.isLoginSubject.next(true);
          this.memberPageReloadMethodCall.next();
          this.orderListsvc.orderPageReload();
        }
      }

      return result;
    }));
  }

  /// 1.4 使用機票號碼取得訂位資訊
  async TktLogin(condition: TktLoginRequestModel): Promise<Observable<EShoppingApiResultModel<LoginResponseModel>>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.TktLogin;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.TKT + ',' +
                                                                  condition.TicketNumber + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };

    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
    // condition.ClientIP = userIp ? userIp.ip : this.memberClientIp;
    if (this.baseDatasvc.appid) {
      condition.AppId = this.baseDatasvc.appid;
    }
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<LoginResponseModel>>(
      (apiUrl), condition, Options).pipe(map(result => {
        if (result.isOK) {
          if (result.data !== null) {
            // this.LogOut(false);

            if (this.baseDatasvc.flightData !== null && this.baseDatasvc.flightData !== undefined) {
              const newFlightData = result.data.itinerary.find(x => x.paxLocator === this.baseDatasvc.flightData.paxLocator &&
                x.fltDepLclDate === this.baseDatasvc.flightData.fltDepLclDate);
              if (newFlightData !== null && newFlightData !== undefined) {
                this.baseDatasvc.flightData = newFlightData;
                this.localStoragesvc.remove(LocalStorageType.FlightData);
              } else {
                this.baseDatasvc.flightData = null;
                this.localStoragesvc.remove(LocalStorageType.FlightData);
              }
            }

            this.baseDatasvc.shoppingCarData = null;
            this.baseDatasvc.memberData = null;
            this.localStoragesvc.remove(LocalStorageType.member);

            result.data.TicketNumber = condition.TicketNumber;

            const memberData: MemberLoginData = {
              loginType: MemberLoginType.TicketNumber,
              loginData: result.data,
              loginTime: result.dataTime
            };

            this.baseDatasvc.memberData = memberData;
            this.localStoragesvc.add(LocalStorageType.member, memberData);

            this.isLoginSubject.next(true);
            this.memberPageReloadMethodCall.next();
            this.orderListsvc.orderPageReload();
          }
        }

        return result;
      }));
  }

  /// 3.1  會員登入驗證
  async CheckDfpID(condition: CheckDfpIDRequestModel): Promise<Observable<EShoppingApiResultModel<GetDfpMemberInfoResponseModel>>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.CheckDfpID;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.DFP + ',' +    //登入方式為會員登入
                                                                  condition.DfpCardNumber + ',' +                             //驗證卡號
                                                                  new Date().getTime())});                                    //驗證時間
    let Options = {
      headers: _headers
    };
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<GetDfpMemberInfoResponseModel>>((apiUrl), condition, Options).pipe(map(result => {
      if (result.isOK) {
//        const memberInfoCondition: GetDfpMemberInfoRequestModel = {
//          DfpCardNumber: condition.DfpCardNumber,
//          ClientIP: this.memberClientIp
//        };
        this.baseDatasvc.memberData = null;
        this.localStoragesvc.remove(LocalStorageType.member);

        const memberData: MemberLoginData = {
          loginType: MemberLoginType.DynastyMember,
          userData: result.data,
          loginTime: result.dataTime
        };
        this.baseDatasvc.memberData = memberData;
        this.localStoragesvc.add(LocalStorageType.member, memberData);

        this.isLoginSubject.next(true);
      }
      //this.localStoragesvc.remove(LocalStorageType.tokenid);
      return result;
    }));
  }

  /// 3.2  查詢哩程
  async GetMileage(condition: GetMileageRequestModel): Promise<Observable<EShoppingApiResultModel<GetMileageResponseModel>>> {
    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.GetMileage;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.DFP + ',' +
                                                                  condition.DfpCardNumber + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };
    condition.LngNo = this.languagesvc.nowLangNo;
    return this.http.post<EShoppingApiResultModel<GetMileageResponseModel>>((apiUrl), condition, Options);
  }

  /// 3.3  取得會員資料
//  async GetDfpMemberInfo(condition: GetDfpMemberInfoRequestModel): Promise<Observable<EShoppingApiResultModel<GetDfpMemberInfoResponseModel>>> {
//    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
//    var _headers = new HttpHeaders({'Content-Type': 'application/json',
//                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(new Date().getTime())});
//
//    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.GetDfpMemberInfo;
//
//    let Options = {
//      headers: _headers
//    };
//
//    return this.http.post<EShoppingApiResultModel<GetDfpMemberInfoResponseModel>>((apiUrl), condition, Options)
//      .pipe(map(result => {
//        if (result.isOK) {
//          const memberData: MemberLoginData = {
//            loginType: MemberLoginType.DynastyMember,
//            userData: result.data,
//            loginTime: result.dataTime
//          };
//
//          this.baseDatasvc.memberData = memberData;
//          this.localStoragesvc.add(LocalStorageType.member, memberData);
//          this.isLoginSubject.next(true);
//
//          return result;
//        }
//      }));
//  }


  /// 3.4  取得獎項清單
  async GetAwardList(condition: GetAwardListRequestModel): Promise<Observable<EShoppingApiResultModel<GetAwardListResponseModel>>> {
    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
    // condition.ClientIP = userIp ? userIp.ip : this.memberClientIp;
    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.GetAwardList;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.DFP + ',' +
                                                                  condition.DfpCardNumber + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<GetAwardListResponseModel>>((apiUrl), condition, Options);
  }

  /// 6.1 取得登入頁 URL
  CheckEmpID(condition?: CheckEmpIDRequestModel): Observable<EShoppingApiResultModel<CheckEmpIDResponseModel>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.CheckEmpID;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.EMP + ',' +
                                                                  '' + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };

    condition.Callback_URL = this.callBackURL;
    condition.Error_URL = this.errorURL;
    condition.ClientIP = this.memberClientIp;
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<CheckEmpIDResponseModel>>((apiUrl), condition, Options);
  }

  /// 6.2 取得登入結果
  GetLoginByGuid(condition: GetLoginByGuidRequestModel): Observable<EShoppingApiResultModel<GetLoginByGuidResponseModel>> {

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.GetLoginByGuid;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.EMP + ',' +
                                                                  '' + ',' +
                                                                  new Date().getTime())});
    let Options = {
      headers: _headers
    };

    condition.ClientIP = this.memberClientIp;
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<GetLoginByGuidResponseModel>>((apiUrl), condition, Options).pipe(map(result => {
      if (result.isOK) {
        // const memberData: MemberLoginData = {
        //   loginType: MemberLoginType.EmployID,
        //   userData: {
        //     GUID: result.data.GUID,
        //     GROUP: result.data.GROUP,
        //     EMPID: result.data.EMPID
        //   },
        //   loginTime: result.dataTime
        // };

        // this.baseDatasvc.memberData = memberData;
        // this.localStoragesvc.add(LocalStorageType.member, memberData);
        // this.isLoginSubject.next(true);
      }

      return result;
    }));
  }

  /// 檢查使用者是否過期
  checkMemberIsLogin(memberData?: MemberLoginData): boolean {
    if (memberData !== null) {
      //console.log(memberData);

      if (memberData.loginTime === undefined) {
        this.LogOut(true);
        return false;
      }

      const nowTime = new Date();
      const loginDate = new Date(memberData.loginTime);
      const timeOutDate = new Date(new Date().setHours(loginDate.getHours() + 3));

      if (nowTime >= timeOutDate) {
        this.LogOut(true);
        return false;
      }

      return true;
    }

    return false;
  }

  /// 3.5
  async CheckDfpIDAndName(condition: CheckDfpIDAndNameRequestModel): Promise<Observable<EShoppingApiResultModel<GetDfpMemberInfoResponseModel>>> {
    // const userIp = await this.ipsvc.getIPAddress().toPromise() as any;
    // condition.ClientIP = userIp ? userIp.ip : this.memberClientIp;
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.DFP + ',' +
                                                                  condition.DfpCardNumber + ',' +
                                                                  new Date().getTime())});

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.CheckDfpIDAndName;

    let Options = {
      headers: _headers
    };

    condition.PaxName = this.baseDatasvc.memberData.loginData.paxLastName + '/' + this.baseDatasvc.memberData.loginData.paxFirstName;
    condition.LngNo = this.languagesvc.nowLangNo;

    return this.http.post<EShoppingApiResultModel<CheckDfpIDAndNameResponseModel>>((apiUrl), condition, Options).pipe(map(result => {
        if (result.isOK) {
          //        const memberInfoCondition: GetDfpMemberInfoRequestModel = {
          //          DfpCardNumber: condition.DfpCardNumber,
          //          ClientIP: this.memberClientIp
          //        };

            this.baseDatasvc.memberData = null;
            this.localStoragesvc.remove(LocalStorageType.member);

            const memberData: MemberLoginData = {
              loginType: MemberLoginType.DynastyMember,
              userData: result.data,
              loginTime: result.dataTime
            };
            
            this.baseDatasvc.memberData = memberData;
            this.localStoragesvc.add(LocalStorageType.member, memberData);

            this.isLoginSubject.next(true);
          }
          //this.localStoragesvc.remove(LocalStorageType.tokenid);
          return result;
    }));
  }

  /// 我的帳戶重整
  memberPageReload(): void {
    this.memberPageReloadMethodCall.next();
  }

  /// 變更航班
  changeFlightData(): void {
    this.changeFlightMethodCall.next();
  }

  getCaptcha(): Observable<EShoppingApiResultModel<string>> {
    
    var _headers = new HttpHeaders({'Content-Type': 'application/json',
                                    'Authorization' : 'Bearer ' + this.cryptosvc.encryptData(FrontendLoginType.NONE + ',' +
                                                                  "" + ',' +
                                                                  new Date().getTime())});

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.CaptchaUrl + '?' + Date.now();

    let Options = {
      headers: _headers
    };


    return this.http.get<EShoppingApiResultModel<string>>(apiUrl, Options).pipe(map(result => {
      return result;
    }));
  }
}
