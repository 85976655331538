import { Component, OnInit, OnDestroy, Input, ElementRef, ViewEncapsulation } from '@angular/core';
import { PopupService } from './popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PopupComponent implements OnInit, OnDestroy {

  @Input() id: string;

  private element: any;

  isCenter = false;
  isTop = false;

  constructor(
    private popupsvc: PopupService,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {

    if (!this.id) {

      return;
    }

    document.body.appendChild(this.element);

    this.element.addEventListener('click', el => {
      if (el.target.className === 'popup-model') {
        this.close();
      }
    });

    if (this.element.classList.contains('center')) {
      this.element.children[0].classList.add('center');
    }

    this.popupsvc.add(this);
  }

  ngOnDestroy(): void {
    this.popupsvc.remove(this.id);
    this.element.remove();
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('popup-model-open');
  }

  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('popup-model-open');
  }

}
