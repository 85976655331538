import { LanguageService } from './language.service';
import { TopNewsModel } from './model/top-news/top-news.model';
import { ResultModel } from './model/result-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiConfig } from '../config/api-config';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(
    private http: HttpClient,
    private languagesvc: LanguageService
  ) { }
}
