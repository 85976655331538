import { YoutubeUrlPipe } from './../core/pipe/youtube-url.pipe';
import { AlertModule } from './alert/alert.module';
import { UnescapeHtmlPipe } from './../core/pipe/unescape-html.pipe';
import { EnumToArrayPipe } from './../core/pipe/enum-to-array.pipe';
import { OwlcarouselDirective } from './../core/directive/owalcarousel.directive';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PopupModule } from './popup/popup.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductImgComponent } from './product/product-img/product-img.component';
import { ProductImageTextComponent } from './product/product-image-text/product-image-text.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination/dist/ngx-pagination.module';
import { JwPaginationComponent } from './jw-pagination/jw-pagination.component';
import { MobilePageToolModule } from './mobile-page-tool/mobile-page-tool.module';
import { MobileProductFilterComponent } from './mobile-page-tool/mobile-product-filter/mobile-product-filter.component';
import { SafeHtmlPipe } from '../core/pipe/safe-html.pipe';

@NgModule({
  declarations: [
    OwlcarouselDirective,
    EnumToArrayPipe,
    YoutubeUrlPipe,
    UnescapeHtmlPipe,
    SafeHtmlPipe,
    ProductImgComponent,
    ProductImageTextComponent,
    JwPaginationComponent,
    MobileProductFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PopupModule,
    AlertModule,
    OwlcarouselDirective,
    EnumToArrayPipe,
    UnescapeHtmlPipe,
    SafeHtmlPipe,
    YoutubeUrlPipe,
    ProductImgComponent,
    ProductImageTextComponent,
    TranslateModule,
    JwPaginationComponent,
    MobilePageToolModule,
    MobileProductFilterComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
