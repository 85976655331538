<div class="row mb-4 ">
  <div class="col-xl-3 col-6 mb-5" *ngFor="let productData of this.productList, let i = index">
      <div class="productBox">
          <div class="productBox_img_box">
              <div class="productBox_hover_bg">
                  <div class="productBox_hover_btn_con">
                      <a class="sky-item-button main" href="javascript:;" (click)="this.buyNow(productData.No)">{{"ProductButtonBuyNow" | translate}}</a>
                      <button class="sky-item-button" type="button" (click)="this.addShoppingCart(productData.No)">{{"ProductButtonAddToCart" | translate}}</button>
                  </div>
              </div>
              <div class="sky-label">
                <ng-container *ngFor="let item of productData.ProductFeatures.slice(0, 2)">
                  <div class="new" [style.background-color]="item.BackgroundColor" [style.color]="item.Color" *ngIf="item.IconImg === null">{{item.ProductFeatureName}}</div><br>
                </ng-container>
              </div>
              <button type="button" class="sky-love" [class]="this.wishListSvc.isInWishList(productData.No, productData.IsKeep) ? 'active' : ''"
              (click)="this.wishListSvc.addToWishList(productData.No, productData.ProductType, this.wishListSvc.isInWishList(productData.No, productData.IsKeep))"></button>
              <a href="javascript:;" class="product_link"><img [src]="productData.ProductImgs[0].SmallImgFileName" alt="" *ngIf="productData.ProductImgs.length > 0"></a>
          </div>
          <div class="productBox_dec_box">
            <!-- [routerLink]="['product/detail', productData.No]" -->
              <a  class="product_link"
                  routerLink="/{{this.languagesvc.nowLangNo}}/product/detail/{{productData.No}}" [queryParams]="{ productType: this.productType }">
                  <p class="itemNo">{{"ProductDetailItemNo" | translate}} : {{productData.No}}</p>
                  <p class="brandName">{{productData.BrandName}}</p>
                  <p class="productName ellipsis-2">
                    {{productData.ProductName}}
                    <ng-container *ngIf="this.productEnList.length > 0">
                      <br>
                      {{this.getProductEnName(productData.No)}}
                    </ng-container>
                  </p>
                  <div class="product_price_box">
                      <p class="price" *ngIf="productData.Price || productData.Price > 0">
                        <ng-container>USD {{productData.Price}}</ng-container>
                        <ng-container *ngIf="this.productTwPriceAry.length > 0 && this.productTwPriceAry[i]">
                          <span class="sub_font">/ TWD {{this.productTwPriceAry[i]}}</span>
                        </ng-container>
                      </p>
                      <small *ngIf="productData.OldPrice && productData.Price < productData.OldPrice">
                        <ng-container>USD {{productData.OldPrice}}</ng-container>
                        <ng-container *ngIf="this.productTwOldPriceAry.length > 0 && this.productTwOldPriceAry[i]">
                          <span class="sub_font">/ TWD {{this.productTwOldPriceAry[i]}}</span>
                        </ng-container>
                      </small>
                  </div>
              </a>
          </div>
      </div>
  </div>
</div>
