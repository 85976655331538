import { MobilePageToolService } from './../mobile-page-tool.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryType } from 'src/app/core/enums/server/category-type.enum';
import { LayoutService } from 'src/app/layout/layout.service';
import { MenuService } from 'src/app/layout/menu/menu.service';
import { SearchStepEnum } from 'src/app/layout/search/search-step-enum.enum';
import { SearchService } from 'src/app/layout/search/search.service';
import { ProductQueryModel } from 'src/app/routes/product/product-Query.model';
import { LanguageService } from 'src/app/service/language.service';
import { BrandDataModel } from 'src/app/service/model/brand/brand.model';
import { MenuDataModel } from 'src/app/service/model/menu/menu.model';
import { ProductFeatureConditionModel } from 'src/app/service/model/product-feature/product-feature-condition.model';
import { ProductFeatureModel } from 'src/app/service/model/product-feature/product-feature.model';
import { ProductConditionModel } from 'src/app/service/model/product/product-condition.model';
import { ProductFeatureService } from 'src/app/service/product-feature.service';

export enum searchValueTypeEnum {
  Categorie,
  SubCategorie,
  BranchCategorie,
  ProductFeature,
  Brand
}

@Component({
  selector: 'app-mobile-product-filter',
  templateUrl: './mobile-product-filter.component.html',
  styleUrls: ['./mobile-product-filter.component.css']
})
export class MobileProductFilterComponent implements OnInit {

  searchStepEnum = SearchStepEnum;
  @Input() brandDataAry: BrandDataModel[] = [];
  @Input() toolId: string;

  @Output() searchSubmit = new EventEmitter<ProductConditionModel>();
  @ViewChild('tKeyword') tKeyword: NgModel;

  productQueryModel?: ProductQueryModel = null;

  searchValueType = searchValueTypeEnum;
  brandsData: BrandDataModel[];
  CategorieAry: MenuDataModel[];
  categorieIds: number[] = [];
  subCategorieIds: number[] = [];
  productFeatureAry: ProductFeatureModel[] = [];
  eventCategorieIds: number[] = [];
  branchCategorieIds: number[] = [];
  brandIds: number[] = [];
  productFeatureIds: number[] = [];

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    public layoutsvc: LayoutService,
    private el: ElementRef,
    public searchsvc: SearchService,
    public languagesvc: LanguageService,
    private productFeaturesvc: ProductFeatureService,
    private menusvc: MenuService,
    private mobilePageToolsvc: MobilePageToolService
  ) { }

  ngOnInit(): void {


    this.activatedRoute.queryParams.subscribe(params => {

      this.productQueryModel = params as ProductQueryModel;

      this.getProductFeature();
      this.getCatalogList();
    });
  }

  changePage(searchStep: SearchStepEnum): void {
    this.searchsvc.searchStep = searchStep;
  }
  backPage(searchStep: SearchStepEnum): void {
    switch (searchStep) {
      case (this.searchStepEnum.Step2): {
        this.searchsvc.searchStep = this.searchStepEnum.Step1;
        break;
      }
      case (this.searchStepEnum.Step3): {
        this.searchsvc.searchStep = this.searchStepEnum.Step2;
        break;
      }
      default: {
        this.searchsvc.searchStep = this.searchStepEnum.Step1;
      }
    }
  }
  getProductFeature(): void {

    const condition: ProductFeatureConditionModel = { PageSize: 1000 };

    this.productFeaturesvc.getData(condition).subscribe(result => {

      if (result !== undefined && result.Data.Datas !== undefined) {

        this.productFeatureAry = result.Data.Datas;
      }
    },
      (error) => {

      });
  }
  getCatalogList(): void {

    this.CategorieAry = [];
    this.categorieIds = [];
    this.subCategorieIds = [];
    this.eventCategorieIds = [];
    this.brandIds = [];
    this.productFeatureIds = [];

    this.menusvc.getData(false).subscribe(result => {

      if (result.Success) {

        // const mainCategorieAry = result.Data.filter(x => x.CategoryType === CategoryType.Type1);

        if (this.productQueryModel !== null) {

          if (this.productQueryModel.categoryIds !== undefined &&
              this.productQueryModel.categoryIds.length > 0) {

            const categoryIdAry = this.productQueryModel.categoryIds.split(',').map(Number);

            categoryIdAry.forEach(element => {

              this.categorieIds.push(element);

              this.CategorieAry.push(result.Data.filter(x => x.Id === element)[0]);
            });
          }else{

            this.CategorieAry = result.Data.filter(x => x.CategoryType === CategoryType.Type1);
          }

          if (this.productQueryModel.eventCategorieIds !== undefined &&
            this.productQueryModel.eventCategorieIds.length > 0) {

            const eventCategorieIdAry = this.productQueryModel.eventCategorieIds.split(',').map(Number);

            eventCategorieIdAry.forEach(element => {

              this.eventCategorieIds.push(element);
            });
          }

          if (this.productQueryModel.subIds !== undefined &&
            this.productQueryModel.subIds.length > 0) {

            const subIdAry = this.productQueryModel.subIds.split(',').map(Number);

            subIdAry.forEach(element => {

              this.subCategorieIds.push(element);
            });
          }

          if (this.productQueryModel.branchIds !== undefined &&
            this.productQueryModel.branchIds.length > 0) {

            const branchIdAry = this.productQueryModel.branchIds.split(',').map(Number);

            branchIdAry.forEach(element => {

              this.branchCategorieIds.push(element);
            });
          }

          if (this.productQueryModel.productFeatureIds !== undefined &&
            this.productQueryModel.productFeatureIds.length > 0) {

              const productFeatureAry = this.productQueryModel.productFeatureIds.split(',').map(Number);

              productFeatureAry.forEach(element => {

                this.productFeatureIds.push(element);
              });
            }
        }
      }
    }, (error) => { });
  }
  valueChange($event, valueType: searchValueTypeEnum, valueId: number): void {

    const checkStatus: boolean = $event.target.checked;

    switch (valueType) {

      case searchValueTypeEnum.Categorie: {

        const parentnode = $event.target.closest('.primary_category');
        const subinputnodes = Array.prototype.slice.call(parentnode.getElementsByClassName('subcategories')[0].getElementsByClassName('subcategoriesCheckbox'));

        if (checkStatus === true) {

          this.categorieIds.push(valueId);
        } else {

          this.categorieIds = this.categorieIds.filter(id => id !== valueId);
          subinputnodes.forEach(element => {

            const subValueId = Number(element.getAttribute('data-valueId'));

            element.checked = false;
            this.subCategorieIds = this.subCategorieIds.filter(id => id !== subValueId);
          });
        }


        break;
      }

      case searchValueTypeEnum.SubCategorie: {

        if (checkStatus === true) {

          this.subCategorieIds.push(valueId);
        } else {

          this.subCategorieIds = this.subCategorieIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.BranchCategorie: {

        if (checkStatus === true) {

          this.branchCategorieIds.push(valueId);
        } else {

          this.branchCategorieIds = this.branchCategorieIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.Brand: {

        if (checkStatus === true) {

          this.brandIds.push(valueId);
        } else {

          this.brandIds = this.brandIds.filter(id => id !== valueId);
        }
        break;
      }

      case searchValueTypeEnum.ProductFeature: {

        if (checkStatus === true) {

          this.productFeatureIds.push(valueId);
        } else {

          this.productFeatureIds = this.productFeatureIds.filter(id => id !== valueId);
        }
        break;
      }

      default: {
        break;
      }
    }

  }
  search(): void {

    const condition: ProductConditionModel = {
      CategoryIds: this.categorieIds !== undefined && this.categorieIds.length > 0 ? this.categorieIds : null,
      SubCategoryIds: this.subCategorieIds !== undefined && this.subCategorieIds.length > 0 ? this.subCategorieIds : null,
      ProductFeatureIds: this.productFeatureIds !== undefined && this.productFeatureIds.length > 0 ? this.productFeatureIds : null,
      BrandIds: this.brandIds !== undefined && this.brandIds.length > 0 ? this.brandIds : null,
      PageNumber: 1
    };



    this.searchSubmit.emit(condition);
    this.mobilePageToolsvc.close(this.toolId);
  }
  cancelSearch(): void {
    this.categorieIds = [];
    this.subCategorieIds = [];
    this.branchCategorieIds = [];
    this.brandIds = [];
    this.productFeatureIds = [];

    const searchContainer = document.getElementsByClassName('sky-search-item-mb')[0];

    const checkboxs = Array.prototype.slice.call(document.getElementsByClassName('search_check_box'));

    checkboxs.forEach(element => {

      element.checked = false;
    });

    const filterItems = Array.prototype.slice.call(document.getElementsByClassName('filterItem'));

    filterItems.forEach(element => {

      element.setAttribute('aria-expanded', false);
      element.classList.remove('collapsed');
      element.classList.add('collapsed');
    });

    const collapses = Array.prototype.slice.call(document.getElementsByClassName('collapse'));

    collapses.forEach(element => {

      element.classList.remove('show');
    });

    this.mobilePageToolsvc.close(this.toolId);
  }
}
