import { BaseDataService } from 'src/app/service/base-data.service';
import { GeneralPopProductDataModel, PopupService } from './../shared/popup/popup.service';
import { MemberService } from 'src/app/service/member.service';
import { AlertService } from './../shared/alert/alert.service';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { Subscription } from 'rxjs';

import { LayoutService } from './layout.service';
import { MenuService } from './menu/menu.service';
import { SearchService } from './search/search.service';
import { CookieService } from '../service/cookie.service';
import { ShoppingCartService } from '../service/shopping-cart.service';
import { MemberLoginType } from '../core/enums/member/member-login-type.enum';
import { MenuType } from '../core/enums/menu/menu-type.enum';
import { MenuStep } from './menu/menu-step.enum';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  animations: [
    trigger('slideInOut', [
      state('open', style({
        transform: 'translateX(0%)'
      })),
      state('closed', style({
        transform: 'translateX(-100%)'
      })),
      transition('closed => open', [
        animate('0.3s linear')
      ]),
      transition('open => closed', [
        animate('0.3s linear')
      ])
    ])
  ]
})
export class LayoutComponent implements OnInit {

  windowWidth = 0;
  // wasInside = false;
  menuOpen = false;
  docScrollTop: number;
  isShow = true;
  // isShowTop = true;
  isShowFooterPaddingBottom = false;

  memberLoginTypeEnum = MemberLoginType;

  constructor(
    public layoutsvc: LayoutService,
    public menusvc: MenuService,
    public searchsvc: SearchService,
    public cookiesvc: CookieService,
    public shoppingcartsvc: ShoppingCartService,
    private router: Router,
    public membersvc: MemberService,
    public alertsvc: AlertService,
    public popupsvc: PopupService,
    public baseDataSvc: BaseDataService
  ) {

    this.layoutsvc.isScrollDown = false;
    this.docScrollTop = document.documentElement.scrollTop;

  }

  ngOnInit(): void {

    if (this.membersvc.isLoginSubject.value) {
      if (this.baseDataSvc.flightData) {
        this.shoppingcartsvc.shoppingGet().subscribe();
      }
    }

    this.layoutsvc.windowWidth = window.innerWidth;
    this.layoutsvc.windowHeight = window.innerHeight;

    if (window.innerWidth <= 991) {
      this.layoutsvc.isMobile = true;
    }

    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
        //console.log('NavigationStart');
        window.scrollTo(0, 0);
        this.menusvc.closePcMenu();
        this.menusvc.closeMobileMenu();
        this.membersvc.memberConIsOpen = false;
        this.searchsvc.pcSearchIsopen = false;
        this.layoutsvc.isShowTop = true;
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        //console.log('NavigationEnd');
        this.isShow = event.url.includes('/member/download') ? false : true;

        if (event.url.includes('/product/detail') && this.layoutsvc.isMobile) {
          this.layoutsvc.isShowTop = false;
          this.isShowFooterPaddingBottom = true;
        } else {
          this.layoutsvc.isShowTop = true;
          this.isShowFooterPaddingBottom = false;
        }
      }
      if (event instanceof NavigationError) {

      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {

    this.layoutsvc.windowWidth = window.innerWidth;
    this.layoutsvc.windowHeight = window.innerHeight;

    if (window.innerWidth <= 991) {
      this.layoutsvc.isMobile = true;
    } else {
      this.layoutsvc.isMobile = false;
    }
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event): void {

    const doc = document.documentElement;

    let mobileMargin = 0;

    if (this.layoutsvc.isMobile) {
      mobileMargin = 105;
    }

    if (doc.scrollTop > this.docScrollTop){

      if (doc.scrollTop > mobileMargin) {

        this.layoutsvc.isScrollDown = true;
      }
    }else{

      this.layoutsvc.isScrollDown = false;
    }

    this.docScrollTop = doc.scrollTop;
  }

  openMenuEvent($event): void {
    this.menuOpen = true;
  }

  closeMenuEvent($event): void {
    this.menuOpen = false;
  }

  onActivate(event): void {
    window.scroll(0, 0);
  }

  removeAllDetails(): void {

    this.popupsvc.closeAll();
    this.shoppingcartsvc.shoppingCartRemoveAll().subscribe();
  }

  removeProduct(data: GeneralPopProductDataModel): void {

    this.popupsvc.closeAll();
    this.shoppingcartsvc.shoppingCartRemoveFromCart(data.popRemoveProductNo, data.popRemoveProductType, data.popRemoveCount, data.isShoppingCart)
  }

  openLoginPopCon(memberLoginType?: MemberLoginType, isCheckDfpIDAndName?: boolean): void {
    if (this.layoutsvc.isMobile) {
      this.popupsvc.closeAll();
      this.menusvc.menuType = MenuType.Member;
      this.membersvc.memberLoginType = memberLoginType;
      this.menusvc.openMobileMenu(MenuStep.step2);
    }else{
      this.membersvc.openLoginPopCon(memberLoginType, isCheckDfpIDAndName);
    }
  }
}
