<div class="wrap_marquee_foot marquee_foot-container" *ngIf="!this.cookiesvc.isConsented">
  <div class="position-relative">
      <div class="marquee_foot_text" >
          <p [class]="this.layoutsvc.isMobile ? '' : 'ellipsis-2'" [innerHTML]='"CookieNotice" | translate'></p>
      </div>
      <div class="position-absolute marquee_foot_button">
          <button class="sky-item-button" type="button" (click)="this.cookiesvc.setConsent(true, $event)">{{"CookieAccept" | translate}}</button>
      </div>
  </div>
</div>
