<div class="mobile-warp tool_warpper" [style.height]='this.layoutsvc.windowHeight + "px"' [@slideInOut]="this.isOpen ? 'open' : 'closed'">

  <div class="sky-head-fixed">
    <div class="head_secondary_bg-m">
      <div class="head-container-fixed_m">
        <div class="head-logo float-left">
          <!-- <a [routerLink]="['/ ' + this.languagesvc.nowLangNo + ' ']"><img
              src="../../../../assets/content/images/img-logo-cal-header-w@3x.png" alt="" width="208px"></a> -->
        </div>
        <div class="head-icon float-right">
          <a href="javascript:;" (click)="close()"><img src="./assets/content/images/ic-cancel-w.svg"
              alt="" width="32px"></a>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>

  <ng-content></ng-content>
</div>
