import { LanguageService } from './../../../../service/language.service';
import { Component, OnInit, AfterViewInit, Input, ViewChild } from '@angular/core';
import { BrandDataModel } from '../../../../service/model/brand/brand.model';
import { NgModel } from '@angular/forms';

import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-pc-menu-brand-search',
  templateUrl: './pc-menu-brand-search.component.html',
  styleUrls: ['./pc-menu-brand-search.component.css']
})
export class PcMenuBrandSearchComponent implements OnInit, AfterViewInit {

  public keyword = '';

  @ViewChild('tKeyword') tKeyword: NgModel;

  @Input() public brandsDataAry: BrandDataModel[];

  public brandsData: BrandDataModel[];

  constructor(
    public languagesvc: LanguageService
  ) { }

  ngOnInit(): void {
    this.brandsData = this.brandsDataAry;
  }

  ngAfterViewInit(): void {

    this.tKeyword.valueChanges
    .pipe(
      debounceTime(500)
    )
    .subscribe(k => {

      const kw: string = k;

      this.brandsData = this.brandsDataAry;

      if (kw.length > 0) {

        this.brandsData = this.brandsData.filter(x => {

          return x.BrandName.includes(k);
        });
      }
    });
  }
}
