import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MobilePageToolService {

  private mobilePageTools: any[] = [];

  isOpen = false;

  constructor() { }

  add(alert: any): void {
    this.mobilePageTools.push(alert);
  }

  remove(id: string): void {
    this.mobilePageTools = this.mobilePageTools.filter(x => x.id !== id);
  }

  open(id: string): void {

    const alert = this.mobilePageTools.find(x => x.id === id);
    this.isOpen = true;
    alert.open();
  }

  close(id: string): void {
    const alert = this.mobilePageTools.find(x => x.id === id);
    this.isOpen = false;
    alert.close();
  }

}
