
<div class="sky-menu-con">
  <ul>
    <li class="sky-item">
      <div class="searchTextInput s-size">
        <input type="text" [(ngModel)]="keyword" #tKeyword="ngModel" [placeholder]="'MenuBrandPlaceholder' | translate">
      </div>
    </li>

    <li class="sky-item" *ngFor="let brandData of this.brandsData">
      <a class="menu_link"
      routerLink="/{{this.languagesvc.nowLangNo}}/all brands/{{brandData.BrandName}}"
      [queryParams]="{ name: brandData.BrandName, brandIds: brandData.Id, page: 1 }">
        <p>{{brandData.BrandName}}</p>
      </a>
    </li>
  </ul>
</div>
