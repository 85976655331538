
<div>
  <div class="content_inside">
    <app-header *ngIf="this.isShow"></app-header>

    <router-outlet (activate)="this.onActivate($event)"></router-outlet>
  </div>

  <app-footer *ngIf="this.isShow" [isShowPaddingBottom]="this.isShowFooterPaddingBottom"></app-footer>

  <div class="mobile_side_warpper mobile_menu" id="mobile_menu"
    [@slideInOut]="this.menusvc.mobileMenuIsopen ? 'open' : 'closed'"></div>

  <div class="mobile_side_warpper mobile_search" id="mobile_search"
    [@slideInOut]="this.searchsvc.mobileSearchIsopen ? 'open' : 'closed'"></div>

  <div class="mobile_side_warpper mobile_shooping_cart" id="mobile_shooping_cart"
    [@slideInOut]="this.shoppingcartsvc.mobileShoppingCartIsopen ? 'open' : 'closed'"></div>

  <a class="wrap_gotop show" href="javascript:;" style="opacity: 1;" *ngIf="this.layoutsvc.isShowTop"></a>
</div>

<app-cookie-consent></app-cookie-consent>

<app-alert [id]="this.alertsvc.alertId">
  <div class="alert_warrper">
    <div class="alert_warrper_con">
      <p>{{this.alertsvc.alertText}}</p>
      <button type="button" (click)="this.alertsvc.close(this.alertsvc.alertId)">
        <img src="../../assets/content/images/ic-ok-white.png" *ngIf="this.alertsvc.alertOpenIsOk">
        <img src="../../assets/content/images/ic-failed.svg" *ngIf="!this.alertsvc.alertOpenIsOk">
      </button>
    </div>
  </div>
</app-alert>

<app-alert id="loading">
  <div class='lmask'>
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>
</app-alert>

<app-popup [id]="this.popupsvc.generalPop" class="" >
  <div class="member_modal_lightbox_container" *ngIf="this.popupsvc.generalPopData">
    <div class="position-relative">
      <a class="ic-cancel position-absolute" href="javascript:;" (click)="this.popupsvc.close(this.popupsvc.generalPop)" ><img src="./assets/content/images/ic-cancel.svg"></a>
      <div class="member_modal_title">
        <p>{{this.popupsvc.generalPopData.title}}</p>
      </div>
      <div class="member_modal_subtitle text-center" [innerHTML]="this.popupsvc.generalPopData.content | safeHtml"></div>
      <div class="tabled_button catalog-button">
        <button class="sky-item-button" type="button" *ngIf="this.popupsvc.generalPopData.confirmButtonText"
        (click)="this.popupsvc.close(this.popupsvc.generalPop)">{{this.popupsvc.generalPopData.confirmButtonText}}</button>
        <button class="sky-item-button" type="button" *ngIf="this.popupsvc.generalPopData.fightButtonText"
        (click)="this.membersvc.openFlightPopCon(true)">{{this.popupsvc.generalPopData.fightButtonText}}</button>
        <button class="sky-item-button" type="button" *ngIf="this.popupsvc.generalPopData.removeAllProductConfirmText"
        (click)="this.removeAllDetails()">{{this.popupsvc.generalPopData.removeAllProductConfirmText}}</button>
        <button class="sky-item-button" type="button" *ngIf="this.popupsvc.generalPopData.productData"
        (click)="this.removeProduct(this.popupsvc.generalPopData.productData)">{{this.popupsvc.generalPopData.removeProductConfirmText}}</button>
        <button class="sky-item-button" type="button" *ngIf="this.popupsvc.generalPopData.loginButtonText"
        (click)="this.openLoginPopCon(this.memberLoginTypeEnum.DynastyMember, true)">{{this.popupsvc.generalPopData.loginButtonText}}</button>
      </div>
    </div>
  </div>
</app-popup>


