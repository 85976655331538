<div class="sky-navbar-img">
  <h3 class="float-right">{{this.pager}}</h3>
    <div class="clearfix"></div>

    <div class="owl-carousel sky-navbar-img-scroll menu_right_banner" appOwalcarousel [owlOptions]="this.bannerOptions">
      <div class="item" [attr.data-index]="i" *ngFor="let item of this.bannerAry, let i = index" style="height: 345px;overflow: hidden;">
        <a [href]="item.UrlLink" >
          <img [src]="item.BannerImgUrl" />
        </a>
      </div>
    </div>

  <!-- <div class="sky-navbar-img-scroll uk-position-relative uk-visible-toggle uk-light" tabindex="-1"
       uk-slideshow="min-height: 345; max-height: 460">

    <ul class="uk-slideshow-items">
      <li *ngFor="let item of menuData.MenuRightBanners">
        <img [src]="item.BannerImgUrl" alt="" uk-cover>
      </li>
    </ul>

    <div class="uk-position-absolute sky-navbar-arrow">
      <a href="javascript:;" uk-slideshow-item="previous"><img
          src="./assets/content/images/ic-banner-2-previous.svg" width="48px"></a>
      <a href="javascript:;" uk-slideshow-item="next"><img
          src="./assets/content/images/ic-banner-2-next.svg" width="48px"></a>
    </div>

  </div> -->
</div>
