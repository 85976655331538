import { ShoppingRowComponent } from './shopping-cart/shopping-row/shopping-row.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { SearchComponent } from './search/search.component';
import { PcMemberFlightComponent } from './member/pc-member/pc-member-flight/pc-member-flight.component';
import { PcMemberLoginComponent } from './member/pc-member/pc-member-login/pc-member-login.component';
import { PcMemberComponent } from './member/pc-member/pc-member.component';
import { PcSearchComponent } from './search/pc-search/pc-search.component';
import { PcMenuComponent } from './menu/pc-menu/pc-menu.component';
import { PcMenuBrandSearchComponent } from './menu/pc-menu/pc-menu-brand-search/pc-menu-brand-search.component';
import { MobileLanguageComponent } from './language/mobile-language/mobile-language.component';
import { MobileMemberComponent } from './member/mobile-member/mobile-member.component';
import { MobileSearchComponent } from './search/mobile-search/mobile-search.component';
import { MobileMenuComponent } from './menu/mobile-menu/mobile-menu.component';

import { SharedModule } from '../shared/shared.module';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { PcShoppingCartComponent } from './shopping-cart/pc-shopping-cart/pc-shopping-cart.component';
import { MobileShoppingCartComponent } from './shopping-cart/mobile-shopping-cart/mobile-shopping-cart.component';
import { MobileShoppingCartFlightComponent } from './shopping-cart/mobile-shopping-cart/mobile-shopping-cart-flight/mobile-shopping-cart-flight.component';
import { PcMenuBannerComponent } from './menu/pc-menu/pc-menu-banner/pc-menu-banner.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    PcMenuComponent,
    PcMemberComponent,
    PcMemberLoginComponent,
    PcMemberFlightComponent,
    PcSearchComponent,
    MobileMenuComponent,
    MobileMemberComponent,
    MobileLanguageComponent,
    MobileSearchComponent,
    SearchComponent,
    PcMenuBrandSearchComponent,
    CookieConsentComponent,
    ShoppingCartComponent,
    PcShoppingCartComponent,
    MobileShoppingCartComponent,
    MobileShoppingCartFlightComponent,
    ShoppingRowComponent,
    PcMenuBannerComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers: [],
  exports:
  [
    HeaderComponent,
    FooterComponent,
    LayoutComponent
  ]
})
export class LayoutModule { }
