import { ProductType } from './../../../core/enums/product/product-type.enum';
import { BaseDataService } from 'src/app/service/base-data.service';
import { DatePipe } from '@angular/common';
import { ShoppingCartService } from './../../../service/shopping-cart.service';
import { AlertService } from './../../../shared/alert/alert.service';
import { MemberService } from './../../../service/member.service';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ShoppingCarItemModel } from 'src/app/service/model/shopping/shopping-car-item.model';
import { ShoppingCarDetailModel } from 'src/app/service/model/shopping/shopping-car-main.model';
import { ShoppingCarQueryModel } from 'src/app/service/model/shopping/shopping-car-query.model';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-shopping-row',
  templateUrl: './shopping-row.component.html',
  styleUrls: ['./shopping-row.component.css']
})
export class ShoppingRowComponent implements OnInit {

  @Input() productData: ShoppingCarDetailModel;

  public count = 0;
  @ViewChild('tCount') tCount: NgModel;

  constructor(
    private membersvc: MemberService,
    private alertsvc: AlertService,
    private shoppingcartsvc: ShoppingCartService,
    private datepipe: DatePipe,
    public baseDatasvc: BaseDataService
  ) {

  }

  ngOnInit(): void {
    this.count = this.productData.Count;
  }

  removeFromCart(productNo: string, productType: ProductType, count: number): void {

    this.shoppingcartsvc.shoppingCartRemoveFromCart(productNo, productType, count, true);
  }

  getShoppingCarData(): void {

    this.shoppingcartsvc.shoppingGet().subscribe(result => {

    }, (error) => {});
  }

  add(): void {

    this.count++;
  }

  remove(): void {

    if (this.count > 1) {
      this.count--;
    }
  }
}
