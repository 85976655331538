import { element } from 'protractor';
import { MemberLoginType } from './../core/enums/member/member-login-type.enum';
import { LocalStorageType } from './../core/enums/localStorage/local-storage-type.enum';
import { ProductService } from 'src/app/service/product.service';
import { ThrowStmt } from '@angular/compiler';
import { ShoppingCarErrorCode } from './../core/enums/shopping-car-error-code.enum';
import { data } from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { GeneralPopModel, PopupService } from 'src/app/shared/popup/popup.service';
import { AlertService } from './../shared/alert/alert.service';
import { ProductType } from './../core/enums/product/product-type.enum';
import { MemberService } from 'src/app/service/member.service';
import { BaseDataService } from './base-data.service';
import { ShoppingCarCompleteModel } from './model/shopping/shopping-car-complete.model';
import { LanguageService } from './language.service';
import { ShoppingCartComponent } from './../layout/shopping-cart/shopping-cart.component';
import { ShoppingCarMainModel } from './model/shopping/shopping-car-main.model';
import { ShoppingCarQueryModel } from './model/shopping/shopping-car-query.model';
import { ShoppingCarExceptionLogModel } from './model/shopping/shopping-car-ExceptionLog.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResultModel, ResultNoDataModel } from './model/result-model';
import { ShoppingCarItemModel } from './model/shopping/shopping-car-item.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { ApiConfig } from '../config/api-config';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalStorageService } from './local-storage.service';
import { ShoppingCartStep } from '../core/enums/shopping-cart/shopping-cart-step.enum';

export interface ShoppingCartAddModel {
  ProductNo: string;
  ProductType: ProductType;
  Count?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  public mobileShoppingCartIsopen = false;
  public pcShoppingCartIsopen = false;
  public shoppingCarData?: ShoppingCarMainModel = null;
  public shoppingCartStep = this.localStoragesvc.getByName<ShoppingCartStep>(LocalStorageType.shoppingCartStep) !== undefined ?
    this.localStoragesvc.getByName<ShoppingCartStep>(LocalStorageType.shoppingCartStep) :
    ShoppingCartStep.step1;

  callBackURL = ApiConfig.Domain + this.languagesvc.nowLangNo + '/shoppingCart/step4';
  errorURL = ApiConfig.Domain + this.languagesvc.nowLangNo + '/shoppingCart/failed';

  couponCode?: string;
  couponAmount?: number;

  private shoppingCartPageReloadMethodCall = new Subject<any>();
  shoppingCartPageReloadMethod = this.shoppingCartPageReloadMethodCall.asObservable();

  private shoppingCartOpenMethodCall = new Subject<any>();
  shoppingCartOpenMethod = this.shoppingCartOpenMethodCall.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private membersvc: MemberService,
    private languagesvc: LanguageService,
    private baseDatasvc: BaseDataService,
    private datepipe: DatePipe,
    private alertsvc: AlertService,
    private popupsvc: PopupService,
    private translateService: TranslateService,
    private productsvc: ProductService,
    private localStoragesvc: LocalStorageService
  ) {
  }

  openMobleShoppingCart(): void {
    this.mobileShoppingCartIsopen = true;
    document.getElementsByTagName('body')[0].classList.add('overflowHidden');
    document.getElementsByTagName('html')[0].classList.add('overflowHidden');
  }

  closeMobleShoppingCart(): void {
    this.mobileShoppingCartIsopen = false;
    document.getElementsByTagName('body')[0].classList.remove('overflowHidden');
    document.getElementsByTagName('html')[0].classList.remove('overflowHidden');
  }

  tooglePcShoppingCart(): void {
    this.pcShoppingCartIsopen = !this.pcShoppingCartIsopen;
  }

  openPcShoppingCart(isOpen: boolean): void {
    //console.log('openShoppingCart:' + isOpen);

    if (isOpen) {
      this.pcShoppingCartIsopen = true;
      this.shoppingCartOpenMethodCall.next();
    } else {
      this.pcShoppingCartIsopen = false;
    }
  }

  /// 加入購物車
  async shoppingAdd(productNo: string, productType: ProductType, count?: number): Promise<Observable<ResultModel<ShoppingCarErrorCode>>> {
    const popData: GeneralPopModel = {};

    if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      // this.alertsvc.openAlert('請先登入會員', false);

      this.membersvc.openLoginPopCon(MemberLoginType.DynastyMember, false);

      return;
    }

    if (!this.baseDatasvc.flightData) {
      popData.title = this.translateService.instant('shoppingCartAlertChooseFlightTitle');
      popData.content = this.translateService.instant('shoppingCartAlertChooseFlightContent');
      popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonCancel');
      popData.fightButtonText = this.translateService.instant('shoppingCartAlertChooseFlightChooseFlight');

      this.popupsvc.openGeralPop(popData);

      return;
    }

    // if (productType === ProductType.AddPurchase) {
    //   console.log(this.baseDatasvc.shoppingCarData);

    //   if (this.baseDatasvc.shoppingCarData.Details.length === 0) {
    //     popData.title = this.translateService.instant('shoppingCartAlertCantAddTitle');
    //     popData.content = this.translateService.instant('shoppingCartAlertCantAddContent');
    //     popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonConfirm');

    //     this.popupsvc.openGeralPop(popData);

    //     return;
    //   }
    // }

    let addCount = count ? count : 1;

    const result = await this.shoppingGet().toPromise();

    if (!result.Success) {
      /// 取得購物車失敗
    }
    //alert(result);
    var detailProduct = result.Data.Details.find(x => x.ProductNo === productNo);

    if (detailProduct && detailProduct !== undefined) {
      addCount = addCount + detailProduct.Count;

      if (addCount > detailProduct.MaxCount) {
        //購物車+增加數量 > 可預訂數量
        //console.log(result.Message);
        //const alertContent = this.translateService.instant('shoppingCartAlertLimitReachedContent1') + detailProduct.ProductNo + this.translateService.instant('shoppingCartAlertLimitReachedContent2') + detailProduct.MaxCount + this.translateService.instant('shoppingCartAlertLimitReachedContent3');
        const alertContent = this.translateService.instant('shoppingCartAlertLimitReachedContent1') + detailProduct.ProductNo + this.translateService.instant('shoppingCartAlertLimitReachedContent2');
        const popData: GeneralPopModel = {
          title: this.translateService.instant('shoppingCartAlertLimitReachedTitle'),
          content: alertContent,
          confirmButtonText: this.translateService.instant('shoppingCartAlertLimitReachedConfirm'),
        };
        //2023-01-10
        //預訂數量>最大可預訂數量, 扣掉多餘的購物車
        if (detailProduct.Count > detailProduct.MaxCount) {
          this.shoppingRemove(productNo, detailProduct.Count - detailProduct.MaxCount).subscribe(result => {
            if (result.Success) {
              //this.alertsvc.openAlert('已移除商品', true);
            } else {
              //this.alertsvc.openAlert(result.Message, false);
            }
          });
        }
        this.popupsvc.openGeralPop(popData);

        return;
      }
    }

    var memberProduct = result.Data.Members.find(x => x.ProductNo === productNo);

    //console.log(productNo);
    //console.log(result.Data.Members);
    //console.log(memberProduct);

    if (memberProduct && memberProduct !== undefined) {
      addCount = addCount + memberProduct.Count;

      if (addCount > memberProduct.MaxCount) {
        //console.log(result.Message);
        //const alertContent = this.translateService.instant('shoppingCartAlertLimitReachedContent1') + detailProduct.ProductNo + this.translateService.instant('shoppingCartAlertLimitReachedContent2') + detailProduct.MaxCount + this.translateService.instant('shoppingCartAlertLimitReachedContent3');
        const alertContent = this.translateService.instant('shoppingCartAlertLimitReachedContent1') + detailProduct.ProductNo + this.translateService.instant('shoppingCartAlertLimitReachedContent2');

        const popData: GeneralPopModel = {
          title: this.translateService.instant('shoppingCartAlertLimitReachedTitle'),
          content: alertContent,
          confirmButtonText: this.translateService.instant('shoppingCartAlertLimitReachedConfirm'),
        };
        //2023-01-10
        //預訂數量>最大可預訂數量, 扣掉多餘的購物車
        if (memberProduct.Count > memberProduct.MaxCount) {
          this.shoppingRemove(productNo, memberProduct.Count - memberProduct.MaxCount).subscribe(result => {
            if (result.Success) {
              //this.alertsvc.openAlert('已移除商品', true);
            } else {
              //this.alertsvc.openAlert(result.Message, false);
            }
          });
        }
        this.popupsvc.openGeralPop(popData);

        return;
      }
    }

    const condition: ShoppingCarItemModel = {
      ShoppingCarNo: this.membersvc.generateShoppingCarNo(),
      LoadStation: this.baseDatasvc.flightData.loadStation,
      ProductNo: productNo,
      Count: count ? count : 1,
      MemberCardNo: this.baseDatasvc.memberData.loginData.dfpCardType,
      FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString()
    };

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Add;

    return this.http.post<ResultModel<ShoppingCarErrorCode>>(apiUrl, condition, this.httpOptions).pipe(map(result => {
      if (result.Success) {
        this.shoppingGet().subscribe(result => { });
      } else {
        switch (result.Data) {
          case ShoppingCarErrorCode.OutOfStock:
            {
              popData.title = this.translateService.instant('shoppingCartAlertOutOfStockTitle');
              popData.content = this.translateService.instant('shoppingCartAlertOutOfStockContent');;
              popData.confirmButtonText = this.translateService.instant('shoppingCartAlertOutOfStockConfirm');

              this.popupsvc.openGeralPop(popData);

              break;
            }

          case ShoppingCarErrorCode.NotProduct:
            {
              break;
            }

          case ShoppingCarErrorCode.NotAnyProductItem:
            {
              popData.title = this.translateService.instant('shoppingCartAlertCantAddTitle');
              popData.content = this.translateService.instant('shoppingCartAlertCantAddContent');;
              popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonConfirm');

              this.popupsvc.openGeralPop(popData);

              break;
            }

          default:
            break;
        }
      }

      return result;
    }));
  }

  async shoppingAddMutiple(orderNumber: string): Promise<Observable<ResultModel<ShoppingCarErrorCode>>> {
    const popData: GeneralPopModel = {};

    if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      this.alertsvc.openAlert('請先登入會員', false);
      return;
    }

    if (!this.baseDatasvc.flightData) {
      popData.title = this.translateService.instant('shoppingCartAlertChooseFlightTitle');
      popData.content = this.translateService.instant('shoppingCartAlertChooseFlightContent');
      popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonCancel');
      popData.fightButtonText = this.translateService.instant('shoppingCartAlertChooseFlightChooseFlight');

      this.popupsvc.openGeralPop(popData);

      return;
    }

    const condition: ShoppingCarItemModel = {
      ShoppingCarNo: this.membersvc.generateShoppingCarNo(),
      LoadStation: this.baseDatasvc.flightData.loadStation,
      MemberCardNo: this.baseDatasvc.memberData.loginData.dfpCardType,
      FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString(),
      OrderNumber: orderNumber
    };

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Add;

    return this.http.post<ResultModel<ShoppingCarErrorCode>>(apiUrl, condition, this.httpOptions).pipe(map(result => {
      if (result.Success) {
        this.shoppingGet().subscribe(result => { });
      } else {
        switch (result.Data) {
          case ShoppingCarErrorCode.OutOfStock:
            {
              popData.title = this.translateService.instant('shoppingCartAlertOutOfStockTitle');
              popData.content = this.translateService.instant('shoppingCartAlertOutOfStockContent');;
              popData.confirmButtonText = this.translateService.instant('shoppingCartAlertOutOfStockConfirm');

              this.popupsvc.openGeralPop(popData);

              break;
            }

          case ShoppingCarErrorCode.NotProduct:
            {
              break;
            }

          case ShoppingCarErrorCode.NotAnyProductItem:
            {
              popData.title = this.translateService.instant('shoppingCartAlertCantAddTitle');
              popData.content = this.translateService.instant('shoppingCartAlertCantAddContent');;
              popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonConfirm');

              this.popupsvc.openGeralPop(popData);

              break;
            }

          default:
            break;
        }
      }

      return result;
    }));
  }

  // async shoppingAddMutiple(products: ShoppingCartAddModel[]): Promise<ResultNoDataModel> {
  //   const popData: GeneralPopModel = {};

  //   if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
  //     this.alertsvc.openAlert('請先登入會員', false);
  //     return;
  //   }

  //   if (!this.baseDatasvc.flightData) {
  //     popData.title = this.translateService.instant('shoppingCartAlertChooseFlightTitle');
  //     popData.content = this.translateService.instant('shoppingCartAlertChooseFlightContent');
  //     popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonCancel');
  //     popData.fightButtonText = this.translateService.instant('shoppingCartAlertChooseFlightChooseFlight');

  //     this.popupsvc.openGeralPop(popData);

  //     return;
  //   }

  //   const getCartResult = await this.shoppingGet().toPromise();
  //   const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Add;

  //   if (!getCartResult.Success) {
  //     /// 取得購物車失敗
  //   }

  //   products.forEach(async element => {
  //     let addCount = element.Count ? element.Count : 1;

  //     var detailProduct = getCartResult.Data.Details.find(x => x.ProductNo === element.ProductNo);

  //     if (detailProduct || detailProduct !== undefined) {
  //       addCount = addCount + detailProduct.Count;

  //       if (addCount > detailProduct.MaxCount) {
  //         return;
  //       }
  //     }

  //     var memberProduct = getCartResult.Data.Members.find(x => x.ProductNo === element.ProductNo);

  //     if (memberProduct || memberProduct !== undefined) {
  //       addCount = addCount + memberProduct.Count;

  //       if (addCount > detailProduct.MaxCount) {
  //         return;
  //       }
  //     }

  //     const condition: ShoppingCarItemModel = {
  //       ShoppingCarNo: this.membersvc.generateShoppingCarNo(),
  //       LoadStation: this.baseDatasvc.flightData.loadStation,
  //       ProductNo: element.ProductNo,
  //       Count: addCount,
  //       MemberCardNo: this.baseDatasvc.memberData.loginData.dfpCardType,
  //       FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString()
  //     };

  //     var addResult = await this.http.post<ResultModel<ShoppingCarErrorCode>>(apiUrl, condition, this.httpOptions).toPromise();

  //     console.log(addResult);
  //   });

  //   const result: ResultNoDataModel = {
  //     Success: true,
  //     Message: '' as null
  //   }

  //   return result;
  // }

  /// 移除購物車項目
  shoppingRemove(productNo: string, count: number): Observable<ResultNoDataModel> {
    const popData: GeneralPopModel = {};

    if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      this.alertsvc.openAlert('請先登入會員', false);
      return;
    }

    if (!this.baseDatasvc.flightData) {
      popData.title = this.translateService.instant('shoppingCartAlertChooseFlightTitle');
      popData.content = this.translateService.instant('shoppingCartAlertChooseFlightContent');
      popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonCancel');
      popData.fightButtonText = this.translateService.instant('shoppingCartAlertChooseFlightChooseFlight');

      this.popupsvc.openGeralPop(popData);

      return;
    }

    const condition: ShoppingCarItemModel = {
      ShoppingCarNo: this.membersvc.generateShoppingCarNo(),
      LoadStation: this.baseDatasvc.flightData.loadStation,
      ProductNo: productNo,
      Count: count,
      MemberCardNo: this.baseDatasvc.memberData.loginData.dfpCardType,
      FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString()
    };

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Remove;

    return this.http.post<ResultNoDataModel>((apiUrl), condition, this.httpOptions).pipe(map(result => {
      if (result.Success) {
        this.shoppingGet().subscribe(result => { });
      }

      return result;
    }));
  }

  /// 取得購物車
  shoppingGet(coupon?: string): Observable<ResultModel<ShoppingCarMainModel>> {
    const condition: ShoppingCarQueryModel = {
      LngNo: this.languagesvc.nowLangNo,
      FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString(),
      ShoppingNo: this.membersvc.generateShoppingCarNo(),
      MemberCardNo: this.baseDatasvc.memberData.userData ? this.baseDatasvc.memberData.userData.dfpCardType : this.baseDatasvc.memberData.loginData.dfpCardType
    };

    if (coupon) {
      condition.Coupon = coupon;
    }

    if (this.baseDatasvc.flightData) {
      condition.LoadStation = this.baseDatasvc.flightData.loadStation;
    }

    if (this.baseDatasvc.memberData.loginType === MemberLoginType.DynastyMember) {
      condition.DfpMemberNo = this.baseDatasvc.memberData.userData.dfpCardNumber;
    }

    if (this.baseDatasvc.memberData.loginType === MemberLoginType.EmployID) {
      condition.EmpNo = this.baseDatasvc.memberData.userData.EMPID;
    }

    //console.log(condition);
    //console.log(this.baseDatasvc.memberData.loginType);

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Get;

    return this.http.post<ResultModel<ShoppingCarMainModel>>((apiUrl), condition, this.httpOptions).pipe(map(result => {
      //console.log(result);

      if (result.Success) {
        this.baseDatasvc.shoppingCarData = result.Data;
        this.baseDatasvc.shoppingCarCount = result.Data.Details.length + result.Data.Members.length;

        if (coupon) {
          if (result.Data.IsCoupon) {
            this.couponCode = coupon;
            this.couponCode = result.Data.CouponNumber;
            this.couponAmount = result.Data.CouponAmount;
          }
        } else {
          this.couponCode = null;
        }
      }
      return result;
    }));
  }

  /// 購物車完成
  shoppingComplete(condition: ShoppingCarCompleteModel): Observable<ResultModel<string>> {

    //2023-01-10完成訂單前檢查購物車
    let stockChange = false;
    this.shoppingGet().subscribe(result => {
      if (result.Success) {
        //調整庫存
        if (result.Data.Details && result.Data.Details !== undefined) {
          result.Data.Details.forEach(detailData => {
            if (detailData.Count > detailData.MaxCount) {
              this.shoppingRemove(detailData.ProductNo, detailData.Count - detailData.MaxCount).subscribe(result => {
                if (result.Success) {
                  if (!stockChange) { stockChange = true;}
                  //this.alertsvc.openAlert('已移除商品', true);
                } else {
                  //this.alertsvc.openAlert(result.Message, false);
                }
              });
            }
          });
        }
        //預訂數量>最大可預訂數量, 扣掉多餘的購物車
        //調整庫存
        if (result.Data.Members && result.Data.Members !== undefined) {
          result.Data.Members.forEach(detailData => {
          if (detailData.Count > detailData.MaxCount) {
            this.shoppingRemove(detailData.ProductNo, detailData.Count - detailData.MaxCount).subscribe(result => {
              if (result.Success) {
                if (!stockChange) { stockChange = true; }
                //this.alertsvc.openAlert('已移除商品', true);
              } else {
                //this.alertsvc.openAlert(result.Message, false);
              }
            });
          }
        });
      }
    }
    });
    //if (stockChange){}
    condition.CallbackURL = this.callBackURL;
    condition.ErrorURL = this.errorURL;
    condition.LngNo = this.languagesvc.nowLangNo;

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Complete;

    return this.http.post<ResultModel<string>>((apiUrl), condition, this.httpOptions).pipe(map(result => {
      return result;
    }));
  }

  /// 確認付款狀態
  shoppingCheckOrder(guid: string): Observable<ResultModel<string>> {
    const condition = {
      Guid: guid,
      LngNo: this.languagesvc.nowLangNo
    };

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.CheckOrder;

    return this.http.post<ResultModel<string>>((apiUrl), condition, this.httpOptions).pipe(map(result => {
      return result;
    }));
  }

  /// 刪除商品判斷
  /// 判斷是否是加購商品
  /// 如果是則判斷購物車內是否有一班商品
  shoppingCartRemoveFromCart(productNo: string, productType: ProductType, count: number, isShoppingCart: boolean): void {
    const popData: GeneralPopModel = {};

    if ((productType === ProductType.General || productType === ProductType.Member) &&
      this.baseDatasvc.shoppingCarData.Details.filter(x => x.ProductType === ProductType.AddPurchase).length >= 1) {
      let shoppingCartProductCount = 0;

      if (this.baseDatasvc.shoppingCarData.Details) {
        shoppingCartProductCount = shoppingCartProductCount + this.baseDatasvc.shoppingCarData.Details.filter(x => x.ProductType === ProductType.General).length;
      }

      if (this.baseDatasvc.shoppingCarData.Members) {
        shoppingCartProductCount = shoppingCartProductCount + this.baseDatasvc.shoppingCarData.Members.length;
      }

      if (shoppingCartProductCount == 1) {
        popData.title = this.translateService.instant('shoppingCartAlertRemoveTitle');
        popData.content = this.translateService.instant('shoppingCartAlertRemoveContent');
        popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonCancel');
        popData.removeAllProductConfirmText = this.translateService.instant('shoppingCartAlertButtonRemove');

        this.popupsvc.openGeralPop(popData);

        return;
      }
    }

    this.shoppingRemove(productNo, count).subscribe(result => {
      if (result.Success) {
        this.alertsvc.openAlert('已移除商品', true);

        if (isShoppingCart) {
          this.shoppingCartPageReloadMethodCall.next();
        }
      } else {
        this.alertsvc.openAlert(result.Message, false);
      }
    });
  }

  /// 刪除所有 detail 商品
  shoppingCartRemoveAllDetails(): void {
    this.popupsvc.closeAll();

    this.baseDatasvc.shoppingCarData.Details.forEach(detailData => {
      const condition: ShoppingCarItemModel = {
        ShoppingCarNo: this.membersvc.generateShoppingCarNo(),
        LoadStation: this.baseDatasvc.flightData.loadStation,
        ProductNo: detailData.ProductNo,
        Count: detailData.Count,
        MemberCardNo: this.baseDatasvc.memberData.loginData.dfpCardType,
        FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString()
      };

      const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Remove;

      this.http.post<ResultNoDataModel>((apiUrl), condition, this.httpOptions).subscribe(result => {
        if (result.Success) {
          this.shoppingGet().subscribe(result => { });
        }
      });
    });
  }

  /// 刪除所有購物車商品
  shoppingCartRemoveAll(): Observable<ResultModel<null>> {
    const popData: GeneralPopModel = {};

    if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      this.alertsvc.openAlert('請先登入會員', false);
      return;
    }

    if (!this.baseDatasvc.flightData) {
      popData.title = this.translateService.instant('shoppingCartAlertChooseFlightTitle');
      popData.content = this.translateService.instant('shoppingCartAlertChooseFlightContent');
      popData.confirmButtonText = this.translateService.instant('shoppingCartAlertButtonCancel');
      popData.fightButtonText = this.translateService.instant('shoppingCartAlertChooseFlightChooseFlight');

      this.popupsvc.openGeralPop(popData);

      return;
    }

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.RemoveAll + '?shoppingCarNo=' + this.membersvc.generateShoppingCarNo();

    return this.http.get<ResultModel<null>>(apiUrl).pipe(map(result => {
      if (result.Success) {
        this.baseDatasvc.shoppingCarData = null;
        this.shoppingGet().subscribe();
      }

      return result;
    }));

    // this.baseDatasvc.shoppingCarData.Details.forEach(detailData => {
    //   console.log(detailData);

    //   const condition: ShoppingCarItemModel = {
    //     ShoppingCarNo: this.membersvc.generateShoppingCarNo(),
    //     LoadStation: this.baseDatasvc.flightData.loadStation,
    //     ProductNo: detailData.ProductNo,
    //     Count: detailData.Count,
    //     MemberCardNo: this.baseDatasvc.memberData.loginData.dfpCardType,
    //     FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString()
    //   };

    //

    //   const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Remove;

    //   this.http.post<ResultNoDataModel>((apiUrl), condition, this.httpOptions).subscribe(result => {
    //

    //     if (result.Success) {
    //       this.shoppingGet().subscribe(result => { });
    //     }
    //   });
    // });

    // this.baseDatasvc.shoppingCarData.Members.forEach(memberData => {
    //   console.log(memberData);

    //   const condition: ShoppingCarItemModel = {
    //     ShoppingCarNo: this.membersvc.generateShoppingCarNo(),
    //     LoadStation: this.baseDatasvc.flightData.loadStation,
    //     ProductNo: memberData.ProductNo,
    //     Count: memberData.Count,
    //     MemberCardNo: this.baseDatasvc.memberData.loginData.dfpCardType,
    //     FlightDate: this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString()
    //   };

    //

    //   const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Shopping.Remove;

    //   this.http.post<ResultNoDataModel>((apiUrl), condition, this.httpOptions).subscribe(result => {
    //

    //     if (result.Success) {
    //       this.shoppingGet().subscribe(result => { });
    //     }
    //   });
    // });
  }
  
  shoppingCartExceptionLog(condition: ShoppingCarExceptionLogModel) {
    //2023-11-07新增結帳錯誤log
    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.ExceptionLog;
    this.http.post(apiUrl, condition, this.httpOptions);
  }

  changeShoppingCartStep(step: ShoppingCartStep): void {
    this.localStoragesvc.remove(LocalStorageType.shoppingCartStep);
    this.localStoragesvc.add(LocalStorageType.shoppingCartStep, step);
    this.shoppingCartStep = step;
  }
}
