import { LayoutService } from 'src/app/layout/layout.service';
import { Subject, Subscription } from 'rxjs';
import { Injectable, EventEmitter } from '@angular/core';
import { SearchStepEnum } from './search-step-enum.enum';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  mobileSearchIsopen = false;
  pcSearchIsopen = false;
  searchStep: SearchStepEnum;

  private openPcSearchMethodCall = new Subject<any>();
  openPcSearchMethod = this.openPcSearchMethodCall.asObservable();

  constructor(
    private layoutsvc: LayoutService
  ) { }

  openMobileSearch(): void {
    this.searchStep = SearchStepEnum.Step1;
    this.mobileSearchIsopen = true;
    document.getElementsByTagName('body')[0].classList.add('overflowHidden');
    document.getElementsByTagName('html')[0].classList.add('overflowHidden');
  }

  closeMobileSearch(): void {
    this.mobileSearchIsopen = false;
    document.getElementsByTagName('body')[0].classList.remove('overflowHidden');
    document.getElementsByTagName('html')[0].classList.remove('overflowHidden');
  }

  openPcSearch(isOpen: boolean): void {
    if (isOpen) {
      this.pcSearchIsopen = true;
      this.openPcSearchMethodCall.next();
      // this.layoutsvc.headerToolIsOpen = true;
    }else{
      this.pcSearchIsopen = false;
      // this.layoutsvc.headerToolIsOpen = false;
    }
  }
}
