import { ApiConfig } from './../config/api-config';
import { IsKeepMsg } from './../core/enums/wish-list/is-keep-msg.enum';
import { FrontendProductKeepModel } from './model/wish-list/frontend-product-keep.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataService } from 'src/app/service/base-data.service';
import { DatePipe } from '@angular/common';
import { ShoppingCartService } from 'src/app/service/shopping-cart.service';
import { ProductType } from './../core/enums/product/product-type.enum';
import { MemberLoginType } from './../core/enums/member/member-login-type.enum';
import { GeneralPopModel, PopupService } from './../shared/popup/popup.service';
import { AlertService } from './../shared/alert/alert.service';
import { MemberService } from './member.service';
import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import { WishListModel } from './model/wish-list/wish-list.model';
import { LocalStorageType } from '../core/enums/localStorage/local-storage-type.enum';
import { element } from 'protractor';
import { map, retry } from 'rxjs/operators';
import { ShoppingCarQueryModel } from './model/shopping/shopping-car-query.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResultModel } from './model/result-model';
import { R3FactoryDelegateType } from '@angular/compiler/src/render3/r3_factory';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class WishListService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  private wishListRemoveMethodCall = new Subject<any>();
  wishListRemoveMethod = this.wishListRemoveMethodCall.asObservable();

  private wishListAddMethodCall = new Subject<any>();
  wishListAddMethod = this.wishListAddMethodCall.asObservable();

  wishProductAddArray = [];
  wishProductRemoveArray = [];

  constructor(
    private http: HttpClient,
    private membersvc: MemberService,
    private localStoragesvc: LocalStorageService,
    private shoppingCartsvc: ShoppingCartService,
    private alertsvc: AlertService,
    private popupsvc: PopupService,
    private datepipe: DatePipe,
    public baseDatasvc: BaseDataService,
    private translateService: TranslateService
  ) { }

  addToWishList(productNo: string, productType: ProductType, isKeep: boolean): void {

    var isKeep = this.isInWishList(productNo, isKeep);

    if (!this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData) ||
      this.baseDatasvc.memberData.loginType !== undefined &&
      (this.baseDatasvc.memberData.loginType === MemberLoginType.PnrLogin ||
      this.baseDatasvc.memberData.loginType === MemberLoginType.TicketNumber)) {

      const popData: GeneralPopModel = {
        title: this.translateService.instant('AlertWishListAddTitle'),
        content: this.translateService.instant('AlertWishListAddContent'),
        confirmButtonText: this.translateService.instant('AlertWishListAddButtonLater'),
        loginButtonText: this.translateService.instant('AlertWishListAddButtonLogin')
      };

      this.popupsvc.openGeralPop(popData);

      return;
    }

    // this.create(productNo);
    if (!isKeep) {
      this.productKeepAdd(productNo);
    } else {
      this.ProductKeepRemove(productNo);
    }
  }

  create(productNo: string): void {

    const newRow: WishListModel = {
      MemberNo: this.baseDatasvc.memberData.loginData.dfpCardNumber,
      ProdyctNo: productNo,
      CreateTime: new Date()
    };

    let wishListAry = this.localStoragesvc.getByName<WishListModel[]>(LocalStorageType.WishList);

    if (wishListAry === undefined) {
      const newWishAry: WishListModel[] = [newRow];
      this.localStoragesvc.add(LocalStorageType.WishList, newWishAry);
    } else {

      if (wishListAry.filter(x => x.MemberNo === this.baseDatasvc.memberData.loginData.dfpCardNumber &&
        x.ProdyctNo === productNo).length > 0) {

        const exsitRow = wishListAry.filter(x => x.MemberNo === this.baseDatasvc.memberData.loginData.dfpCardNumber &&
          x.ProdyctNo === productNo);

        exsitRow.forEach(element => {

          const nowItem = element as WishListModel;

          wishListAry = wishListAry.filter(x => x !== nowItem);
        });

        this.alertsvc.openAlert(this.translateService.instant('RemoveWishList'), true);
      } else {

        wishListAry.push(newRow);
        this.alertsvc.openAlert(this.translateService.instant('AddWishList'), true);
      }

      this.localStoragesvc.remove(LocalStorageType.WishList);
      this.localStoragesvc.add(LocalStorageType.WishList, wishListAry);
    }
  }

  mutipleCreate(productNoAry: string[]): void {

    let doneCount = 0;

    productNoAry.forEach(async element => {

      const condition: FrontendProductKeepModel = {
        ProductNo: element
      };

      switch (this.baseDatasvc.memberData.loginType) {
        case MemberLoginType.DynastyMember:
          condition.DfpMemberNo = this.baseDatasvc.memberData.userData.dfpCardNumber;
          break;
        case MemberLoginType.EmployID:
          condition.EmpNo = this.baseDatasvc.memberData.userData.EMPID;
          break;
        case MemberLoginType.PnrLogin:
          condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
          break;
        case MemberLoginType.TicketNumber:
          condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
          break;
        default:
          break;
      }

      const apiUrl = ApiConfig.APIDomain + ApiConfig.API.ProductKeepAdd
      const result = await this.http.post<ResultModel<IsKeepMsg>>(apiUrl, condition, this.httpOptions).toPromise();
      doneCount++;

      if (productNoAry.length === doneCount) {
        this.wishListAddMethodCall.next();
        this.alertsvc.openAlert(this.translateService.instant('AddWishList'), true);
      }
    });

    // const wishListAry = this.localStoragesvc.getByName<WishListModel[]>(LocalStorageType.WishList);

    // const addRowAry: WishListModel[] = [];

    // const dfpCardNo = this.baseDatasvc.memberData.loginData ?
    //   this.baseDatasvc.memberData.loginData.dfpCardNumber :
    //   this.baseDatasvc.memberData.userData.dfpCardNumber;

    // productNoAry.forEach(element => {

    //   const newRow: WishListModel = {
    //     MemberNo: dfpCardNo,
    //     ProdyctNo: element,
    //     CreateTime: new Date()
    //   };

    //   addRowAry.push(newRow);

    // });

    // if (wishListAry === undefined) {

    //   const newWishAry: WishListModel[] = [];

    //   addRowAry.forEach(element => {

    //     newWishAry.push(element);
    //   });

    //   this.localStoragesvc.add(LocalStorageType.WishList, newWishAry);
    // } else {

    //   addRowAry.forEach(element => {

    //     if (wishListAry.filter(x => x.MemberNo === dfpCardNo &&
    //       x.ProdyctNo === element.ProdyctNo).length === 0) {

    //       wishListAry.push(element);
    //     }
    //   });

    //   this.localStoragesvc.remove(LocalStorageType.WishList);
    //   this.localStoragesvc.add(LocalStorageType.WishList, wishListAry);

    //   this.alertsvc.openAlert('已加入願望清單', true);
    // }
  }

  removeAll(productNoAry: string[]): void {

    let doneCount = 0;

    productNoAry.forEach(async element => {

      const condition: FrontendProductKeepModel = {
        ProductNo: element
      };

      switch (this.baseDatasvc.memberData.loginType) {
        case MemberLoginType.DynastyMember:
          condition.DfpMemberNo = this.baseDatasvc.memberData.userData.dfpCardNumber;
          break;
        case MemberLoginType.EmployID:
          condition.EmpNo = this.baseDatasvc.memberData.userData.EMPID;
          break;
        case MemberLoginType.PnrLogin:
          condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
          break;
        case MemberLoginType.TicketNumber:
          condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
          break;
        default:
          break;
      }

      const apiUrl = ApiConfig.APIDomain + ApiConfig.API.ProductKeepRemove
      const result = await this.http.post<ResultModel<null>>(apiUrl, condition, this.httpOptions).toPromise();
      doneCount++;

      if (productNoAry.length === doneCount) {
        this.wishListRemoveMethodCall.next();
      }
    });
  }

  getWishListAry(memberNo: string): WishListModel[] {

    const wishListAry = this.localStoragesvc.getByName<WishListModel[]>(LocalStorageType.WishList);

    let returnAry: WishListModel[] = [];

    if (wishListAry !== undefined && this.membersvc.isLoginSubject.value) {

      const dfpCardNo = this.baseDatasvc.memberData.loginData ?
        this.baseDatasvc.memberData.loginData.dfpCardNumber :
        this.baseDatasvc.memberData.userData.dfpCardNumber;

      const userWishListAry = wishListAry.filter(x => x.MemberNo === dfpCardNo);

      if (userWishListAry.length > 0) {

        returnAry = userWishListAry;

        return returnAry;
      }
    }

    return returnAry;
  }

  isInWishList(productNo: string, isKeep: boolean): boolean {

    let isInWishList = isKeep;

    if (this.wishProductAddArray.length > 0) {
      const userWishListAry = this.wishProductAddArray.filter(x => x === productNo);
      if (userWishListAry.length > 0) {
        isInWishList = true;
      }
    }

    if (this.wishProductRemoveArray.length > 0) {
      const userWishListAry = this.wishProductRemoveArray.filter(x => x === productNo);
      if (userWishListAry.length > 0) {
        isInWishList = false;
      }
    }

    return isInWishList;

    // if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData) && this.baseDatasvc.memberData !== undefined) {

    //   if (this.baseDatasvc.memberData.loginType === MemberLoginType.DynastyMember) {

    //     const wishListAry = this.localStoragesvc.getByName<WishListModel[]>(LocalStorageType.WishList);

    //     if (wishListAry !== undefined && this.membersvc.isLoginSubject.value) {

    //       const dfpCardNo = this.baseDatasvc.memberData.loginData ?
    //         this.baseDatasvc.memberData.loginData.dfpCardNumber :
    //         this.baseDatasvc.memberData.userData.dfpCardNumber;

    //       const userWishListAry = wishListAry.filter(x => x.MemberNo === dfpCardNo && x.ProdyctNo === productNo);

    //       if (userWishListAry.length > 0) {

    //         return true;
    //       }
    //     }
    //   }
    // }

    // return false;
  }

  productKeepAdd(productNo: string): void {

    const condition: FrontendProductKeepModel = {
      ProductNo: productNo
    };

    switch (this.baseDatasvc.memberData.loginType) {
      case MemberLoginType.DynastyMember:
        condition.DfpMemberNo = this.baseDatasvc.memberData.userData.dfpCardNumber;
        break;
      case MemberLoginType.EmployID:
        condition.EmpNo = this.baseDatasvc.memberData.userData.EMPID;
        break;
      case MemberLoginType.PnrLogin:
        condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
        break;
      case MemberLoginType.TicketNumber:
        condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
        break;
      default:
        break;
    }

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.ProductKeepAdd

    this.http.post<ResultModel<IsKeepMsg>>(apiUrl, condition, this.httpOptions).pipe(map(result => {

      if (result.Success) {
        this.wishProductAddArray.push(productNo);
        this.wishProductRemoveArray = this.wishProductRemoveArray.filter(x => x !== productNo);
        this.wishListAddMethodCall.next();
        this.alertsvc.openAlert(this.translateService.instant('AddWishList'), true);
      }

      return result;
    })).subscribe();
  }

  ProductKeepRemove(productNo: string): void {
    const condition: FrontendProductKeepModel = {
      ProductNo: productNo
    };

    switch (this.baseDatasvc.memberData.loginType) {
      case MemberLoginType.DynastyMember:
        condition.DfpMemberNo = this.baseDatasvc.memberData.userData.dfpCardNumber;
        break;
      case MemberLoginType.EmployID:
        condition.EmpNo = this.baseDatasvc.memberData.userData.EMPID;
        break;
      case MemberLoginType.PnrLogin:
        condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
        break;
      case MemberLoginType.TicketNumber:
        condition.ShoppingCarNo = this.membersvc.generateShoppingCarNo();
        break;
      default:
        break;
    }

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.ProductKeepRemove

    this.http.post<ResultModel<null>>(apiUrl, condition, this.httpOptions).pipe(map(result => {
      if (result.Success) {
        this.wishProductRemoveArray.push(productNo);
        this.wishProductAddArray = this.wishProductAddArray.filter(x => x !== productNo);
        this.wishListRemoveMethodCall.next(productNo);
        this.alertsvc.openAlert(this.translateService.instant('RemoveWishList'), true);
      }
    })).subscribe();
  }
}
