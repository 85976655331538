import { BaseDataService } from './base-data.service';
import { map } from 'rxjs/operators';
import { FrontendRecommendProductsCondition } from './model/product/frontend-recommend-products-condition.model';
import { ExChangeResponseModel } from './model/member/exChangeResponse.model';
import { EShoppingApiResultModel } from './model/member/eShoppingApiResult.model';
import { ExChangeRequestModel } from './model/member/exChangeRequest.model';
import { LanguageService } from './language.service';
import { Injectable, Pipe } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { ResultModel } from './../service/model/result-model';
import { ProductConditionModel } from '../service/model/product/product-condition.model';
import { ProductResultModel, ProductModel } from '../service/model/product/product.model';

import { ApiConfig } from '../config/api-config';
import { DatePipe } from '@angular/common';
import { MemberLoginType } from '../core/enums/member/member-login-type.enum';
import { ProductType } from '../core/enums/product/product-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };

  constructor(
    private http: HttpClient,
    private languagesvc: LanguageService,
    private datepipe: DatePipe,
    private baseDatasvc: BaseDataService,
  ) { }

  getProduct(condition: ProductConditionModel): Observable<ResultModel<ProductResultModel>> {

    let nowDate = this.datepipe.transform(new Date(), 'yyyy/MM/dd').toString();

    if (this.baseDatasvc.flightData && this.baseDatasvc.flightData.loadDate) {
      nowDate = this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyy/MM/dd').toString();
    }

    if (this.baseDatasvc.flightData) {
      condition.LoadStation = this.baseDatasvc.flightData.loadStation;
      condition.Flight = this.baseDatasvc.flightData.fltCarrier + this.baseDatasvc.flightData.fltNumber;
      condition.FltSector = this.baseDatasvc.flightData.fltDepStationCode + this.baseDatasvc.flightData.fltArvStationCode;
    }

    condition.LngNo = condition.LngNo === undefined ? this.languagesvc.nowLangNo : condition.LngNo;
    condition.FlightDate = nowDate;

    condition.ProductTypes = [ProductType.General];

    if (this.baseDatasvc.memberData) {
      switch (this.baseDatasvc.memberData.loginType) {
        case MemberLoginType.DynastyMember:
          condition.DfpMemberNo = this.baseDatasvc.memberData.userData.dfpCardNumber;
          condition.ProductTypes.push(ProductType.Member);
          break;
        case MemberLoginType.EmployID:
          condition.EmpNo = this.baseDatasvc.memberData.userData.EMPID;
          break;
        default:
          break;
      }
    }

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.Products;

    //console.log(condition);

    return this.http.post<ResultModel<ProductResultModel>>(apiUrl, condition, this.httpOptions).pipe(map(result => {

      return result;
    }));
  }

  /// 2.1 商品金額幣別轉換
  ExChange(condition: ExChangeRequestModel): Observable<EShoppingApiResultModel<[ExChangeResponseModel[]]>> {

    if (this.baseDatasvc.flightData && this.baseDatasvc.flightData.loadDate) {
      condition.FltDate = this.datepipe.transform(this.baseDatasvc.flightData.fltDepLclDate, 'yyyyMMdd').toString();
    }else
    {
      //2023-03-14調整
      if (condition.FltDate && condition.FltDate != "")
      {
        condition.FltDate = this.datepipe.transform(condition.FltDate, 'yyyyMMdd');
      }
      else
      {
        condition.FltDate = "";
      }
    }
    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.eShoppingApi.ExChange;

    return this.http.post<EShoppingApiResultModel<[ExChangeResponseModel[]]>>((apiUrl), condition, this.httpOptions);
  }

  /// 推薦商品
  recommendProducts(condition: FrontendRecommendProductsCondition): Observable<ResultModel<ProductModel[]>> {

    condition.LngNo = condition.LngNo === undefined ? this.languagesvc.nowLangNo : condition.LngNo;

    if (this.baseDatasvc.flightData) {
      condition.Flight = this.baseDatasvc.flightData.fltCarrier + this.baseDatasvc.flightData.fltNumber;
    }

    const apiUrl = ApiConfig.APIDomain + ApiConfig.API.ProductRecommends;

    return this.http.post<ResultModel<ProductModel[]>>(apiUrl, condition, this.httpOptions);
  }
}
