import { ContactUsService } from './../../../../service/contact-us.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderListService } from 'src/app/service/order-list.service';
import { LanguageService } from './../../../../service/language.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { ShoppingCartService } from 'src/app/service/shopping-cart.service';
import { DatePipe } from '@angular/common';
import { DfpLoginRequestModel } from './../../../../service/model/member/dfpLoginRequest.model';
import { TktLoginRequestModel } from './../../../../service/model/member/tktLoginRequest.mode';
import { PnrLoginRequestModel } from './../../../../service/model/member/pnrLoginRequest.model';
//import { GetDfpMemberInfoRequestModel } from './../../../../service/model/member/getDfpMemberInfoRequest.model';
import { CheckDfpIDRequestModel } from './../../../../service/model/member/checkDfpIDRequest.model';
import { Component, OnInit, Input, AfterViewInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { GeneralPopModel, PopupService } from '../../../../shared/popup/popup.service';
import { MemberService } from '../../../../service/member.service';
import { MemberLoginType } from '../../../../core/enums/member/member-login-type.enum';
import { MemberPopupType } from '../../../../core/enums/member/member-popup-type.enum';
import { AbstractControl, FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { ShoppingCarQueryModel } from 'src/app/service/model/shopping/shopping-car-query.model';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Console } from 'console';

//2022-02-22
interface PnrloginQueryModel {
  pnr?: string;
  paxlastname?: string;
  paxfirstname?: string;
}

declare function excuteTurnstileFfp(lang):any;
declare function excuteTurnstileTkt(lang):any;

@Component({
  selector: 'app-pc-member-login',
  templateUrl: './pc-member-login.component.html',
  styleUrls: ['./pc-member-login.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class PcMemberLoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() memberLoginType: MemberLoginType;
  
  private captchaData: string;
  private captchaDataTkt: string;
  //CaptchaCode: string;
  //captchaCodeTkt: string;
  memberLoginTypeEnum = MemberLoginType;
  pwshow = false;
  isPnr = false;
  isTicket = false;
  isMemberCard = false;
  pnrloginQueryData: PnrloginQueryModel;  //2022-02-22
  tktCfInit = false;
  ffpCfInit = false;

  errorMsg: string[] = [];

  memberLogInForm = new FormGroup({
    DfpCardNumber: new FormControl('', [
      Validators.required
      //Validators.maxLength(9)
    ]),
    DfpPassword: new FormControl('', [
      Validators.required
      // Validators.minLength(5),
      // Validators.maxLength(20)
    ]),
    CaptchaCodeFfp: new FormControl('', [])
    //CaptchaCode: new FormControl('', [
//      Validators.required
      //Validators.minLength(4),
      //Validators.maxLength(4)
  //  ])
  });

  ticketLoginInForm = new FormGroup({
    Number: new FormControl('', [
      Validators.required
      //Validators.minLength(6)
      // Validators.maxLength(13)
    ]),
    FirstName: new FormControl('', [
      Validators.required
    ]),
    LastName: new FormControl('', [
      Validators.required
    ]),
    CaptchaCodeTkt: new FormControl('', [])
    //CaptchaCodeTkt: new FormControl('', [
//      Validators.required
      //Validators.minLength(5),
      //Validators.maxLength(20)
    //])
  });

  constructor(
    public router: Router,
    public popupsvc: PopupService,
    public membersvc: MemberService,
    private datepipe: DatePipe,
    private shoppingCartsvc: ShoppingCartService,
    private alertsvc: AlertService,
    public languagesvc: LanguageService,
    private orderListsvc: OrderListService,
    private route: ActivatedRoute,        //2022-02-22
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.onChanges();
    this.resetCaptcha();
    //let node = document.createElement('script');
    //node.src = "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit";//Change to your js file
    //node.type = 'text/javascript';
    //document.getElementsByTagName('head')[0].appendChild(node);
    console.log(this.languagesvc.nowLangNo);
  }
 
  resetCaptcha(): void {
    //this.membersvc.getCaptcha().subscribe(result => {
      //if (result.isOK) {
//        this.captchaData = "data:image/png;base64,"+ result.data;
        //this.captchaDataTkt = "data:image/png;base64,"+ result.data;
        //console.log(this.captchaData);
      //}
    //});
  }

  ngOnDestroy(): void {
  
  }

  ngAfterViewInit(): void {
    //this.resetCaptcha();
    let _lang = 'en';
    if (this.languagesvc != null && this.languagesvc.nowLangNo != '')
    {
      _lang = this.languagesvc.nowLangNo;
    }
    else
    {
      if (location.toString().toLowerCase().indexOf("/tw") > -1){_lang = 'tw';} else{_lang = 'en';}
    }
    if (this.membersvc.memberLoginType == this.memberLoginTypeEnum.DynastyMember)
    {
      //console.log("ngAfterViewInit-->DynastyMember")
      //console.log(this.ffpCfInit)
      if (!this.ffpCfInit){
        let cfscript = document.createElement('script');
        cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_ffp', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});});";
        if (!this.ffpCfInit && !this.tktCfInit){
          //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                           //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
        }
        document.getElementsByTagName('head')[0].appendChild(cfscript);
        this.ffpCfInit = true;
      }
    }
    if (this.membersvc.memberLoginType == this.memberLoginTypeEnum.TicketNumber)
    {
      //console.log("ngAfterViewInit-->TicketNumber")
      //console.log(this.tktCfInit)
      if (!this.tktCfInit){
        let cfscript = document.createElement('script');
        cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_tkt', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});});";
        if (!this.ffpCfInit && !this.tktCfInit){
          //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                           //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
        }
        document.getElementsByTagName('head')[0].appendChild(cfscript);
        this.tktCfInit = true;
      }
    }
    
  }

  changeMemberToFfp():boolean {
    //this.resetCaptcha();
    //console.log("changeMemberToFfp-->DynastyMember")
    //console.log(this.ffpCfInit)
    let _lang = 'en';
    if (this.languagesvc != null && this.languagesvc.nowLangNo != '')
    {
      _lang = this.languagesvc.nowLangNo;
    }
    else
    {
      if (location.toString().toLowerCase().indexOf("/tw") > -1){_lang = 'tw';} else{_lang = 'en';}
    }
    if (!this.ffpCfInit){
      let cfscript = document.createElement('script');
      //cfscript.text = "$('#cf-turnstile_ffp').html($('#cf-turnstile').html())";
      cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_ffp', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});});";
      if (!this.ffpCfInit && !this.tktCfInit){
        //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                         //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
      }
      document.getElementsByTagName('head')[0].appendChild(cfscript);
      this.ffpCfInit = true;
    }else
    {
      //console.log("excuteTurnstileFfp");
      excuteTurnstileFfp(_lang);
      this.ffpCfInit = true;
    }
    return true;
  }

  changeMemberToTkt():boolean {
    //this.resetCaptcha();
    //console.log("changeMemberToFfp-->TicketNumber")
    //console.log(this.tktCfInit)
    let _lang = 'en';
    if (this.languagesvc != null && this.languagesvc.nowLangNo != '')
    {
      _lang = this.languagesvc.nowLangNo;
    }
    else
    {
      if (location.toString().toLowerCase().indexOf("/tw") > -1){_lang = 'tw';} else{_lang = 'en';}
    }
    if (!this.tktCfInit){
      let cfscript = document.createElement('script');
      cfscript.text = "turnstile.ready(function(){turnstile.render('#cf-turnstile_tkt', {language:'" + _lang +"',retry:'never',callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});});";
      if (!this.ffpCfInit && !this.tktCfInit){
        //cfscript.text += "function excuteTurnstileFfp(){turnstile.reset('#cf-turnstile_ffp');turnstile.execute('#cf-turnstile_ffp', {callback: function(token) {$('#CaptchaCodeFfp').val(token).change();}});}"+
                         //"function excuteTurnstileTkt(){turnstile.reset('#cf-turnstile_tkt');turnstile.execute('#cf-turnstile_tkt', {callback: function(token) {$('#CaptchaCodeTkt').val(token).change();}});}";
      }
      document.getElementsByTagName('head')[0].appendChild(cfscript);
      this.tktCfInit = true;
    }
    else
    {
      //console.log("excuteTurnstileTkt");
      excuteTurnstileTkt(_lang);
      this.tktCfInit = true;
    }
    return true;
  }
  get DfpCardNumber(): AbstractControl { return this.memberLogInForm.get('DfpCardNumber'); }
  get DfpPassword(): AbstractControl { return this.memberLogInForm.get('DfpPassword'); }
  get CaptchaCodeFfp(): AbstractControl { return this.memberLogInForm.get('CaptchaCodeFfp'); }

  get Number(): AbstractControl { return this.ticketLoginInForm.get('Number'); }
  get FirstName(): AbstractControl { return this.ticketLoginInForm.get('FirstName'); }
  get LastName(): AbstractControl { return this.ticketLoginInForm.get('LastName'); }
  get CaptchaCodeTkt(): AbstractControl { return this.ticketLoginInForm.get('CaptchaCodeTkt'); }

  async memberFormSubmit(): Promise<void> {
    this.errorMsg = [];
    let captchacode = $('#CaptchaCodeFfp').text();
    if (this.memberLogInForm.invalid) {
      if (this.memberLogInForm.get('DfpCardNumber').invalid) {
        this.memberLogInForm.setErrors({ ...this.memberLogInForm.errors, 'DfpCardNumber': true });
      }

      if (this.memberLogInForm.get('DfpPassword').invalid) {
        this.memberLogInForm.setErrors({ ...this.memberLogInForm.errors, 'DfpPassword': true });
      }

      //if (this.memberLogInForm.get('CaptchaCode').invalid) {
//        this.memberLogInForm.setErrors({ ...this.memberLogInForm.errors, 'CaptchaCode': true });
      //}
      // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyCardOrPwd'));

      return;
    }

    const condition: CheckDfpIDRequestModel = {
      DfpCardNumber: this.DfpCardNumber.value,
      DfpPassword: this.DfpPassword.value,
      CaptchaCode: captchacode//his.CaptchaCodeFfp.value
    };

    this.alertsvc.openLoading(true);
    if (!this.membersvc.isCheckDfpIDAndName) {
      // 3.1 一般登入
      (await this.membersvc.CheckDfpID(condition)).subscribe(result => {
        if (!result.isOK) {
          this.resetCaptcha();
          this.errorMsg.push(result.message);
          this.alertsvc.openLoading(false);
          return;
        }
        //2024-07-14登入完畢導到航班頁面在呼叫
        //this.memberLogin(condition.DfpCardNumber);
        this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);
        this.membersvc.openFlightPopCon(false);
      }, (error) => {
        this.resetCaptcha();
        this.alertsvc.openLoading(false);
        this.alertsvc.openAlert('ServerError', false);
      });
    } else {
      // 3.5 特殊登入
      (await this.membersvc.CheckDfpIDAndName(condition)).subscribe(result => {
        if (!result.isOK) {
          this.resetCaptcha();
          this.errorMsg.push(result.message);
          this.alertsvc.openLoading(false);
          return;
        }
        //2024-07-14登入完畢導到航班頁面在呼叫
        //this.memberLogin(condition.DfpCardNumber);
        this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);
        this.membersvc.openFlightPopCon(false);
      }, (error) => {
        this.resetCaptcha();
        this.alertsvc.openLoading(false);
        this.alertsvc.openAlert('ServerError', false);
      });
    }
  }

  async memberLogin(dfpCardNumber: string): Promise<void> {
    const loginCondigion: DfpLoginRequestModel = {
      DfpCardNumber: dfpCardNumber
    };
    (await this.membersvc.DfpLogin(loginCondigion)).subscribe(loginResult => {
      this.alertsvc.openLoading(false);

      if (loginResult.isOK) {
        //console.log(loginResult.isOK);
        //console.log(!loginResult.data.itinerary );
        //console.log(loginResult.data.itinerary.length);
        if (!loginResult.data.itinerary || loginResult.data.itinerary.length === 0) {
          this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          this.popupsvc.open(this.membersvc.memberPopupId, true);
        } else {
          
          //if (this.router.url.includes('/tw/member/')) {
          if (this.router.url.includes('/member/')) {
            this.membersvc.memberPageReload();
          }
          //if (this.router.url.includes('/tw/member/Order')) {
          if (this.router.url.includes('/member/Order')) {
            this.orderListsvc.orderPageReload();
          }

          this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);
          this.membersvc.openFlightPopCon(false);
        }
      } else {
        this.resetCaptcha();
        this.membersvc.memberPopupType = MemberPopupType.NOFlight;
        this.popupsvc.open(this.membersvc.memberPopupId, true);
      }
    }, (error) => {
      this.resetCaptcha();
      this.alertsvc.openLoading(false);
      this.alertsvc.openAlert('Server Error', false);
    });
  }

  async ticketFormSubmit(): Promise<void> {
    this.errorMsg = [];
    let captchacode = $('#CaptchaCodeTkt').text();
    //console.log("CaptchaCodeTkt: " + $('#CaptchaCodeTkt').val());
    //console.log("CaptchaCodeTkt: " + $('#CaptchaCodeTkt').text());
    //console.log("CaptchaCodeTkt: " + $('#CaptchaCodeTkt').text());
    if (this.ticketLoginInForm.invalid) {
      if (this.ticketLoginInForm.get('Number').invalid) {
        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'Number': true });
        // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyPnrOrTicketNumber'));
      }

      if (this.ticketLoginInForm.get('FirstName').invalid) {
        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'FirstName': true });
        // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyLastName'));
      }

      if (this.ticketLoginInForm.get('LastName').invalid) {
        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'LastName': true });
        // this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyLastName'));
      }
      //if (this.ticketLoginInForm.get('CaptchaCodeTkt').invalid) {
//        this.ticketLoginInForm.setErrors({ ...this.ticketLoginInForm.errors, 'CaptchaCodeTkt': true });
      //}
      return;
    }

    const ticketNumber: string = this.ticketLoginInForm.get('Number').value;

    ticketNumber.replace(' ', '');

    //console.log('this.ticketLoginInForm:' + JSON.stringify(this.ticketLoginInForm.value));

    if (ticketNumber.length === 6 || ticketNumber.length === 13) {
      if (ticketNumber.length === 6) {
        const condition: PnrLoginRequestModel = {
          PaxLocator: this.Number.value,
          PaxFirstName: this.FirstName.value,
          PaxLastName: this.LastName.value,
          CaptchaCode: captchacode//this.CaptchaCodeTkt.value
        };
        this.alertsvc.openLoading(true);

        (await this.membersvc.PnrLogin(condition)).subscribe(result => {
          this.alertsvc.openLoading(false);

          if (result.isOK) {
            // this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);
            if (result.data.itinerary !== null || result.data.itinerary.length > 0) {
              this.popupsvc.closeAll();

              const popData: GeneralPopModel = {
                content: this.translateService.instant('OtherMemberLoginAlertContent'),
                fightButtonText: this.translateService.instant('OtherMemberLoginAlertSelectFlighButton'),
                loginButtonText: this.translateService.instant('OtherMemberLoginAlertSelectMemberLogin')
              };

              this.popupsvc.openGeralPop(popData);
              // this.membersvc.openFlightPopCon(true);
            } else {
              this.membersvc.memberPopupType = MemberPopupType.NOFlight;
            }
          } else {
            this.resetCaptcha();
            this.errorMsg.push(result.message);
          }
        }, (error) => {
          this.resetCaptcha();
          this.errorMsg.push(error);
          this.alertsvc.openLoading(false);
        });
      }

      if (ticketNumber.length === 13) {
        if (this.containSpecial(ticketNumber)) {
          this.errorMsg.push(this.translateService.instant('MemberLoginToolTicketError'));
          return;
        }

        const condition: TktLoginRequestModel = {
          TicketNumber: this.Number.value,
          PaxFirstName: this.FirstName.value,
          PaxLastName: this.LastName.value,
          CaptchaCode: captchacode//this.CaptchaCodeTkt.value
        };
        
        this.alertsvc.openLoading(true);

        (await this.membersvc.TktLogin(condition)).subscribe(result => {
          this.alertsvc.openLoading(false);

          if (result.isOK) {
            // this.alertsvc.openAlert(this.translateService.instant('LogInSuccessfully'), true);
            const popData: GeneralPopModel = {
              content: this.translateService.instant('MemberLoginAlertContent'),
              confirmButtonText: this.translateService.instant('MemberLoginAlertCloseButton'),
              loginButtonText: this.translateService.instant('MemberLoginAlertLoginButton')
            };

            this.popupsvc.openGeralPop(popData);

            if (result.data.itinerary !== null || result.data.itinerary.length > 0) {
              this.popupsvc.closeAll();

              const popData: GeneralPopModel = {
                content: this.translateService.instant('OtherMemberLoginAlertContent'),
                fightButtonText: this.translateService.instant('OtherMemberLoginAlertSelectFlighButton'),
                loginButtonText: this.translateService.instant('OtherMemberLoginAlertSelectMemberLogin')
              };

              this.popupsvc.openGeralPop(popData);
              // this.membersvc.openFlightPopCon(true);
            } else {
              this.membersvc.memberPopupType = MemberPopupType.NOFlight;
            }
          } else {
            this.resetCaptcha();
            this.errorMsg.push(result.message);
            // this.membersvc.memberPopupType = MemberPopupType.NOFlight;
          }
        }, (error) => {
          this.resetCaptcha();
         });
      }

      return;
    }

    this.errorMsg.push(this.translateService.instant('ErrorMessageEmptyPnrOrTicketNumber'));

    return;
  }

  passwordShowHide(): void {
    this.pwshow = !this.pwshow;
  }

  containSpecial(str: string) {
    var specialChars = "~·`!！@#$￥%^…&*()（）—-_=+[]{}【】、|\\;:；：'\"“‘,./<>《》?？，。";
    var len = specialChars.length;
    for (var i = 0; i < len; i++) {
      if (str.indexOf(specialChars.substring(i, i + 1)) != -1) {
        return true;
      }
    }
    return false;
  }

  onChanges(): void {
    //2022-02-22
    if (location.toString().toLowerCase().indexOf("?pnr=") > -1) {
      this.route.queryParams.subscribe(params => {
        this.pnrloginQueryData = params as PnrloginQueryModel;
        if (this.pnrloginQueryData.pnr !== undefined && this.pnrloginQueryData.pnr.length === 6) {
          //alert(this.pnrloginQueryData.pnr);
          this.Number.setValue(this.pnrloginQueryData.pnr);
          this.isPnr = true;
          this.isTicket = false;
        }
      });
    }
    this.ticketLoginInForm.valueChanges.subscribe(val => {
      const ticketInputValue = val.Number;
      if (ticketInputValue.length === 6) {
        this.isPnr = true;
        this.isTicket = false;
      } else if (ticketInputValue.length === 13) {
        this.isPnr = false;
        this.isTicket = true;
      } else {
        this.isPnr = false;
        this.isTicket = false;
      }
    });

    this.memberLogInForm.valueChanges.subscribe(val => {
      if (val.DfpCardNumber.length > 6) {
        this.isMemberCard = true;
      } else {
        this.isMemberCard = false;
      }
    });
  }
}