import { LanguageService } from 'src/app/service/language.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isOpen = false;
  @Input() isShowPaddingBottom = false;

  constructor(
    public languagesvc: LanguageService
  ) { }

  ngOnInit(): void {

    jQuery('.sky-foot-item').on( 'click', () => {

      jQuery(this).toggleClass('active');

    });
  }

  mobileToogle($event): void {

    const isOpen = $event.target.getAttribute('aria-expanded');
    this.isOpen = isOpen === 'false' ? true : false;
  }
}
