import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private alerts: any[] = [];

  public alertId = 'mainAlert';
  public alertText = '';
  public alertOpenIsOk = true;
  constructor() { }

  add(alert: any): void {
    this.alerts.push(alert);
  }

  remove(id: string): void {
    this.alerts = this.alerts.filter(x => x.id !== id);
  }

  open(id: string): void {

    const alert = this.alerts.find(x => x.id === id);
    alert.open();
  }

  close(id: string): void {
    const alert = this.alerts.find(x => x.id === id);
    alert.close();
  }

  openAlert(text: string, isOk: boolean): void {

    const alert = this.alerts.find(x => x.id === this.alertId);

    this.alertText = text;

    this.alertOpenIsOk = isOk;

    alert.open();

    setTimeout(() => {

      alert.close();
    }, 2000);
  }

  openLoading(isOpen: boolean): void {

    const alert = this.alerts.find(x => x.id === 'loading');

    if (isOpen) {

      alert.open();

    } else {

      alert.close();
    }
  }
}
