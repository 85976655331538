<div [class]="this.membersvc.memberConIsOpen ? 'show' : ''" class="member_pc position-absolute shadow">
  <div class="member_container_pc" [class]="this.membersvc.isLoginSubject.value ? 'islogin' : ''">

    <div class="member_container_padding">
      <ng-container *ngIf="this.membersvc.isLoginSubject.value">

        <div class="member_info_box"
             *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.DynastyMember">
          <div class="info_box">
            <p class="name">
              {{this.baseDatasvc.memberData.userData.eName.split("/")[0] + "/**"}}
              <!-- {{this.baseDatasvc.memberData.loginData.paxLastName + "/" + this.baseDatasvc.memberData.loginData.paxFirstName}} -->
            </p>
            <p class="">
              {{"MemberDynastyMember" | translate}} | {{this.baseDatasvc.memberData.userData.dfpCardNumber}}
            </p>
          </div>
          <div class="card_box">
            <img src="../../../../assets/content/images/{{this.baseDatasvc.memberData.userData.dfpCardType}}_s.png">
          </div>
        </div>

        <div class="member_info_box"
             *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.EmployID">
          <div class="member_card float-left">
            <p class="name">
              <ng-container *ngIf="this.baseDatasvc.memberData.loginData">
                {{this.baseDatasvc.memberData.loginData.paxLastName + "/" + this.baseDatasvc.memberData.loginData.paxFirstName}}
              </ng-container>
            </p>
            <small>
              {{"MemberEmployeeNumber" | translate}}<span>
                |
              </span>
              {{this.baseDatasvc.memberData.loginData ? this.baseDatasvc.memberData.loginData.dfpCardNumber : ""}}
            </small>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="member_info_box"
             *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.PnrLogin">
          <div class="member_card float-left">
            <p class="name">
              {{this.baseDatasvc.memberData.loginData.paxLastName + "/" + this.baseDatasvc.memberData.loginData.paxFirstName}}
            </p>
            <small>
              {{"MemberReservationNumber" | translate}}<span>
                |
              </span>{{this.baseDatasvc.memberData.loginData.PaxLocator}}
            </small>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="member_info_box"
             *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.TicketNumber">
          <div class="member_card float-left">
            <p class="name">
              {{this.baseDatasvc.memberData.loginData.paxLastName + "/" + this.baseDatasvc.memberData.loginData.paxFirstName}}
            </p>
            <small>
              {{"MemberE-TicketNumber" | translate}}<span>
                |
              </span>{{this.baseDatasvc.memberData.loginData.TicketNumber}}
            </small>
          </div>
          <div class="clearfix"></div>
        </div>

        <ul class="member_acton_box">
          <li class="member_order">
            <a routerLink="/{{this.languagesvc.nowLangNo}}/member/{{this.memberCenterTypeEnum[this.memberCenterTypeEnum.Order]}}"
               [queryParams]="{ type: this.memberCenterTypeEnum[this.memberCenterTypeEnum.Order] }">{{"MemberToolListMyOrder" | translate}}</a>
          </li>
          <li class="member_push_notification">
            <a routerLink="/{{this.languagesvc.nowLangNo}}/member/{{this.memberCenterTypeEnum[this.memberCenterTypeEnum.Notification]}}"
               [queryParams]="{ type: this.memberCenterTypeEnum[this.memberCenterTypeEnum.Notification] }">{{"MemberToolPushNotification" | translate}}</a>
          </li>
          <li class="member_wishlist">
            <a href="javascript:;" (click)="this.toWishList()">{{"MemberToolWishlist" | translate}}</a>
          </li>
          <li class="member_sign_out">
            <button type="button" (click)="this.logout()">
              <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.DynastyMember">{{"MemberToolSignOutDynastyMember" | translate}}</ng-container>
              <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.TicketNumber">{{"MemberToolSignOutTicketNumber" | translate}}</ng-container>
              <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.PnrLogin">{{"MemberToolSignOutPnrLogin" | translate}}</ng-container>
              <ng-container *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.EmployID">{{"MemberToolSignOut" | translate}}</ng-container>
            </button>
          </li>
        </ul>

        <div class="member_foot"
             *ngIf="this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.PnrLogin || this.baseDatasvc.memberData.loginType === this.memberLoginTypeEnum.TicketNumber">
          <p class="sky-mb-20" [innerHTML]='"MemberToolLoginWording" | translate'></p>
          <button class="sky-item-button main" type="button"
                  (click)="this.openLogin(memberLoginTypeEnum.DynastyMember)">
            {{"MemberLoginToolDynastyMemberLogin" | translate}}
          </button>
          <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'tw'">
            {{"MemberLoginToolNotAMemberYet" | translate}}
            <span>
              <a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh"
                 target="_blank">{{"MemberLoginToolSignUp" | translate}}</a>
            </span>
          </p>
          <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'en'">
            {{"MemberLoginToolNotAMemberYet" | translate}}
            <span>
              <a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=en-us&country=tw&locale=en"
                 target="_blank">{{"MemberLoginToolSignUp" | translate}}</a>
            </span>
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="!this.membersvc.isLoginSubject.value">
        <button class="sky-item-button main" type="button"
                (click)="this.openLogin(memberLoginTypeEnum.DynastyMember)">
          {{"MemberLoginToolDynastyMemberLogin" | translate}}
        </button>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'tw'">
          {{"MemberLoginToolNotAMemberYet" | translate}}
          <span>
            <a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh"
               target="_blank">
              {{"MemberLoginToolSignUp" | translate}}
            </a>
          </span>
        </p>
        <p class="member_sign_pc" *ngIf="this.languagesvc.nowLangNo === 'en'">
          {{"MemberLoginToolNotAMemberYet" | translate}}
          <span>
            <a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=en-us&country=tw&locale=en"
               target="_blank">
              {{"MemberLoginToolSignUp" | translate}}
            </a>
          </span>
        </p>
        <div class="member-borer">
          <div class="text-transform">{{"MemberLoginToolOr" | translate}}</div>
        </div>
        <button class="sky-item-button" type="button"
                (click)="this.openLogin(memberLoginTypeEnum.TicketNumber)">
          {{"MemberLoginToolUseReservation/E-TicketNUMBER" | translate}}
        </button>
      </ng-container>
    </div>
  </div>
</div>

<app-popup [id]="this.membersvc.memberPopupId" class="">

  <div class="member_modal_container d-lg-block d-xl-block">
    <div class="position-relative">

      <a class="ic-cancel position-absolute" href="javascript:;" type="text"
         (click)="this.popupsvc.close(this.popupId)"><img src="./assets/content/images/ic-cancel.svg"></a>

      <app-pc-member-login *ngIf="this.membersvc.memberPopupType === this.memberPopupTypeEnum.Login"
                           [memberLoginType]="this.memberLoginType"></app-pc-member-login>
      <app-pc-member-flight *ngIf="this.membersvc.memberPopupType === this.memberPopupTypeEnum.SelectFlight">
      </app-pc-member-flight>

      <ng-container class="member_modal_lightbox_container"
                    *ngIf="this.membersvc.memberPopupType === this.memberPopupTypeEnum.NOFlight">
        <div class="member_modal_title">
          <p>{{"FlghtNoneTitleWording" | translate}}</p>
        </div>

        <div class="member_modal_subtitle text-center">{{"FlghtNoneContentWording" | translate}}</div>

        <div class="tabled_button catalog-button">
          <button class="sky-item-button" type="button"
                  (click)="this.popupsvc.closeAll()">
            {{"FlghtNoneBrowseFirst" | translate}}
          </button>
          <a class="sky-item-button main"
             href="https://www.china-airlines.com">{{"FlghtNoneGoBuyTicket" | translate}}</a>
        </div>
      </ng-container>
    </div>
  </div>
</app-popup>
