<div class="head_secondary_container-02-m">
  <div class="members_mobile_title members_mobile_bg_w">
      <div class="head-container_m">
          <h3 class="text-transform sky-border-item">{{"MobileMenuLanguage" | translate}}</h3>
      </div>
  </div>
  <nav class="primary_category">
      <ul>
          <li class="members_mobile_bg" *ngFor="let item of this.languageAry">
              <div class="head-container_m">
                  <div class="input sky-border-item">
                      <label class="i-radio">
                          <input type="radio"
                          [id]="item.LanguageNo"
                          name="language"
                          value="{{item.LanguageNo}}"
                          [checked]="this.languagesvc.nowLangNo === item.LanguageNo ? 'check' : ''"
                          (click)="this.languagesvc.changeLanguage(item.LanguageNo)">
                          <span class="checkmark text-transform">{{item.LanguageName}}</span>
                      </label>
                  </div>
              </div>
          </li>
      </ul>
  </nav>
</div>
