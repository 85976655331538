import { ProductService } from 'src/app/service/product.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductType } from './../../../core/enums/product/product-type.enum';
import { WishListService } from './../../../service/wish-list.service';
import { ShoppingCartStep } from './../../../core/enums/shopping-cart/shopping-cart-step.enum';
import { LanguageService } from './../../../service/language.service';
import { AlertService } from './../../../shared/alert/alert.service';
import { error, element } from 'protractor';
import { MemberPopupType } from './../../../core/enums/member/member-popup-type.enum';
import { GeneralPopModel, PopupService } from './../../../shared/popup/popup.service';
import { MemberLoginType } from './../../../core/enums/member/member-login-type.enum';
import { MemberService } from './../../../service/member.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ShoppingCartService } from '../../../service/shopping-cart.service';
import { ShoppingCarItemModel } from 'src/app/service/model/shopping/shopping-car-item.model';
import { ShoppingCarQueryModel } from 'src/app/service/model/shopping/shopping-car-query.model';
import { DatePipe } from '@angular/common';
import { BaseDataService } from 'src/app/service/base-data.service';
import { ShoppingCarErrorCode } from 'src/app/core/enums/shopping-car-error-code.enum';
import { DfpLoginRequestModel } from 'src/app/service/model/member/dfpLoginRequest.model';
import { ShoppingCarMainModel } from 'src/app/service/model/shopping/shopping-car-main.model';
import { ExChangeRequestModel } from 'src/app/service/model/member/exChangeRequest.model';
import { EmpLoginRequestModel } from 'src/app/service/model/member/empLoginRequest.model';
import { PnrLoginRequestModel } from 'src/app/service/model/member/pnrLoginRequest.model';
import { TktLoginRequestModel } from 'src/app/service/model/member/tktLoginRequest.mode';

declare const jQuery: any;

@Component({
  selector: 'app-pc-shopping-cart',
  templateUrl: './pc-shopping-cart.component.html',
  styleUrls: ['./pc-shopping-cart.component.css']
})
export class PcShoppingCartComponent implements OnInit {
  wasInside = false;

  memberPopupTypeEnum = MemberPopupType;
  memberLoginTypeEnum = MemberLoginType;
  memberLoginType: MemberLoginType;
  shoppingCarData?: ShoppingCarMainModel;
  isDone = false;

  detailTwPriceAry = [];
  memberTwPriceAry = [];

  constructor(
    private route: Router,
    public shoppingcartsvc: ShoppingCartService,
    public popupsvc: PopupService,
    public membersvc: MemberService,
    private alertsvc: AlertService,
    private datepipe: DatePipe,
    public languagesvc: LanguageService,
    public wishListsvc: WishListService,
    public baseDatasvc: BaseDataService,
    private translateService: TranslateService,
    private productsvc: ProductService
  ) {
    this.membersvc.memberPageReloadMethod.subscribe(async () => {
      if (this.baseDatasvc.flightData) {
        this.shoppingCarData = null;
        this.getShoppingCarData();
      }
    });
    this.shoppingcartsvc.shoppingCartOpenMethod.subscribe(() => {
      //console.log('shoppingCartOpenMethod');
      this.clickInside();
      this.getShoppingCarData();
    });
    // this.shoppingcartsvc.shoppingCartPageReloadMethod.subscribe(() => {
    //   this.shoppingCarData = null;
    //     this.getShoppingCarData();
    // });
  }

  ngOnInit(): void {
  }

  @HostListener('click')
  clickInside(): void {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout(): void {
    if (!this.wasInside) {
      this.shoppingcartsvc.openPcShoppingCart(false);
    }
    this.wasInside = false;
  }

  goToCart(): void {
    this.shoppingcartsvc.pcShoppingCartIsopen = false;
    this.route.navigate([this.languagesvc.nowLangNo + '/shoppingCart', ShoppingCartStep[ShoppingCartStep.step1]]);
  }

  openLogin(id: string, memberLoginType: MemberLoginType): void {
    // this.popupsvc.open(id);
    this.membersvc.openLoginPopCon(memberLoginType);
    this.shoppingcartsvc.tooglePcShoppingCart();
  }

  openFlightSelect(): void {
    if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData)) {
      this.membersvc.openFlightPopCon(true);
    } else {
      this.openLogin(this.membersvc.memberPopupId, MemberLoginType.DynastyMember);
    }

    this.shoppingcartsvc.pcShoppingCartIsopen = false;
  }

  async getShoppingCarData(): Promise<void> {
    this.isDone = false;

    if (this.membersvc.checkMemberIsLogin(this.baseDatasvc.memberData) && this.baseDatasvc.memberData.loginData) {
      if (this.baseDatasvc.flightData !== null) {
        this.shoppingcartsvc.shoppingGet().subscribe(result => {
          this.isDone = true;

          if (result.Success) {
            this.shoppingCarData = result.Data;

            if (this.languagesvc.nowLangNo === 'tw') {
              if (result.Data.Details && result.Data.Details.length > 0) {
                const detailPriceAry = result.Data.Details.map(x => {
                  let detailPrice = x.ProductPrice * x.Count;

                  // if (x.IsSpecialProduct) {
                  //   detailPrice = x.FinalPrice;
                  // }else{
                  //   detailPrice = x.ProductPrice * x.Count;
                  // }
                  return detailPrice.toString();
                });

                const exChangeconditionDetail: ExChangeRequestModel = {
                  USDPrice: detailPriceAry
                };

                this.productsvc.ExChange(exChangeconditionDetail).subscribe(exChangeDetailResult => {
                  if (exChangeDetailResult.isOK) {
                    this.detailTwPriceAry = exChangeDetailResult.data.map(x => {
                      return x.find(x => x.exCurrency === 'TWD').exPrice.toString();
                    });
                  }
                });
              }

              if (result.Data.Members && result.Data.Members.length > 0) {
                const memberPriceAry = result.Data.Members.map(x => {
                  return x.FinalPrice.toString();
                });

                const exChangeconditionMember: ExChangeRequestModel = {
                  USDPrice: memberPriceAry
                };

                this.productsvc.ExChange(exChangeconditionMember).subscribe(exChangeMemberResult => {
                  if (exChangeMemberResult.isOK) {
                    this.memberTwPriceAry = exChangeMemberResult.data.map(x => {
                      return x.find(x => x.exCurrency === 'TWD').exPrice.toString();
                    });
                  }
                });
              }
            }
          }
        }, (error) => {
          this.isDone = true;
          this.alertsvc.openAlert('Server Error', false);
        });
      } else {
        if (this.baseDatasvc.memberData.loginType === MemberLoginType.DynastyMember) {
          const loginCondigion: DfpLoginRequestModel = {
            DfpCardNumber: this.baseDatasvc.memberData.userData.dfpCardNumber
          };

          (await this.membersvc.DfpLogin(loginCondigion)).subscribe(loginResult => { }, (error) => { });
        }

        this.isDone = true;
      }
    } else {
      this.isDone = true;
    }
  }

  removeFromCart(productNo: string, productType: ProductType, count: number): void {
    const popData: GeneralPopModel = {
      title: this.translateService.instant('shoppingCartAlertLimitRemoveProductTitle'),
      content: this.translateService.instant('shoppingCartAlertLimitRemoveProductContent'),
      confirmButtonText: this.translateService.instant('shoppingCartAlertLimitRemoveProductConfirm'),
      removeProductConfirmText: this.translateService.instant('shoppingCartAlertLimitRemoveProductRemove'),
      productData: {
        popRemoveProductNo: productNo,
        popRemoveProductType: productType,
        popRemoveCount: count,
        isShoppingCart: false
      }
    };

    this.popupsvc.openGeralPop(popData);
  }

  removeAll(): void {
    const popData: GeneralPopModel = {
      title: this.translateService.instant('shoppingCartAlertLimitRemoveProductTitle'),
      content: this.translateService.instant('shoppingCartAlertLimitRemoveProductContent'),
      confirmButtonText: this.translateService.instant('shoppingCartAlertLimitRemoveProductConfirm'),
      removeAllProductConfirmText: this.translateService.instant('shoppingCartAlertButtonRemove')
    }

    this.popupsvc.openGeralPop(popData);

    // this.shoppingcartsvc.shoppingCartRemoveAll().subscribe(result => {
    //   if (result.Success) {
    //     this.getShoppingCarData();
    //   }
    // });
  }

  async add(productNo: string, productType: ProductType, count: number, maxCount: number): Promise<void> {
    (await this.shoppingcartsvc.shoppingAdd(productNo, productType)).subscribe(result => {
      if (result.Success) {
        this.getShoppingCarData();
      }
    });
  }

  remove(productNo: string, count: number): void {
    if (count > 1) {
      this.shoppingcartsvc.shoppingRemove(productNo, 1).subscribe(result => {
        if (result.Success) {
          this.getShoppingCarData();
        } else {
          this.alertsvc.openAlert(result.Message, false);
        }
      });
    }
  }

  mutipleAddToWishList(): void {
    const noList: string[] = [];

    if (this.shoppingCarData &&
      this.shoppingCarData.Details.length > 0) {
      this.shoppingCarData.Details.forEach(element => {
        if (element.IsOneBuyOneFree === false &&
          element.IsSpecialProduct === false) {
          noList.push(element.ProductNo);
        }
      });
    }

    if (this.shoppingCarData &&
      this.shoppingCarData.Members.length > 0) {
      this.shoppingCarData.Members.forEach(element => {
        if (element.IsOneBuyOneFree === false &&
          element.IsSpecialProduct === false) {
          noList.push(element.ProductNo);
        }
      });
    }

    this.wishListsvc.mutipleCreate(noList);
  }
}
