<!-- foot Start -->
<footer id="foot" [class]="this.isShowPaddingBottom ? 'pb-109' : ''">

    <!-- pc -->
    <div class="sky-foot foot_bg d-none d-lg-block d-xl-block">
        <div class="main-container">
            <a routerLink="/{{this.languagesvc.nowLangNo}}/" class="foot-loog"><img src="../../../assets/content/images/img-logo-footer.png" width="180px"></a>
            <div class="foot-container">
                <div class="sky-foot-sub float-left">
                    <ul class="foot-itme-1 foot-border float-itme-height">
                        <li>
                            <h2>{{"FooterImportantNote" | translate}}</h2>
                        </li>
                        <li>
                            <a href='{{"FooterPrivacyProtectionURL" | translate}}' target="_blank">
                                <p>{{"FooterPrivacyProtection" | translate}}</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/{{this.languagesvc.nowLangNo}}/patentInformation">
                                <p>{{"FooterPatentInformation" | translate}}</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/{{this.languagesvc.nowLangNo}}/flightSecurityPolicy">
                                <p>{{"FooterFlightSecurityPolicy" | translate}}</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="sky-foot-sub float-left">
                    <div class="foot-itme-2 foot-border float-itme-height">
                        <h2>{{"FooterService" | translate}}</h2>
                        <div class="row no-gutters itme">
                            <ul class="col-6 itme-1">
                                <li>
                                    <a routerLink="/{{this.languagesvc.nowLangNo}}/sitemap">
                                        <p>{{"FooterSitemap" | translate}}</p>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/{{this.languagesvc.nowLangNo}}/news">
                                        <p>{{"FooterNews" | translate}}</p>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/{{this.languagesvc.nowLangNo}}/faq">
                                        <p>{{"FooterFAQ" | translate}}</p>
                                    </a>
                                </li>
                            </ul>
                            <ul class="col-6 itme-2">
                                <li>
                                    <a *ngIf="this.languagesvc.nowLangNo === 'tw'" href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh" target="_blank">
                                        <p>{{"FooterRegister" | translate}}</p>
                                    </a>
                                    <a *ngIf="this.languagesvc.nowLangNo === 'en'" href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=en-us&country=tw&locale=en" target="_blank">
                                        <p>{{"FooterRegister" | translate}}</p>
                                    </a>
                                </li>
                                <li>
                                    <a [routerLink]="['purchaseNotes']">
                                        <p>{{"FooterShoppingNotice" | translate}}</p>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div class="sky-foot-sub float-left">
                    <ul class="foot-itme-1 foot-border float-itme-height">
                        <li>
                            <h2>{{"FooterRelatedLinks" | translate}}</h2>
                        </li>
                        <li>
                            <a href="https://www.china-airlines.com" target="_blank">
                                <p>{{"FooterChinaAirlines" | translate}}</p>
                            </a>
                        </li>
                        <li>
                            <a href="https://calec.china-airlines.com/emall/index.aspx" target="_blank">
                                <p>{{"FootereMall" | translate}}</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="sky-foot-sub float-left">
                    <div class="foot-icon">
                        <ul class="foot-icon-itme-1">
                            <li>
                                <a href="https://www.facebook.com/chinaairlines.travelchannel/" target="_blank" *ngIf="this.languagesvc.nowLangNo === 'tw'"><img src="../../../assets/content/images/ic-fb.svg" width="32px"></a>
                                <a href="https://www.facebook.com/chinaairlines.global" target="_blank" *ngIf="this.languagesvc.nowLangNo === 'en'"><img src="../../../assets/content/images/ic-fb.svg" width="32px"></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/chinaairlines.tw/" target="_blank"><img src="../../../assets/content/images/ic-ig.svg" width="32px"></a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/user/TheCHINAAIRLINES" target="_blank"><img src="../../../assets/content/images/ic-youtube.svg" width="32px"></a>
                            </li>
                            <li>
                                <a href="https://weixin.qq.com/r/5Uy4oKHEEPxvreXD9xn3" target="_blank"><img src="../../../assets/content/images/ic-wechat.svg" width="32px"></a>
                            </li>
                            <li>
                                <a href="http://www.weibo.com/u/3658998552" target="_blank"><img src="../../../assets/content/images/ic-weibo.svg" width="32px"></a>
                            </li>
                        </ul>
                        <ul class="foot-icon-itme-2">
                            <li>
                                <a href="https://play.google.com/store/apps/details?id=com.chinaairlines.caleshopping" target="_blank"><img src="../../../assets/content/images/ic-googleplay@3x.png" width="132px"></a>
                            </li>
                            <li>
                                <a href="https://apps.apple.com/app/id1435278283" target="_blank"><img src="../../../assets/content/images/ic-appstore@3x.png" width="132px"></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="float-left">
                    <ul class="foot-itme-3">
                        <li>
                            <h3>{{"FooterRelatedLinksPhone" | translate}}</h3>
                            <a href="tel:+886-2-412-9000">
                                <p>+886-2-412-9000</p>
                            </a>
                        </li>
                        <li>
                            <h3>{{"FooterOperatingHours" | translate}}</h3>
                            <p>{{"FooterOperatingHoursTime" | translate}}</p>
                        </li>
                        <li>
                            <h3>{{"FootereEmail" | translate}}</h3>
                            <a href="mailto:eShopping@china-airlines.com">
                                <p>eShopping@china-airlines.com</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="clearfix"></div>
                <div class="float-right foot-container-company">
                    <h4>Copyright © 2021 China Airlines All Rights Reserved.</h4>
                </div>
                <div class="clearfix"></div>
            </div>


        </div>
        <div class="foot_bg_s"></div>
    </div>

    <!-- moblie -->
    <div id="foot-m" class="sky-foot-m foot_bg-m d-block d-lg-none d-xl-none">
        <div class="main-container">
            <a routerLink="/{{this.languagesvc.nowLangNo}}/" class="foot-loog" ><img src="../../../assets/content/images/img-logo-footer.png" width="180px"></a>
            <button *ngIf="!this.isOpen" class="sky-foot-item" type="button" data-toggle="collapse" data-target=".foot_coding_1" aria-expanded="false" (click)="this.mobileToogle($event)">{{"FotterButtonOpen" | translate}}</button>
            <button *ngIf="this.isOpen" class="sky-foot-item active" type="button" data-toggle="collapse" data-target=".foot_coding_1" aria-expanded="true" (click)="this.mobileToogle($event)">{{"FotterButtonClose" | translate}}</button>
            <div class="foot-container-m collapse foot_coding_1">
                <div class="sky-foot-sub-m">
                    <ul class="foot-itme-1-m foot-border">
                        <li>
                            <h2>{{"FooterImportantNote" | translate}}</h2>
                        </li>
                        <li>
                            <a href="https://www.china-airlines.com/tw/zh/terms-and-conditions/privacy-protection" target="_blank">
                                <p>{{"FooterPrivacyProtection" | translate}}</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/{{this.languagesvc.nowLangNo}}/patentInformation">
                                <p>{{"FooterPatentInformation" | translate}}</p>
                            </a>
                        </li>
                        <li>
                            <a routerLink="/{{this.languagesvc.nowLangNo}}/flightSecurityPolicy">
                                <p>{{"FooterFlightSecurityPolicy" | translate}}</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="sky-foot-sub-m">
                    <div class="foot-itme-2-m foot-border">
                        <h2>{{"FooterService" | translate}}</h2>
                        <div class="row no-gutters itme">
                            <ul class="col-6 itme-1">
                                <li>
                                    <a routerLink="/{{this.languagesvc.nowLangNo}}/sitemap">
                                        <p>{{"FooterSitemap" | translate}}</p>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/{{this.languagesvc.nowLangNo}}/news">
                                        <p>{{"FooterNews" | translate}}</p>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/{{this.languagesvc.nowLangNo}}/faq">
                                        <p>{{"FooterFAQ" | translate}}</p>
                                    </a>
                                </li>
                            </ul>
                            <ul class="col-6 itme-2">
                                <li>
                                    <a href="https://members.china-airlines.com/dynasty/regBenefit.aspx?lang=zh-TW&country=tw&locale=zh" target="_blank">
                                        <p>{{"FooterRegister" | translate}}</p>
                                    </a>
                                </li>
                                <li>
                                    <a [routerLink]="['purchaseNotes']">
                                        <p>{{"FooterShoppingNotice" | translate}}</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="sky-foot-sub-m">
                    <ul class="foot-itme-1-m foot-border">
                        <li>
                            <h2>{{"FooterRelatedLinks" | translate}}</h2>
                        </li>
                        <li>
                            <a href="https://www.china-airlines.com" target="_blank">
                                <p>{{"FooterChinaAirlines" | translate}}</p>
                            </a>
                        </li>
                        <li>
                            <a href="https://calec.china-airlines.com/emall/index.aspx" target="_blank">
                                <p>{{"FootereMall" | translate}}</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="sky-foot-sub-about-m">
                    <ul class="foot-about-m">
                        <li>
                            <h4>{{"FooterRelatedLinksPhone" | translate}}</h4>
                            <a href="tel:+886-2-412-9000">
                                <p>+886-2-412-9000</p>
                            </a>
                        </li>
                        <li>
                            <h4>{{"FooterOperatingHours" | translate}}</h4>
                            <p>{{"FooterOperatingHoursTime" | translate}}</p>
                        </li>
                        <li>
                            <h4>{{"FootereEmail" | translate}}</h4>
                            <a href="mailto:eShopping@china-airlines.com" target="_blank">
                                <p>eShopping@china-airlines.com</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="sky-foot-sub-m">
                <div class="foot-icon-m">
                    <ul class="foot-icon-itme-1-m">
                        <li>
                            <a href="https://www.facebook.com/chinaairlines.travelchannel/" target="_blank" *ngIf="this.languagesvc.nowLangNo === 'tw'"><img src="../../../assets/content/images/ic-fb.svg" width="32px"></a>
                            <a href="https://www.facebook.com/chinaairlines.global" target="_blank" *ngIf="this.languagesvc.nowLangNo === 'en'"><img src="../../../assets/content/images/ic-fb.svg" width="32px"></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/chinaairlines.tw/" target="_blank"><img src="../../../assets/content/images/ic-ig.svg" width="32px"></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/user/TheCHINAAIRLINES" target="_blank"><img src="../../../assets/content/images/ic-youtube.svg" width="32px"></a>
                        </li>
                        <li>
                            <a href="https://weixin.qq.com/r/5Uy4oKHEEPxvreXD9xn3" target="_blank"><img src="../../../assets/content/images/ic-wechat.svg" width="32px"></a>
                        </li>
                        <li>
                            <a href="http://www.weibo.com/u/3658998552" target="_blank"><img src="../../../assets/content/images/ic-weibo.svg" width="32px"></a>
                        </li>
                    </ul>
                    <ul class="foot-icon-itme-2-m">
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.chinaairlines.caleshopping" target="_blank"><img src="../../../assets/content/images/ic-googleplay@3x.png" width="132px"></a>
                        </li>
                        <li>
                            <a href="https://apps.apple.com/app/id1435278283" target="_blank"><img src="../../../assets/content/images/ic-appstore@3x.png" width="132px"></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="foot-container-company-m">
                <h4>Copyright © 2021 China Airlines All Rights Reserved.</h4>
            </div>
        </div>
        <div class="foot_bg_s-m"></div>
    </div>

</footer>
<!-- foot End -->
